const axios = require('axios');

const emailService = async (mailAddress) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const url = apiUrl + "/growapi/checkemail";
  const emailLowercase = String(mailAddress).toLowerCase();
  const data = {
    email: emailLowercase,
  };
  return await axios.post(url, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}



export default emailService;