import {getAuth  } from "firebase/auth";
import * as FileSaver from 'file-saver';
import Loaderax from "../loader/globelLoader"


export  const getAllInvoice = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
    const url =apiUrl + "stripebilling/getallmyinvoice ";
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
   
    return await Loaderax.ax.post(url,{},{
        headers: headers
      })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export  const getYearByInvoice = async (param) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  // Old - getallmyinvoice
  // New - getallmyinvoice_v2
  const url = apiUrl + "/stripebilling/getallmyinvoice_v2";
  const token = await getToken();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
 
  return await Loaderax.ax.post(url,param,{
      headers: headers
    })
      .then((response) => {
          return response;
      },
          (error) => {
              return error;
          }) 
}

export const getProviderInvoice = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  // Old - getallmyinvoice
  // New - getallmyinvoice_v2
  const url = apiUrl + "/stripebilling/getallmypaymentproviderinvoice";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}


export const getOneTimeAddOns = async (param) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getallmyonetimeaddoninvoice";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return await Loaderax.ax.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}

export const getRecurringTimeAddOns = async (param) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getallmyrecurringaddoninvoice";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return await Loaderax.ax.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}


export const downloadInvoice = async (invoice, merchName) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const timestamp = new Date().getTime();
  const url = apiUrl + "/growapi/getmyinvoice";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'Authorization': 'Bearer ' + token,
    }

    const param = {
      invoiceID: (invoice.type === 'Plan' || invoice.type === 'Provider' ? invoice.id : invoice.subType === 'recurring' ? invoice.id : invoice.data.id),
      type: invoice.type,
      subType : invoice.subType
    }
  if (invoice.type === 'Provider'){
    param['type'] = 'Plan'
  }

  return await Loaderax.ax.post(url,param,{
      responseType: 'blob',
      headers: headers
    }).then((blob) => {
      FileSaver.saveAs(blob.data, 'ShelfPerks-' + merchName + '-' + invoice.number + 
      '.pdf');
    });
}

export const downloadInvoicePopup = async (invoice, merchName) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const timestamp = new Date().getTime();
  const url = apiUrl + "/growapi/getmyinvoice";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/pdf',
    'Authorization': 'Bearer ' + token,
  }
  const param = {
    invoiceID: invoice.id,
    type: invoice.type,
    subType: invoice.subType
  }

  return await Loaderax.ax.post(url, param, {
    responseType: 'blob',
    headers: headers
  }).then((blob) => {
    FileSaver.saveAs(blob.data, 'ShelfPerks-' + merchName + '-' + new Date().getTime() +
      '.pdf');
  });
}



const getToken = () => {
    return getAuth()
      .currentUser?.getIdToken(true)
      .then((idToken) => {
        return idToken;
      });
  }