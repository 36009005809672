import * as React from 'react';
import Container from '@mui/material/Container';
import { BsCheck2 } from 'react-icons/bs';
import { Grid, } from '@mui/material';
import { styled } from '@mui/material';

import MKBox from "../components/MKBox";
import MKButton from "../components/MKButton";
import MKTypography from "../components/MKTypography";

import "../App.css"
import shelfperks from "../assets/images/logo.svg";
import iPaddeviceBanner from "../assets/images/iPaddeviceBanner.png";
import PremiumBanner from "../assets/images/PremiumBanner.png";

export function Done({ portalurl, alreadeyAdded, merchantName }) {

    const handleStartNowClick = () => {
        window.location.replace(portalurl);
    }
    const ColorButton = styled(MKButton)(() => ({
        backgroundColor: "#C2C2C2",
        '&:hover': {
            backgroundColor: "#3CAC33",
        },
    }))
    return (
        <MKBox mb='250px'>
            <Container component="main" maxWidth="xs" style={{ overflowX: "hidden" }}>
                <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                        <div className='logo originalLogo'>
                            <img src={shelfperks} alt="LOGO"></img>
                        </div>
                    </Grid>
                    <Grid item className='logo-container premiumLogo' sx={{ justifyContent: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12} >
                        <div className='donePage'>
                            <img src={iPaddeviceBanner} width="343px" height="140px" alt="IPADBANNER"></img>
                            <img src={PremiumBanner} width="343px" height="110px" alt="PREMIUM"></img>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={12} justifyContent="center" display="flex">
                        <MKBox component="main"
                            sx={{
                                mt: {
                                    lg: "90px",
                                    md: "90px",
                                    sm: '35px',
                                    xs: "35px",
                                },
                                width: "86px",
                                height: "85px",
                                borderRadius: '43px',
                                backgroundColor: "#50B748"
                            }}>
                            <div className='check-icon' style={{ align: "center" }}>
                                <BsCheck2 style={{paddingBottom: '3px'}}/>
                            </div>
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={12} justifyContent="center" display="flex">
                        <MKTypography
                            fontSize="24px"
                            sx={{
                                mt: "42px",
                                width: "300px",
                                height: "auto",
                                fontFamily: "OpenSansSemiBold",
                                textAlign: 'center'
                            }}>
                            {alreadeyAdded ? "" :
                                <div>
                                    Great! You are all set!
                                </div>
                            }
                        </MKTypography>
                    </Grid>
                    <Grid xs={12} lg={12} justifyContent="center" display="flex">
                        <MKTypography
                            fontSize="16px"
                            sx={{
                                mt: "16px",
                                // height: {
                                //     lg: "22px",
                                //     xs: "15px",
                                // },
                                mb: "40px",
                                fontFamily: "OpenSansRegular",
                                textAlign: {
                                    xs: "center"
                                }
                            }}>
                            {merchantName != "" ? "Hi " + merchantName + "! " : ""}
                            {alreadeyAdded ?

                                <div>You have already completed your registration process.</div>
                                :
                                <>

                                <div style={{marginBottom: '20px'}}>
                                    <div>We have sent the details to your registered email.</div>
                                    <div>Keep it handy since you will need those details to access your ShelfPerks
                                        Merchant Portal and App.
                                    </div>
                                    <div>
                                        You can now set up your store operations in Shelfperks Merchant portal.
                                    </div>
                                </div>
                                </>
                            }
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={12} justifyContent="center" display="flex">
                        <ColorButton
                            variant="contained"
                            onClick={handleStartNowClick}
                            sx={{
                                backgroundColor: "#50B748",
                                color: "white",
                                textTransform: "capitalize",
                                fontFamily: "OpenSansSemiBold",
                                fontSize: "15px"
                            }}
                        >
                            {alreadeyAdded ? "Take Me to My Merchant Portal" : "Take Me to My Merchant Portal"}
                        </ColorButton>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    )
}