const axios = require('axios');

const registerMerchantDetailsService = async (form, phoneNumber) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const siteUrl = window.spconfig?.config.siteURL;
  const url = apiUrl + "/growapi/addmerchant";
  const formobj = {
    merchant_email: String(form.email).toLowerCase(),
    firstname: form.first_name,
    lastname: form.last_name,
    bus_type: form.bussiness_type,
    bus_name: form.bussiness_name,
    address1: form.address1,
    address2: form.address2,
    state: form.state,
    city: form.city,
    zipcode: form.zipCode,
    country: form.country,
    additional_number: "",
    mobile_number: phoneNumber,
    site_url: siteUrl + "completeregister",
  };
  return await axios.post(url, JSON.stringify(formobj), {
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}



export default registerMerchantDetailsService;