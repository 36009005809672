const axios = require('axios');

const forgotUsernameService = async (data) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const url = apiUrl + "/growapi/forgotusername";

    return await axios.post(url, JSON.stringify(data),{
        headers: { "Content-Type": "application/json"},
    })
    .then((response) => {
        return response
    },
    (error) => {
        return error
    })
}

export default forgotUsernameService;