// import Loaderax from "../loader/globelLoader"
const axios = require('axios');

export  const portalURLValidation = async (urlValid) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/validateportalurl";
    const data = {
        portal_url: urlValid
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const authenticationValidation = async (merchurlid) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink =apiUrl + "/growapi/validatemyportal";
    const data = {
        merchurlid: merchurlid,
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const getMerchantDetails = async (merchanturl) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getMerchatDetails";
    const data = {
        merchurlid: merchanturl,
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

