function currencyConverter(amount, currency) {

    let currencyAmount = '';
    switch (currency) {
        case 'CAD':
            currencyAmount = (Number(amount)).toLocaleString("en-ca", {
                style: "currency",
                currency: "CAD",
            });
            break;

        case 'USD':
            currencyAmount = (Number(amount)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
            break;

        default:
            currencyAmount = (Number(amount)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
            break;
    }

    return currencyAmount;
}


export default currencyConverter;