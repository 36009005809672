import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";
import MKButton from "../../../../components/MKButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { taxAddressCheck, handleSubscribeStore } from "../../../../providers/planService"
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import currencyConverter from '../../../../helpers/currencyConverter';
import { withStyles } from "@material-ui/core/styles";
import AddressForm from '../../../../components/SPAddressForm';
import { addLocationInitialGrow, getNotAllowedStore, storeValidation } from '../../../../providers/addonGrow'
import { FileUploader } from "react-drag-drop-files";
import uploadArrow from "../../../../assets/images/cloudUp.svg";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import emailValidation from "../../../../helpers/emailValidation"
import { firebaseFileUpload } from '../../../../providers/firebaseStorage';
import clsx from "clsx";


const MyInputLabel = withStyles(() => ({
    root: {
        "& .MuiInputLabel-asterisk": {
            color: "#F7931E",
        }
    }
}))(InputLabel)

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "12px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }

    }
}))(TextField)

const MyFormField = withStyles(() => ({
    root: {
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input:focus": {
            backgroundColor: "#FFFFFF"
        }
    }
}))(FormControl)


const iconStyles = {
    selectIcon: {
        color: "#8C9196"
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <KeyboardArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const AddOnLocationModal = ({ planDetails, setLocModal }) => {








    const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "BMP"];

    const openHours = [
        '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM',
        '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM', '12AM',
    ]
    const [weekSelection, setWeekSelection] = useState({
        open_at: '9AM',
        close_at: '5PM'
    })


    const addLocDet = [
        'Congratulations on your business expansion!',
        'An additional location comes with 1 additional terminal and 1 additional user.',
        'One time add-on will be billed for a single time only and recurring add-on will be billed the same day of the every billing cycle.'
    ]

    const alertMessage = "Please deselect the location to add a new store."

    const [dayHourSelection, setDayHourSelection] = useState(
        [
            {
                'name': 'MONDAY',
                'subName': 'Mon',
                'checked': false,
                'open_at': '9AM',
                'close_at': '5PM'
            }, {
                'name': 'TUESDAY',
                'subName': 'Tue',
                'checked': false,
                'open_at': '9AM',
                'close_at': '5PM'
            },
            {
                'name': 'WEDNESDAY', 'subName': 'Wed', 'checked': false, 'open_at': '9AM', 'close_at': '5PM'
            },
            {
                'name': 'THURSDAY', 'subName': 'Thu', 'checked': false, 'open_at': '9AM', 'close_at': '5PM'
            },
            {
                'name': 'FRIDAY', 'subName': 'Fri', 'checked': false, 'open_at': '9AM', 'close_at': '5PM'
            },
            {
                'name': 'SATURDAY', 'subName': 'Sat', 'checked': false, 'open_at': '9AM', 'close_at': '5PM'
            },
            {
                'name': 'SUNDAY', 'subName': 'Sun', 'checked': false, 'open_at': '9AM', 'close_at': '5PM'
            },
        ]
    )
    // const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']


    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [hourError, setHourError] = useState(false);

    const [storeError, setStoreError] = useState(false);

    const [helperText, setHelperText] = useState('');
    const [message, setMessage] = useState('');
    const [errorResponse, setErrorResponse] = useState(false);
    const [typeSelected, setTypeSelected] = useState(false);
    const [textFieldsShow, setTextFieldShow] = useState(true);
    const [oneTimeAmount, setOneTimeAmount] = useState('');
    const [recurringAmount, setRecurringAmount] = useState('');
    const [fields, setFields] = useState({
        email: "",
        storeName: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        zipCode: "",
        country: "",
        phone: "",
    });

    const [formError, setFormError] = useState({});
    const [countryCode, setCountryCode] = useState('US')
    const [storeList, setStoreList] = useState([]);
    const [showAddLocForm, setAddLocForm] = useState(false);
    const [storeType, setStoreType] = useState('');
    const [storeHour, setStoreHour] = useState(-1)
    const [notAllowedStoreKey, setNotAllowedStoreKey] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [storeNameExist, setStoreNameExist] = useState(false)
    const [storeLogo, setStoreLogo] = useState(null);
    const [storeMap, setStoreMap] = useState(null);
    const [storeLogoError, setStoreLogoError] = useState(false);
    const [storeMapError, setStoreMapError] = useState(false);
    const [storeLogoName, setStoreLogoName] = useState('');
    const [storeMapName, setStoreMapName] = useState('');
    const [bothFromOpen, setBothFormOpen] = useState(false);


    useEffect(() => {
        setOneTimeAmount(planDetails['onetime'].price);
        setRecurringAmount(planDetails['recurring'].price);
        async function getStores() {
            const storeList = await getNotAllowedStore();
            const stores = storeList.data.ResponseData.data;
            if (stores.length > 0){
                stores.unshift({ key: '', storeName: 'Select a store' }); 
            }
            setStoreList(stores);
            if (stores.length === 0) {
                addLocationFormHandle();
            }
        }
        getStores();
    }, [])

    const handleRadioChange = (event) => {
        const choice = event.target.value;
        setValue(choice);
        setError(false);
        setHelperText('');
        if (choice === '') {
            setTypeSelected(false)
        } else {
            setTypeSelected(true);
            if (!planDetails[choice].price || planDetails[choice].price === 0) {
                setTextFieldShow(false)
            } else {
                setTextFieldShow(true)
            }

        }
    };


    const handleRadioChangeStoreType = (event) => {
        setStoreType(event.target.value);
        setTypeError(false)
    }

    const handleRadioChangeStoreHourType = (event) => {
        setStoreHour(event.target.value)
        setHourError(false)
    }

    const validateForm = async () => {
        setFormError(validateFields())
        if (storeType === '' && showAddLocForm) {
            setTypeError(true);
            return false;
        }
        if (storeHour === -1 && showAddLocForm) {
            setHourError(true);
            return false;
        }
        // if (emailExist) {
        //     return false;
        // }

        if (storeNameExist) {
            return false
        }

        if (value === '') {
            setError(true);
            setHelperText('Please select an option.');
            return false;
        }


        if (notAllowedStoreKey === '' && showAddLocForm == false) {
            setStoreError(true);
            return false;
        }

        if (notAllowedStoreKey !== '' && showAddLocForm && fields.storeName !== '') {
            setBothFormOpen(true);
            return false;
        }

        return true;
    }


    const handleStoreForm = async () => {

        let storeURL = '';
        let mapURL = '';
        if (storeLogo) {
            storeURL = await firebaseFileUpload(storeLogo);
        }
        if (storeMap) {
            mapURL = await firebaseFileUpload(storeMap);
        }

        const formValid = await validateForm()
        if (!formValid) {
            return;
        }


        const checkTax = await taxAddressCheck()
        if (checkTax.status === 200) {
            if ((checkTax.data.tax_enabled !== 0)) {
                setMessage(checkTax.data.tax_status)
                setErrorResponse(true);
                return;
            }
        }
        try {
            const data = {
                "type": "ADDITIONAL_LOCATION",
                "formData": {
                    "STORE": {
                        "ADDRESS": fields.address1,
                        "ADDRESS_LINE_2": fields.address2,
                        "AISLE_MAP": mapURL,
                        "CITY": fields.city,
                        "COUNTRY": fields.country,
                        "CREATED_BY": "",
                        "CREATED_ON": 0,
                        "EMAIL": fields.email,
                        "IS_DELETED": 0,
                        "NOT_ALLOWED": 0,
                        "PARENT_STORE": "",
                        "PHONE": fields.phone,
                        "RECEIPT_SETTINGS": {
                            "DISPLAY_CUSTOM_MESSAGE": 0,
                            "GROUP_CATEGORY": 0,
                            "PRINT_CASHIER_NAME": 1,
                            "PRINT_PROD_CODE": 1,
                            "PRINT_STORE_LOGO": 0
                        },
                        "STATE": fields.state,
                        "STORES_SALES_TAX": {
                            "COMBINED_SALES_TAX_AMOUNT": 5,
                            "COMBINED_SALES_TAX_TYPE": 1,
                            "HAS_MAX_TAX_EXEMPT": 0,
                            "MAX_TAX_EXEMPT_AMOUNT": 0
                        },
                        "STORE_CODE": "",
                        "STORE_HOURS": "",
                        "STORE_LOGO": storeURL,
                        "STORE_NAME": fields.storeName,
                        "STORE_TYPE": storeType,
                        "UPDATED_BY": "",
                        "UPDATED_ON": 0,
                        "WEBSITE": "",
                        "ZIPCODE": fields.zipCode
                    },
                    "HOUR": {
                        "CUS_TYPE_MONDAY": dayHourSelection[0].checked,
                        "CUS_TYPE_MONDAY_FROM": dayHourSelection[0].open_at,
                        "CUS_TYPE_MONDAY_TO": dayHourSelection[0].close_at,
                        "CUS_TYPE_TUESDAY": dayHourSelection[1].checked,
                        "CUS_TYPE_TUESDAY_FROM": dayHourSelection[1].open_at,
                        "CUS_TYPE_TUESDAY_TO": dayHourSelection[1].close_at,
                        "CUS_TYPE_WEDNESDAY": dayHourSelection[2].checked,
                        "CUS_TYPE_WEDNESDAY_FROM": dayHourSelection[2].open_at,
                        "CUS_TYPE_WEDNESDAY_TO": dayHourSelection[2].close_at,
                        "CUS_TYPE_THURSDAY": dayHourSelection[3].checked,
                        "CUS_TYPE_THURSDAY_FROM": dayHourSelection[3].open_at,
                        "CUS_TYPE_THURSDAY_TO": dayHourSelection[3].close_at,
                        "CUS_TYPE_FRIDAY": dayHourSelection[4].checked,
                        "CUS_TYPE_FRIDAY_FROM": dayHourSelection[4].open_at,
                        "CUS_TYPE_FRIDAY_TO": dayHourSelection[4].close_at,
                        "CUS_TYPE_SATURDAY": dayHourSelection[5].checked,
                        "CUS_TYPE_SATURDAY_FROM": dayHourSelection[5].open_at,
                        "CUS_TYPE_SATURDAY_TO": dayHourSelection[5].close_at,
                        "CUS_TYPE_SUNDAY": dayHourSelection[6].checked,
                        "CUS_TYPE_SUNDAY_FROM": dayHourSelection[6].open_at,
                        "CUS_TYPE_SUNDAY_TO": dayHourSelection[6].close_at,



                        "HOUR_TYPE": Number(storeHour),
                        "WORK_ALL_DAY_FROM": weekSelection.open_at,
                        "WORK_ALL_DAY_TO": weekSelection.close_at
                    },
                }
            }

            const addOnCode = planDetails[value].code;
            if (notAllowedStoreKey !== '' && notAllowedStoreKey !== undefined && notAllowedStoreKey !== null) {
                const result = await handleSubscribeStore([{ "sp_code": addOnCode, "quantity": 1 }], notAllowedStoreKey, 'Exist');
                if (result.data.url) {
                    window.location.href = result.data.url
                }
            } else {
                const returnData = await addLocationInitialGrow(data);
                const returnKey = returnData.data.ResponseData.data.pushkey
                const result = await handleSubscribeStore([{ "sp_code": addOnCode, "quantity": 1 }], returnKey, 'New');
                if (result.data.url) {
                    window.location.href = result.data.url
                }
            }
        } catch (err) {
            setMessage('Cannot use addons from Free plan.')
            setErrorResponse(true);
            return;
        }

    }


    const handleClose = () => {
        setLocModal(false);
    }


    const handleInputChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setFields({ ...fields, [name]: value });
        delete formError[name];
        if (name === 'email') {
            setFields({ ...fields, [name]: value.toLowerCase() });
            setEmailExist(false);
            setInvalidEmail(false)
        }
        setFormError(formError);
    }


    const handleWeekSelection = (event) => {

        const { value } = event.target;
        const { name } = event.target;

        setWeekSelection({ ...weekSelection, [name]: value })
    }

    const handleDayHourSelection = (event, index) => {
        const original = [...dayHourSelection];
        const { value } = event.target
        const { name } = event.target

        if (name === 'open_at') {
            original[index].open_at = value;
        } else {
            original[index].close_at = value;
        }
        setDayHourSelection(original);
    }

    const handleDayCheck = (element, index) => {
        const original = [...dayHourSelection];
        const addData = {
            name: element.name,
            subName: element.subName,
            checked: !(element.checked),
            open_at: element.open_at,
            close_at: element.close_at
        }
        // dayHourSelection[index]
        original[index] = addData
        setDayHourSelection(original);
    }


    const addLocationFormHandle = () => {
        setAddLocForm(true);
    }



    const handleChangeStoreLogo = (file) => {
        setStoreLogoError(false);
        const max_size = 5242880;
        if (file.size > max_size) {
            setStoreLogoError(true);
        } else {
            setStoreLogoName(file.name)
            setStoreLogo(file);
        }
    };



    const handleChangeStoreMap = (file) => {
        

        setStoreMapError(false);
        const max_size = 5242880;
        if (file.size > max_size) {
            setStoreMapError(true);
        } else {
            setStoreMapName(file.name)
            setStoreMap(file);
        }

    };


    const handleNotAllowedOption = (event) => {
        setStoreError(false);
        setBothFormOpen(false);
        setNotAllowedStoreKey(event.target.value.key)
    }

    const validateFields = () => {
        let errors = {}
        for (let key in fields) {
            if (key !== 'address2') {
                if (fields[key] === "") {
                    errors[key] = true
                }
            }
        }
        return errors;
    }

    const emailValidates = async (event) => {
        const validEmail = emailValidation(event.target.value);
        validEmail ? setInvalidEmail(false) : setInvalidEmail(true);
        if (validEmail) {
            const param = {
                filterNode: "STORE_EMAIL",
                filterValue: event.target.value
            }
            const result = await storeValidation(param);
            setEmailExist(result.data.ResponseData.taken);

        }
    }

    const storeNameVals = async (event) => {
        const param = {
            filterNode: "STORE_NAME",
            filterValue: event.target.value
        }
        const result = await storeValidation(param);
        setStoreNameExist(result.data.ResponseData.taken);
    }



    const styleobj = {
        marginTop: "36px",
        borderBottom: "2px solid #BABFC5",
    }
    const emailErrorObj = {
        color: "#FF4040",
    }

    return (
        <>
            <div className="addonlocmodal">
                <div className="overlay"><></></div>
                <div className="addon-modal-loc-content">

                    {!errorResponse ?

                        <Grid sx={{
                            p: { lg: "40px", md: "40px", sm: "30px", xs: "20px" },
                            height: "auto",
                            mt: { xs: "20px", sm: "20px", md: "0px" }
                        }}>
                            <MKTypography fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, }}> <span className="semi-bold">{planDetails.name}</span>

                            </MKTypography>

                            <MKBox>
                                <MKTypography sx={{
                                    // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                    overflow: "auto"
                                }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                                    <div>


                                        <div className='addon-loc-content'>

                                            <div>
                                                {addLocDet[0]}
                                            </div>
                                            <div>
                                                {addLocDet[1]}
                                            </div>
                                            <div>
                                                {addLocDet[2]}
                                            </div>

                                        </div>





                                        <div style={{ marginTop: '30px' }}>
                                            <FormControl error={error}>
                                                <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>Type of Add-on</MKTypography>

                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={handleRadioChange}
                                                >
                                                    {planDetails.onetime?.code ?
                                                        <FormControlLabel mb={'40px'} value="onetime" control={<Radio sx={{
                                                            color: '#50B748',
                                                            '&.Mui-checked': {
                                                                color: '#50B748',
                                                            },

                                                        }} />} label={"One Time"} />
                                                        : ""}

                                                    <MKTypography fontSize="16px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.onetime.price)}</span>)</MKTypography>


                                                    {planDetails.recurring?.code ?
                                                        <FormControlLabel mb={'40px'} value="recurring" control={<Radio sx={{
                                                            color: '#50B748',
                                                            '&.Mui-checked': {
                                                                color: '#50B748',
                                                            },
                                                        }} />} label="Recurring Monthly" />
                                                        : ""}
                                                    <MKTypography fontSize="16px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.recurring.price)}</span>)</MKTypography>

                                                </RadioGroup>
                                                <FormHelperText>{helperText}</FormHelperText>
                                            </FormControl>
                                        </div>


                                    </div>

                                </MKTypography>

                                {storeList.length > 0 ?
                                    <Grid>
                                        <MKTypography className="semi-bold" fontSize="16px" sx={{ marginTop: '50px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>Want to power up a location that is currently inactive?</MKTypography>

                                        <Grid item container sx={12} sm={6}>
                                            <MyFormField fullWidth variant="standard" error={storeError || bothFromOpen}>
                                                <MyInputLabel style={{ fontFamily: "OpenSansRegular" }}>Store Location</MyInputLabel>

                                                <Select
                                                    variant="standard"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Store Location"
                                                    name="location"
                                                    IconComponent={CustomExpandMore}
                                                    onChange={handleNotAllowedOption}
                                                    sx={{
                                                        width: '380px',
                                                        marginTop: '20px'
                                                    }}
                                                >
                                                    {storeList.map((element) => <MenuItem key={element.key} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element.storeName}</MenuItem>)}
                                                </Select>
                                                <FormHelperText>{storeError ? "Please select a store" : bothFromOpen?
                                                alertMessage:""}
                                                </FormHelperText>
                                            </MyFormField>
                                            <MKTypography className="semi-bold" fontSize="16px" sx={{ marginTop: '50px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 2 }}>Or
                                                <Button onClick={addLocationFormHandle} variant="text" className="semi-bold" sx={{ color: '#50B748', fontSize: "16px", marginTop: '-3px' }}>Add as a new location?</Button>
                                            </MKTypography>

                                        </Grid>
                                    </Grid>
                                    : ""}
                                {showAddLocForm ?

                                    <>
                                        <MKTypography className="semi-bold" fontSize="16px" sx={{ marginTop: '20px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>Fill in the details of your new location —</MKTypography>

                                        <Grid item container xs={12} sm={12} sx={{ display: 'flex', direction: 'column', justifyContent: 'start' }}>
                                            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: {sm:'120px', xs: '0px'} }}>
                                                <MyTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    label="Store Name"
                                                    name="storeName"
                                                    variant="standard"
                                                    onBlur={storeNameVals}
                                                    error={formError.storeName}
                                                    helperText={formError.storeName ? 'Store name is required' : ''}
                                                    value={fields.storeName}
                                                    onChange={handleInputChange}
                                                    InputLabelProps={{
                                                        style: { fontFamily: "OpenSansRegular" }
                                                    }}
                                                />
                                                {storeNameExist ?
                                                    <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "42px", fontFamily: "OpenSansRegular" }}>
                                                        Looks like this Store Name is already registered with us!
                                                    </MKTypography> : ""}
                                            </Grid>
                                            <Grid xs={12} sm={7} md={7} lg={5}  sx={{ justifyContent: 'start' }} >
                                                <MyTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    name="email"
                                                    autoComplete="email"
                                                    value={fields.email}
                                                    error={formError.email || invalidEmail}
                                                    helperText={formError.email ? "Email is required"
                                                        : invalidEmail ? fields.email === "" ? "Email is required"
                                                            : "Invalid Email" : ""}
                                                    onChange={handleInputChange}
                                                    onBlur={emailValidates}
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
                                                    }}
                                                />
                                                {emailExist ?
                                                    <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "42px", fontFamily: "OpenSansRegular" }}>
                                                        Looks like this email is already registered with us!
                                                    </MKTypography> : ""}

                                            </Grid>

                                            <AddressForm
                                                fieldsdisabled={false}
                                                fields={fields}
                                                setFields={setFields}
                                                formError={formError}
                                                setFormError={setFormError}
                                                setCountryCode={setCountryCode}
                                                pageName={"AddonLocation"}
                                            />

                                            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: '120px' }}>
                                                <div style={{ marginTop: '40px' }}>
                                                    <FormControl error={typeError}>
                                                        <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>Type of Store</MKTypography>

                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={handleRadioChangeStoreType}
                                                        >

                                                            <FormControlLabel mb={'40px'} sx={{ marginRight: '110px' }} value="Retail" control={<Radio sx={{
                                                                color: '#50B748',
                                                                '&.Mui-checked': {
                                                                    color: '#50B748',
                                                                },

                                                            }} />} label="Retail" />



                                                            <FormControlLabel mb={'40px'} value="Warehouse" control={<Radio sx={{
                                                                color: '#50B748',
                                                                '&.Mui-checked': {
                                                                    color: '#50B748',
                                                                },
                                                            }} />} label="Warehouse" />

                                                        </RadioGroup>
                                                        <FormHelperText>{typeError ? "Type of Store is required" : ""}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            <Grid xs={12} sm={7} md={7} lg={4.9} sx={{ justifyContent: 'start', }}>
                                                <div style={{ marginTop: '40px' }}>
                                                    <FormControl error={hourError}>
                                                        <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>Hours of Operation</MKTypography>

                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={handleRadioChangeStoreHourType}
                                                        >

                                                            <FormControlLabel mb={'20px'} value={1} control={<Radio sx={{
                                                                color: '#50B748',
                                                                '&.Mui-checked': {
                                                                    color: '#50B748',
                                                                },

                                                            }} />} label="Mon-Sun" />



                                                            <FormControlLabel mb={'20px'} value={2} control={<Radio sx={{
                                                                color: '#50B748',
                                                                '&.Mui-checked': {
                                                                    color: '#50B748',
                                                                },
                                                            }} />} label="24 x 7" />

                                                            <FormControlLabel mb={'20px'} value={3} control={<Radio sx={{
                                                                color: '#50B748',
                                                                '&.Mui-checked': {
                                                                    color: '#50B748',
                                                                },
                                                            }} />} label="Customize Days" />

                                                        </RadioGroup>
                                                        <FormHelperText>{hourError ? "Hours of Operation is required" : ""}</FormHelperText>
                                                    </FormControl>
                                                    {storeHour == 1 ?
                                                        <MKBox key={'weekSelection'} sx={{ mt: '40px' }}>
                                                            <Grid item container sx={12}>
                                                                <Grid key={'open_at'} xs={5} sm={5}>
                                                                    <Select
                                                                        variant="standard"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Mon-Sun"
                                                                        name="open_at"
                                                                        value={weekSelection.open_at}
                                                                        onChange={handleWeekSelection}
                                                                        IconComponent={() => (
                                                                            <KeyboardArrowDownIcon style={{ color: '#8C9196' }} />
                                                                        )}
                                                                    >

                                                                        {openHours.map(element => <MenuItem key={element} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element}</MenuItem>)}
                                                                    </Select>
                                                                </Grid>
                                                                <Grid key={'na'} xs={1} sm={2} sx={{ fontSize: '22px', fontWeight: 700 }}>
                                                                    -
                                                                </Grid>
                                                                <Grid key={'close_at'} xs={5} sm={5}>
                                                                    <Select
                                                                        variant="standard"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Business Type"
                                                                        name="close_at"
                                                                        value={weekSelection.close_at}
                                                                        onChange={handleWeekSelection}
                                                                        IconComponent={() => (
                                                                            <KeyboardArrowDownIcon style={{ color: '#8C9196' }} />
                                                                        )}
                                                                    >

                                                                        {openHours.map(element => <MenuItem key={element} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element}</MenuItem>)}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>

                                                        </MKBox>
                                                        : ""}
                                                    {storeHour == 3 ?
                                                        <MKBox key={'daySelection'} sx={{ mt: '40px' }}>

                                                            {dayHourSelection.map((element, index) => {
                                                                return (
                                                                    <Grid item container sm={12} sx={{ mt: '26px' }}>
                                                                        <Grid sm={4}>
                                                                            <FormControlLabel onChange={() => handleDayCheck(element, index)} control={<Checkbox />} label={element.subName} />
                                                                        </Grid>
                                                                        <Grid key={element.name} sm={4}>
                                                                            <Select
                                                                                variant="standard"
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                label="Business Type"
                                                                                name="open_at"
                                                                                value={element.open_at}
                                                                                onChange={(e) => handleDayHourSelection(e, index)}
                                                                                IconComponent={() => (
                                                                                    <KeyboardArrowDownIcon style={{ color: '#8C9196' }} />
                                                                                )}
                                                                            >

                                                                                {openHours.map(ele => <MenuItem key={ele} value={ele} sx={{ fontFamily: "OpenSansRegular" }}>{ele}</MenuItem>)}
                                                                            </Select>

                                                                        </Grid>
                                                                        <Grid key={element.subName} sm={4}>
                                                                            <Select
                                                                                variant="standard"
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                label="Business Type"
                                                                                name="close_at"
                                                                                value={element.close_at}
                                                                                onChange={(e) => handleDayHourSelection(e, index)}
                                                                                IconComponent={() => (
                                                                                    <KeyboardArrowDownIcon style={{ color: '#8C9196' }} />
                                                                                )}
                                                                            >

                                                                                {openHours.map(ele => <MenuItem key={ele} value={ele} sx={{ fontFamily: "OpenSansRegular" }}>{ele}</MenuItem>)}
                                                                            </Select>

                                                                        </Grid>


                                                                    </Grid>
                                                                )
                                                            })}

                                                        </MKBox>
                                                        : ""}
                                                </div>
                                            </Grid>

                                            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: { lg: '120px', sm:'0px', xs: '0px' }, mt: '50px' }}>
                                                <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>Store Map</MKTypography>

                                                <FileUploader key={'map'} handleChange={handleChangeStoreMap} name="file" types={fileTypes}>
                                                    <MKBox className='drop_area_field'>
                                                        <img src={uploadArrow} style={{ float: 'left', marginRight: '5px' }} width="28px" height="20px" alt="Upload"></img>

                                                        <div className="fileuploadText" style={{
                                                            color: '#6D7175',
                                                            fontSize: '14px',
                                                            letterSpacing: '0px',
                                                            marginLeft: '5px',
                                                            verticalAlign: 'middle',
                                                        }}>

                                                            {storeMapName === '' ?
                                                                <span>
                                                                    Drop file to upload or
                                                                </span>
                                                                :
                                                                < span >
                                                                    {storeMapName}
                                                                </span>
                                                            }


                                                        </div>
                                                        <MKButton
                                                            variant="outlined"
                                                            color="primary"
                                                            className="browseBtn"
                                                            size="medium"
                                                            sx={{
                                                                width: '100px',
                                                                height: '40px',
                                                                fontSize: "14px",
                                                                borderRadius: "4px",
                                                                color: "#50B748",
                                                                borderColor: "#50B748",

                                                                verticalAlign: "middle",
                                                                lineHeight: 1.5,
                                                                textTransform: "capitalize",

                                                            }}

                                                        >
                                                            Browse
                                                        </MKButton>

                                                    </MKBox>
                                                    {storeMapError ?
                                                        <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "42px", fontFamily: "OpenSansRegular" }}>
                                                            Can't upload more than 5 MB file.
                                                        </MKTypography> : ""}
                                                </FileUploader>
                                            </Grid>

                                            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mt: '50px' }}>
                                                <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>Store Logo</MKTypography>

                                                <FileUploader key={'logo'} handleChange={handleChangeStoreLogo} name="file" types={fileTypes}>
                                                    <MKBox className='drop_area_field'>
                                                        <img src={uploadArrow} style={{ float: 'left', marginRight: '5px' }} width="28px" height="20px" alt="Upload"></img>

                                                        <div className="fileuploadText" style={{
                                                            color: '#6D7175',
                                                            fontSize: '14px',
                                                            letterSpacing: '0px',
                                                            marginLeft: '5px',
                                                            verticalAlign: 'middle',
                                                        }}>
                                                            <span>
                                                                {storeLogoName === '' ?
                                                                    <span>
                                                                        Drop file to upload or
                                                                    </span>
                                                                    :
                                                                    < span >
                                                                        {storeLogoName}

                                                                    </span>
                                                                }
                                                            </span>
                                                        </div>
                                                        <MKButton
                                                            variant="outlined"
                                                            color="primary"
                                                            className="browseBtn"
                                                            size="medium"
                                                            sx={{
                                                                width: '100px',
                                                                height: '40px',
                                                                fontSize: "14px",
                                                                borderRadius: "4px",
                                                                color: "#50B748",
                                                                borderColor: "#50B748",
                                                                fontFamily: "OpenSansMedium",
                                                                verticalAlign: "middle",
                                                                lineHeight: 1.5,
                                                                textTransform: "capitalize",

                                                            }}

                                                        >
                                                            Browse
                                                        </MKButton>

                                                    </MKBox>
                                                    {storeLogoError ?
                                                        <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "42px", fontFamily: "OpenSansRegular" }}>
                                                            Can't upload more than 5 MB file.
                                                        </MKTypography> : ""}
                                                </FileUploader>
                                            </Grid>

                                        </Grid>
                                    </>
                                    : ""}

                                <Grid lg={12} sx={{ mt: '50px', justifyContent: { md: 'start', sm: 'center', xs: 'center' }, }} container item>

                                    <MKBox sx={{ display: "inline-block", }}>
                                        <MKButton
                                            variant="outlined"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"
                                            sx={{
                                                width: '100px',
                                                height: '40px',
                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                mr: "50px",
                                                verticalAlign: "middle",
                                                lineHeight: 1.5,
                                                textTransform: "capitalize",

                                            }}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </MKButton>
                                        <MKButton
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className="upgrade"
                                            sx={{
                                                ':hover': {
                                                    bgcolor: '#3CAC33',
                                                    color: '#FFFFFF',

                                                },
                                                width: '100px',
                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize",

                                            }}
                                            onClick={handleStoreForm}
                                        >
                                            Buy now
                                        </MKButton>
                                    </MKBox>
                                </Grid>
                            </MKBox>
                        </Grid>
                        :
                        < Grid sx={{
                            p: '24px',
                            // height: "auto",
                            mt: { xs: "60px", sm: "60px", md: "0px" }
                        }}>
                            <MKBox xs={12} sx={{ justifyContent: 'center' }}>
                                <div style={{ textAlign: 'center' }}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                            </MKBox>
                            <MKBox>
                                <MKTypography sx={{
                                    // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                    overflow: "auto"
                                }} fontSize="16px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                    <span style={{ fontSize: '20px' }}> {message}</span> <br />


                                </MKTypography>
                                <Grid xs={12} lg={12} sx={{ mt: '48px', justifyContent: "Center" }} container item>
                                    <MKBox xs={12} sx={{ display: "inline-block", justifyContent: "Center" }}>
                                        <MKButton
                                            variant="text"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"

                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                verticalAlign: "middle",
                                                lineHeight: 1.5,
                                                textTransform: "capitalize",


                                            }}
                                            onClick={handleClose}
                                        >
                                            OK
                                        </MKButton>

                                    </MKBox>
                                </Grid>
                            </MKBox>
                        </Grid>

                    }
                </div>
            </div>

        </>
    )
}

export default AddOnLocationModal