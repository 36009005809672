import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MKBox from "../../../components//MKBox";
import AddressEditPopup from "./addressEditPopup";
import Lottie from "lottie-react";
import growLoader from "../../../assets/animation/growLoader.json";
import { getMerchantBillingAddress } from "../../../providers/profileChagne"

const BillingAddressDetails = ({ merchantURL, toggleChange, toggleValue }) => {

    const [mAddress, setMAddress] = useState({
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: ''
    })

    const [editAddressPopup, setEditAddressPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [merchDetails, setMerchDetails] = useState({});

    const toggleModal = () => {
        toggleChange(!toggleValue)
        setEditAddressPopup(!editAddressPopup);
    };


    if (toggleValue) {
        document.body.classList.add('active-editAdd-modal')
    } else {
        document.body.classList.remove('active-editAdd-modal')
    }

    useEffect(() => {
        const getAddress = async (merchantURL) => {
            const result = await getMerchantBillingAddress(merchantURL);
            const merchDetails = result.data.ResponseData;
            setMerchDetails(merchDetails);
            const addressTwo = (merchDetails.MERCHANT_ADDRESS2.trim().length === 0 ? '.' : ', ' + merchDetails.MERCHANT_ADDRESS2 + '.')
            const addObj = {
                name: merchDetails.FIRST_NAME + ' ' + merchDetails.LAST_NAME,
                address: merchDetails.MERCHANT_ADDRESS + addressTwo,
                city: merchDetails.MERCHANT_CITY,
                state: merchDetails.MERCHANT_STATE,
                country: merchDetails.MERCHANT_COUNTRY.toUpperCase(),
                zipCode: merchDetails.MERCHANT_ZIPCODE
            }
            setMAddress(addObj)
        }
        getAddress(merchantURL)
    }, [])

    const updateAddressDisplay = async () => {
        setIsLoading(true);
        const details = await getMerchantBillingAddress(merchantURL);
        const merchDetails = details.data.ResponseData;
        const addressTwo = (merchDetails.MERCHANT_ADDRESS2.trim().length === 0 ? '.' : ', ' + merchDetails.MERCHANT_ADDRESS2 + '.')
        const addObj = {
            name: merchDetails.FIRST_NAME + ' ' + merchDetails.LAST_NAME,
            address: merchDetails.MERCHANT_ADDRESS + addressTwo,
            city: merchDetails.MERCHANT_CITY,
            state: merchDetails.MERCHANT_STATE,
            country: merchDetails.MERCHANT_COUNTRY.toUpperCase(),
            zipCode: merchDetails.MERCHANT_ZIPCODE
        }
        setMAddress(addObj)
        setIsLoading(false);
    }


    const changeBillingAddress = () => {
        toggleModal();
        // document.getElementById('planCard').scrollIntoView();
    }

    const converToCamelCase = (name) => {
        return name.substring(0, 1).toUpperCase() + name.substring(1, name.length).toLowerCase();
    }

    const converToCamelCaseCountry = (name) => {
        let countryName = '';
        const array = name.split(" ");
        array.forEach((word) => {
            countryName += converToCamelCase(word) + " ";
        })
        return countryName;
    }

    return (

        <Grid container>
            <Grid item container xs={12} >
                <Grid item container xs={12} sm={12} md={12} sx={{ mt: '32px' }} >

                    {!isLoading ?
                    <>
                    <Grid item container xs={5.5} sm={2} md={2} lg={4} direction="column" justifyContent="end start" >
                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", opacity: 1, lineHeight: 2 }} sx={{ textAlign: { lg: 'right', md: 'left', sm: 'left', xs: 'left' } }} > <span className="semi-bold"> Billing Address:</span></MKTypography>
                        {/* <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "right", opacity: 1, lineHeight: 2 }}> <span className="semi-bold"> Address :</span></MKTypography> */}
                    </Grid>
                    <Grid item container xs={5.5} sm={9} md={9} lg={7} direction="column" justifyContent="start" sx={{ ml: 3 }} >

                        {/* <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {mAddress.name} </span></MKTypography> */}
                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {mAddress.address} </span></MKTypography>
                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {mAddress.city}, {mAddress.state} {mAddress.zipCode} </span></MKTypography>

                                <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {converToCamelCaseCountry(mAddress.country)}</span></MKTypography>



                    </Grid>
                        </>
                    : 
                        <Grid item xs={12} sx={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                            
                            <MKBox
                                sx={{
                                    ml: 40,
                                    width: 100,
                                    height: 100,
                                }}>
                                <Lottie animationData={growLoader} loop={true} />
                            </MKBox>
                        </Grid>
}
                </Grid>
            </Grid>


            <Grid>
                {toggleValue && (
                    <AddressEditPopup toggleModal={toggleModal} merchantURL={merchantURL} updateAddressDisplay={updateAddressDisplay} merchDetails={merchDetails} />
                )}
            </Grid>

        </Grid>

    )
}

export default BillingAddressDetails;