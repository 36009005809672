import React from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";

const ConfirmPopup = ({ confirmYes, confirmNo }) => {

    return (
        <>
            <div className="confirmmodal">
                <div className="overlay"><></></div>
                <div className="confirm-popup-content">
                    <Grid sx={{
                        p: '24px',
                        // height: "auto",
                        mt: { xs: "60px", sm: "60px", md: "0px" }
                    }}>
                        <MKBox>
                            <MKTypography sx={{
                                // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                overflow: "auto"
                            }} fontSize="16px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                <span className='semi-bold' style={{ fontSize: '18px' }}> Are you sure want remove the card? </span>

                            </MKTypography>
                            <Grid lg={12} sx={{ mt: { xl: "64px", lg: "64px", md: "64px" },
                            justifyContent: "center", alignContent: "center", textAlign: "center"
                        }} container item>
                                <MKBox sx={{ display: "inline-block" }}>
                                    <MKButton
                                        variant="outlined"
                                        color="primary"
                                        className="upgrade"
                                        size="medium"
                                        sx={{

                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            color: "#50B748",
                                            borderColor: "#50B748",
                                            mr: { xs: "50px", sm: "50px", md: "50px" },
                                            verticalAlign: "middle",
                                            lineHeight: 1.5
                                        }}
                                        onClick={confirmNo}
                                    >
                                        NO
                                    </MKButton>
                                    <MKButton
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        className="upgrade"
                                        sx={{
                                            ':hover': {
                                                bgcolor: '#3CAC33',
                                                color: '#FFFFFF',

                                            },
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            verticalAlign: "middle"
                                        }}
                                        onClick={confirmYes}
                                    >
                                        YES
                                    </MKButton>
                                </MKBox>
                            </Grid>
                        </MKBox>
                    </Grid>
                </div>
            </div>

        </>
    )
}

export default ConfirmPopup;