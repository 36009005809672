import React from "react"
import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@mui/material/FormControl';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import MKBox from "../MKBox";
import "./phonestyle.css"

export const useStyles = makeStyles(theme => ({
    input: {
        // backgroundColor: '#fff',
        // width: "374px",
        paddingLeft: "0px !important"
    }
}))

const MyTextField = withStyles(() => ({
    root: {
        "& .MuiInput-underline:before": {
            borderBottom: "1px dotted #BABFC5",
        },
        /* hover (double-ampersand needed for specificity reasons. */
        // "&& .MuiInput-underline:hover": {
        //   borderBottom: "1px solid",
        // },
        /* focused */
        "&.MuiInput-underline:after": {
            // borderBottom: "1px solid #1A73E8",
        },
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            marginBottom: "0px"
        },
        "& .MuiInputBase-input": {
            marginTop: "25px",
            marginBottom: "8px",
        }
    }
}))(TextField)

const PhoneInput = (props, ref) => {
    const classes = useStyles()

    return (
        <MKBox component="main" sx={{
            height: "auto",
            mt: "auto",
            mb: "auto",
            ml: {
                lg: "30px",
                sm: "0px",
                md: "0px",
                xs: "0px",
            },
        }}>
            <Grid container spacing={2}>
                <Grid>
                    <FormControl>
                        <MyTextField
                            {...props}
                            // margin="normal" 
                            // required
                            fullWidth
                            id="MobileNo"
                            label=""
                            name="phone"
                            inputRef={ref}
                            InputProps={{
                                className: classes.input,
                                disableUnderline: true
                            }}
                            variant="standard"
                            InputLabelProps={{
                                style: { fontFamily: "OpenSansRegular" }
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </MKBox>
    )
}
export default forwardRef(PhoneInput)