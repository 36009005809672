const axios = require('axios');

const completeMerchantService = async (regMer) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const urlLink = apiUrl + "/growapi/savemerchant";
  return await axios.post(urlLink, JSON.stringify(regMer), {
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}
export default completeMerchantService;