import { getAuth } from "firebase/auth";
const axios = require('axios');


export const addLocationInitialGrow = async (storeObj) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "growapi/addonpayload";
    
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return await axios.post(urlLink, JSON.stringify(storeObj), {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const getPaymentSessionDetails = async (id) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getAddonStatus";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    const data = {
        session_id: id
    }

    return await axios.post(urlLink, JSON.stringify(data), {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const getAllowedStore = async (id) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getstoreallowed";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    const data = {
        session_id: id
    }

    return await axios.post(urlLink, {}, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const getNotAllowedStore = async (id) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getstorenotallowed";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return await axios.post(urlLink, {}, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const getAllStore = async (id) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getstoreall";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    const data = {
        session_id: id
    }

    return await axios.post(urlLink, {}, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const getTermCount = async (storeKey) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getAddonterminalCount";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    const param = { storekey: storeKey }; 

    return await axios.post(urlLink, param, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}



export const getStoreDetails = async (pushKey) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getAddonStoreDetail";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    // const param = { pushkey: pushKey };

    return await axios.post(urlLink, {}, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export const storeValidation = async (param) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/addonStoreValidation";

    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    return await axios.post(urlLink, param, {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

const getToken = () => {
    return getAuth()
        .currentUser?.getIdToken(true)
        .then((idToken) => {
            return idToken;
        });
}