export function formatDate(newDate, type) {
    if (newDate === '') {
        return ''
    }
  const time = new Date(newDate * 1000).toLocaleTimeString();
    const months = {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December"
    };
    // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
    const d =  new Date(newDate * 1000);
    const year = d.getFullYear();
    const date = d.getDate();
    // const monthIndex = d.getMonth();
    const monthName = months[d.getMonth()];
    // const dayName = days[d.getDay()]; // Thu

    const formatted = ` ${monthName} ${date}, ${year}`;
    if(type === 'invoice'){
      return formatted.toString() + " " + time;
    }else {
      return formatted.toString();
    }
  }