import axios from "axios";
import {getAuth  } from "firebase/auth";
import Loaderax from "../loader/globelLoader"


export  const getPlans = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
    const url =apiUrl + "/stripebilling/getplans";
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
   
    return await axios.post(url,{},{
        headers: headers
      })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export  const getaddons = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
    const url =apiUrl + "/getaddons";
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
   
    return await axios.post(url,{},{
        headers: headers
      })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}

export  const getmyplan = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
    const url =apiUrl + "/stripebilling/getmyplan";
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
   
    return await Loaderax.ax.post(url,{},{
        headers: headers
      })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const handleSubscribe = async (planId, type='paid_regular') => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url =apiUrl + "/stripebilling/handle_subscription_v2";
  const token = await getToken();
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }

  let param = {}
  if (type === 'addons'){

     param = {
      "type": type,  // 'free' 'paid_regular'
      "addonArray": planId,
       "successURL": window.spconfig?.config.siteURL + "/#/plan/" ,
       "cancelURL": window.spconfig?.config.siteURL + "/#/plan/" ,
       "metadata": {
         "growdata": {}
       }
    }
    param['metadata']['growdata'] = {
      'CREATED': new Date().getTime()
    }
  }else {

     param = {
      "type": type,  // 'free' 'paid_regular'
      "sp_code": planId,
      "successURL": window.spconfig?.config.siteURL + "/#/plan/" + planId,
      "cancelURL": window.spconfig?.config.siteURL + "/#/plan",
    }
  }
    

    return await axios.post(url,param,{
      headers: headers
    })
      .then((response) => {
          return response;
      },
          (error) => {
              return error;
          })
}

export const handleSubscribeStore = async (planId, pushkey, type) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/handle_subscription_v2";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  let
    param = {
      "type": 'addons', 
      "addonArray": planId,
      "successURL": window.spconfig?.config.siteURL + "/#/plan/",
      "cancelURL": window.spconfig?.config.siteURL + "/#/plan/",
      "metadata" : {
        "growdata" : {}
      }
    }

  if (type === 'New'){
    param['metadata']['growdata'] = {
      'PUSH_KEY': pushkey
    }
  }else {
    param['metadata']['growdata'] = {
      'STORE_KEY': pushkey
    }
  }

  return await axios.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}

export const handleSubscribeTerminal= async (planId, pushkey) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/handle_subscription_v2";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  let
    param = {
      "type": 'addons',
      "addonArray": planId,
      "successURL": window.spconfig?.config.siteURL + "/#/plan/",
      "cancelURL": window.spconfig?.config.siteURL + "/#/plan/",
      "metadata": {
        "growdata": {
          'STORE_KEY': pushkey
        }
      }
    }


  return await axios.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}


export const handleSubscribeUpdate = async (spAddonID, qty) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/handle_subscription_v2";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  const param = {
    "type": "update_addon",
    "rec_addon_sub_id": spAddonID,
    "recurring_addons": [{ "sp_code": "sp_user30", "new_quantity": qty, "deleted": false }],
  }


  return await axios.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}




 const getToken = () => {
    return getAuth()
      .currentUser?.getIdToken(true)
      .then((idToken) => {
        return idToken;
      });
  }

  export const getCustomClaim =async () => {
    return await getAuth()
      .currentUser?.getIdTokenResult(true)
      .then((idToken) => {
        return idToken;
      });
  }


export const getmyPaymentMethods = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getmycard";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}

export const changePaymentMethods = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/savecard";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }
  const param = {
    "successURL": window.spconfig?.config.siteURL + "/#/plan/",
    "cancelURL": window.spconfig?.config.siteURL + "/#/plan/",
  }

  return await Loaderax.ax.post(url, param, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}


export const removePaymentMethods = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/detachcard";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }
  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}



export const cancelPlanApi = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/cancel_subscription";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }
  
  return await Loaderax.ax.post(url,{}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}



export const getCurrentAdons = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getmyaddons";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}




export const cancelAddons = async (spAddonID) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/cancel_addon";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  const data = {
    rec_addon_sub_id: spAddonID,
  }

  return await Loaderax.ax.post(url, data, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}


export const taxAddressCheck = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/tax_address_check";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }


  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}

export const getAddonsList = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getaddons";
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }


  return await Loaderax.ax.post(url, {}, {
    headers: headers
  })
    .then((response) => {
      return response.data;
    },
      (error) => {
        return error;
      })
}

export const getPaymentSessionDet = async (id) => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/get_session";

  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  const data = {
    session_id: id
  }

  return axios
    .post(url, data, {
      headers: headers
    })
    .then(async (response) => {
      if (response.status === 200) {

        return response.data;
      }

    });
};



export const getPaymentProviders = async () => {
  const apiUrl = window.spconfig?.config.billingUrl;
  const url = apiUrl + "/stripebilling/getpaymentproviderfeecards";

  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  }

  return axios
    .post(url, {}, {
      headers: headers
    })
    .then(async (response) => {
      if (response.status === 200) {

        return response.data;
      }

    });
};
