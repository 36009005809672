const axios = require('axios');


const passwordTokenCheck = async (mtoken, emref, tokenref) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/checkpassretoken";
    const data = {
        mtoken: mtoken,
        emref: emref,
        tokenref: tokenref
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}
export default passwordTokenCheck;
