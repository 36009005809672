import { Container, Grid } from "@mui/material";
import Header from "./ChangePlan/header";
import MKTypography from "../../components/MKTypography";
import React ,{useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import footerroutes from "../../footerroutes";
import StickyFooter from "../../DefaultFooter/index";
import arrowdownload from "../../assets/images/arrowdownload.svg"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getYearByInvoice, getProviderInvoice, downloadInvoice, getOneTimeAddOns, getRecurringTimeAddOns, getInvoiceData } from '../../providers/invoiceService'
import { Link } from 'react-router-dom';
import {formatDate} from '../../helpers/dateFormat';
import MKBox from "../../components/MKBox";
// import { useParams } from 'react-router-dom';
import { getCustomClaim } from "../../providers/planService"

const Invoice = () => {
    const currentYear = new Date().getFullYear();

    const [year, setYear] = React.useState(currentYear);
    const [yearList, setYearList] = React.useState([]);

    const handleChange = (event) => {
        setYear(event.target.value);
    };

    

    

    const [invoiceList, setInvoiceList] = React.useState([]);
    const [merchantName, setMerchantName] = React.useState('')

    useEffect(() => {
        async function fetchData() {
            const token = await getCustomClaim()
            setMerchantName(token.claims.merchName);
        }
            
        fetchData();
        
    }, [])

    const downloadInvoiceData =async (invoice) => {
        // console.log('invoice', invoice);
        downloadInvoice(invoice, merchantName)
    }

    useEffect( () => {

        async function fetchData() {
           const yearListResult = await generateArrayOfYears();
           setYearList(yearListResult)
            getYearStartToEnd(year);
        };
        fetchData();
    },[year])

    function generateArrayOfYears() {
        var max = new Date().getFullYear()
        var years = [];
        const limitYear = new Date().getFullYear();
        let i = max;
        while (i >= limitYear) { 
            years.push(i);
            i--;
        }
        return years
      }

    const getYearStartToEnd =async (year) => { 
        const d = new Date(String(year), 0);
        const theFirst = d.getTime();
        const theLast = new Date(d.getFullYear(), 11, 31);
        const param = {
            startDate: theFirst / 1000,
            endDate: theLast /1000
        }
       const invoiceResult = await getYearByInvoice(param);
        const providerInvoiceList = await getProviderInvoice();
        // console.log('providerInvoiceList', providerInvoiceList);
        const result = await getOneTimeAddOns()
        const recurring = await getRecurringTimeAddOns();

        const list = await formatInvoiceList(invoiceResult, providerInvoiceList, result, recurring);
        list.sort((a, b) => b.created_date - a.created_date);
        setInvoiceList(list)
    
    }

    const formatInvoiceList = async (subscription, provider, oneTime, recurring) =>{
        const list = [];
        if (subscription.data){
            subscription.data.forEach((invoice) => {
                invoice['type'] = 'Plan';
                invoice['subType'] = '';
                invoice['created_date'] = Math.trunc((Number(invoice.created_at) / 1000));
                list.push(invoice)
            })
        }
        if (provider.data) {
            provider.data.forEach((invoice) => {
                invoice['type'] = 'Provider';
                invoice['subType'] = '';
                invoice['created_date'] = Math.trunc((Number(invoice.created_at) / 1000));
                list.push(invoice)
            })
        }
        if (oneTime.data) {
            oneTime.data.forEach((invoice) => {
                invoice['type'] = 'Addon';
                invoice['subType'] = 'onetime';
                invoice['number'] = invoice.number;
                invoice['created_date'] = Math.trunc((Number(invoice.data.created)));;
                list.push(invoice)
            })
        }
        if (recurring.data) {
            recurring.data.forEach((invoice) => {
                invoice['type'] = 'Addon';
                invoice['subType'] = 'recurring';
                invoice['number'] = invoice.number;
                invoice['created_date'] = Math.trunc((Number(invoice.created_at / 1000)));
                list.push(invoice)
            })
        }
        return list;
    }


    return(
        <>
            <Container maxWidth="xl"> < Header /> </Container>
            <Container style={{
                minHeight:"70vh"
            }}>
                <Grid container justifyContent="start" pt={4} 
                    sx={{
                        p: 0,
                        mb:"100px"
                    }}>
                    <Grid lg={12} md={12} xl={12} container item justifyContent="start">
                        <Grid mb={2} xs={12}>
                        <Link to="/plan" style={{ textDecoration: "none" }}>
                        <MKTypography fontSize="14px" style={{ color: "#50B748", letterSpacing: "0", textAlign: "left", opacity: 1 }}>&lt; Go back</MKTypography>
                            </Link>
                           
                        </Grid>
                        <Grid justifyContent="space-between" xs={12}
                            sx={{
                                listStyle: "none",
                                display: "flex",
                                flexDirection: { xs: "row" },
                                
                            }}
                            
                        >
                            <div>
                                <MKTypography variant="h2" sx={{
                                    fontSize: {lg:"40px", sm:"28px", xs:"28px"}
                                }} style={{ color: "#2E4455", letterSpacing: "0.4px", textAlign: "left", opacity: 1, fontFamily:"Typografix" , }}>Past Invoices</MKTypography>
                            </div>
                            <div>
                                {
                                    yearList && 
                                    <FormControl variant="standard" sx={{ fontSize:"14px", m: 1, minWidth: 30, border: 0, p:0 }}>
                                    {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={year}
                                        displayEmpty
                                        onChange={handleChange}  
                                        disableUnderline
                                        color="#2E4455"
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily:"OpenSansRegular",
                                            fontWeight:"600",
                                            color:"#2E4455",
                                            padding:0
                                        }}                                  
                                    >
                                       
                                        {yearList ? yearList.map((yr, index) => ( 
                                            <MenuItem key={index} value={yr}>{yr}</MenuItem>
                                        )) : null}
                                        
                                    </Select>
                                </FormControl>
                                }
                                

                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{mt:{lg:"56px", xs:"32px"}}}>
                            <Grid>
                                {invoiceList.map((invoice) =>
                                    <Grid key={invoice} justifyContent="space-between" xs={12}
                                        sx={{
                                            listStyle: "none",
                                            display: "flex",
                                            flexDirection: { xs: "row" },
                                            borderBottom: " 1px solid #BABFC5",
                                            pb: "21px",
                                            mt: "26px",
                                        }}
                                    >
                                        <div style={{ margin: "0px", height: "24px" }}>
                                            <MKTypography fontSize="18px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1 }}>{invoice.number} - {invoice.type} - {formatDate(invoice.created_date, 'invoice')}</MKTypography>
                                        </div>
                                        <div style={{margin:"0px", height:"24px"}}>
                                            <MKTypography variant="button" fontWeight="bold" mr="25px" style={{ cursor: "pointer"}}>
                                                {/* {brand} */}
                                                <img src={arrowdownload} alt="" sx={{ width: "20px", height:"22px", }} 
                                                onClick={() => downloadInvoiceData(invoice)} />
                                            </MKTypography>
                                        </div>
                                    </Grid>
                                )
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>

            <MKBox className="footerXL" pt={2} mt={0} mb={0} sx={{ backgroundColor: "#EFF2EF"}} 
            >
                <Container maxWidth="lg">
                    <StickyFooter content={footerroutes} />
                </Container>
                <MKBox className="navybg" sx={{ backgroundColor: "#2E4455", }} />
            </MKBox>
                
        </>
    )
}

export default Invoice;