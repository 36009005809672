import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import SpGrow from './MainLayout'
import reportWebVitals from './reportWebVitals';
import "../src/fonts/OpenSans-Medium.ttf";
import "../src/fonts/OpenSans-Regular.ttf";
import "../src/fonts/OpenSans-SemiBold.ttf";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from 'react-router-dom';
import Lottie from "lottie-react";
import growLoader from "./assets/animation/growLoader.json";
import { Grid, } from '@mui/material';
import MKBox from "./components/MKBox";

import firebase from "firebase/compat/app";


export default function Welcome() {

  const [jsonData, setJasonData] = useState({})
  

  

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    var json_obj, status = false;
    xhr.open('GET', `assets/app-config.json`, true);
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var json_obj = JSON.parse(xhr.responseText);
          const firebaseConf = json_obj.config.firebaseConfig;
          firebase.initializeApp(firebaseConf);
          status = true;
          window.spconfig = json_obj
          setJasonData({ json_obj });
        } else {
          console.error(xhr.statusText);
        }
      }
    }.bind(this);
    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };
    xhr.send(null);
  }, [])


  return (
    <>
      {
        jsonData.json_obj ? <SpGrow /> :
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
            <MKBox
              sx={{
                mt: {
                  lg: "235px",
                  xs: "235px",
                },
                width: "150px",
                height: "150px",
              }}>
              <Lottie animationData={growLoader} loop={true} />
            </MKBox>
          </Grid>
      }
      
    </>

  );
}

ReactDOM.render(

  <React.StrictMode>
    <HashRouter>
      <Welcome />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
