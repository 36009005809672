import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKButton from "../../../components/MKButton";
import "../../../assets/theme/base/globals";
import parse from "html-react-parser";

import "./style.css";
import { useState, useEffect } from "react";

var CurrencyFormat = require('react-currency-format');


function NewSimplePricingCard({ variant, color, title, description, price, planDetail, specifications, actionplanDetail, currentPlan, handleAction, alignment, action }) {


    useEffect(() => {
        setYearPrice(planDetail ? planDetail.planPrice[0].price : 0)
        setplanType(planDetail ? planDetail.planPrice[0].price_type : ""); 
    }, [planDetail])

    let currentPlanBoolean = false;
    let labelAction = "Upgrade"

    if (planDetail && currentPlan.planname !== '') {
        if (planDetail.planName === currentPlan.planname && planDetail.planPrice[0].price_type === currentPlan.priceType) {
            currentPlanBoolean = true;
            labelAction = "Your Current Plan"
        }

    }
    let priceValue = 0
    if (planDetail) {
        priceValue = planDetail.planPrice[0].price_type === "yearly" ?
            Number(planDetail.planPrice[0].price / 12)
            : Number(planDetail.planPrice[0].price)
    }
    const getDisplayPrice = <CurrencyFormat value={priceValue} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={''} renderText={value => <span style={{marginLeft:"-10px"}}> {value} </span>} />
    

    const [planType, setplanType] = useState('monthly');
    const [yearPrice, setYearPrice] = useState(0);
    // const getDisplayDuration =  () => {
        
    // }

    const renderSpecifications = 
    // specifications.map((specification, index) => (
        // <MKBox key={index}>
        <MKTypography
            variant="p"
            fontWeight="regular"
            sx={{ lineHeight: "1.5", textAlign: "left !important", }}
            className="pricingcard-specification open-sans"
        >
            {specifications}
        </MKTypography>
        {/* </MKBox>
    )); */}

    const colorCode = {
        // color:"#202223",
        fontFamily: "openSans"
    }

    return (
        <Card className="price-cards" sx={{ width: "100%", height: "100%", overflow: "hidden", boxShadow: "none", borderRadius: "8px", justifyContent:'center' }} p={0}>
            <MKBox 
                sx={currentPlanBoolean ? {
                    '&:hover': {
                        backgroundColor: 'white',
                        color: "#ffffff",
                    }

                } :
                    {
                        '&:hover': {
                            backgroundColor: '#50B748',
                            color: "#ffffff",
                        }

                    }
                }
                className={currentPlanBoolean ? "noHover" : "price-card"} variant={variant} bgColor={variant === "gradient" ? color : "white"} height="100%">
                <MKBox p={3} lineHeight={1}>

                    <MKBox textAlign="center">

                        <MKTypography className="title open-sans-medium open-sans" style={{ fontFamily: "openSans" }} color="#7F7F7F" fontSize="26px">
                            {title}
                        </MKTypography>

                        {/* <MKTypography fontSize="40px" lineHeight="1" pt={1} className="open-sans" mb={2}>
                            <MKTypography className="opensans-bold open-sans" fontSize="40px" variant="span" >
                                <MKTypography className="opensans-bold" fontSize="40px" variant="span"><sup className="opensans-bold" style={{ fontSize: "16px" }}>{price.currency}</sup>{getDisplayPrice()}</MKTypography>
                            </MKTypography>
                        </MKTypography> */}

                        <MKTypography fontSize="40px" lineHeight="1" pt={1}>
                            {/* {price.value} */}
                            <MKTypography className="opensans-bold" fontSize="40px" variant="span">
                                <sup className="opensans-bold" style={{ fontSize: "16px" }}>{price.currency}</sup>
                                {/* <CurrencyFormat value={getDisplayPrice()} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={''} renderText={value => <><div><sup className="opensans-bold" style={{ fontSize: "16px" }}>{price.currency}</sup> {value} </div></>} /> */}
                                {getDisplayPrice}
                        </MKTypography>
                        </MKTypography>

                        <MKTypography style={{
                            marginBottom: "24px",
                     }} className="pricingcard-duration open-sans">
                           {/* {} */}
                            {planType === 'monthly' ? <b>per month billed monthly</b>:
                            <>
                            <b> per month billed annually </b>  <br></br>
                                    <b><CurrencyFormat value={yearPrice} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} renderText={value => <span> {value} </span>} /> </b>
                            </>
                            }
                           
                        </MKTypography>

                        <MKTypography sx={{ height: "80px",
                            // '&:hover': {
                            //     color: "#FFFFFF !important",
                            // }
                    
                    }} mb={0} className="pricingcard-description open-sans">
                            {description}
                        </MKTypography>

                        {/* <MKBox mt={5}>
                                <MKBox mt={1}>
                                    {currentPlanBoolean ?
                                        <MKButton
                                            className="upgrade"
                                            disabled={currentPlanBoolean}
                                            component={Link}
                                            to={action.route}
                                            variant="outlined"
                                            size="medium"
                                            color="dark"
                                            style={{ textDecoration: "none"}}
                                            sx={{ p: "0px", borderRadius: "4px", width: "150px", height: "39px", fontSize: { lg: "14px", xl: "14px" }, fontWeight: 600, py: "5px", textTransform: "capitalize" }}
                                        >
                                            {labelAction}
                                        </MKButton> :
                                        <MKButton
                                            className="upgrade"
                                            onClick={() => handleAction(planDetail)}
                                            variant="outlined"
                                            size="medium"
                                            color="primary"
                                            sx={{ borderRadius: "4px", width: "150px", height: "39px", fontSize: { lg: "14px", xl: "14px" }, fontWeight: 600, py: "5px", textTransform: "capitalize" }}
                                            
                                        >
                                            {labelAction}
                                        </MKButton>
                                    }
                                </MKBox>
                        </MKBox> */}

                    </MKBox>


                    <MKBox pt={3} pb={3} >
                        <Divider sx={{ mt: 0, opacity: 1, }} />
                        <MKBox pt={3} pb={3} fontSize="14px" style={colorCode} fontWeight="400" lineHeight="2" px={1} sx={{ height: { xl: "540px", lg: "540px", xs: "auto", sm: "360px", md: "360px", xxl: '360px' },
                            // '&:hover': {
                            //     color: "#FFFFFF !important",
                            // }
                             }} >
                            {renderSpecifications}
                        </MKBox>
                        <Divider sx={{ my: 0, opacity: 1, }} />
                    </MKBox>

                    <MKBox mt={1} sx={{ textAlign: "center !important" }}>
                        
                            <MKBox mt={1}>
                                {currentPlanBoolean ?
                                    <MKButton
                                        className="upgrade"
                                        disabled={currentPlanBoolean}
                                        component={Link}
                                        to={action.route}
                                        variant="outlined"
                                        size="medium"
                                        color="dark"
                                        sx={{ p: "0px", borderRadius: "4px", width: "150px", height: "39px", fontSize: { lg: "14px", xl: "14px" }, fontWeight: 600, py: "5px", textTransform: "capitalize" }}
                                    >
                                        {labelAction}
                                    </MKButton> :
                                    <MKButton
                                        className="upgrade"
                                        onClick={() => handleAction(planDetail)}
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        sx={{ borderRadius: "4px", width: "150px", height: "39px", fontSize: { lg: "14px", xl: "14px" }, fontWeight: 600, py: "5px", textTransform: "capitalize" }}
                                    >
                                        {labelAction}
                                    </MKButton>
                                }
                            </MKBox>
                       
                    </MKBox>
                </MKBox>
            </MKBox>
        </Card>
    );
}

// Setting default props for the SimplePricingCard
NewSimplePricingCard.defaultProps = {
    color: "dark",
    variant: "contained",
};

// Typechecking props for the SimplePricingCard
NewSimplePricingCard.propTypes = {
    variant: PropTypes.oneOf(["contained", "gradient", "outlined"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    price: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        type: PropTypes.string,
        duration: PropTypes.object,
        currency: PropTypes.string
    }).isRequired,
    specifications: PropTypes.arrayOf(PropTypes.string).isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default NewSimplePricingCard;
