import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import { BsCheck2 } from 'react-icons/bs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PasswordValidator from "../../helpers/passwordValidator";
import { useLocation } from 'react-router-dom';
import passwordTokenCheck from "../../providers/passwordTokenCheck"
import forgotPasswordReset from "../../providers/forgotPasswordReset"
import logo from "../../assets/images/logo.svg";


const ColorButton = styled(MKButton)(() => ({
    backgroundColor: "#C2C2C2",
    '&:hover': {
        backgroundColor: "#3CAC33",
    },
}))

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },

        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input": {
            padding: "16.5px 14px"
        },


    }
}))(TextField)


const ForgotPasswordReset = ({ setStepperCount }) => {

    const location = useLocation().search;
    const tokenref = new URLSearchParams(location).get('tokenref');
    const mtoken = new URLSearchParams(location).get('mtoken');
    const emref = new URLSearchParams(location).get('emref');
    const [username, setUsername] = useState("")
    const [tokenError, setTokenError] = useState(false);


    useEffect(async () => {
        setStepperCount(-1);
        const result = await passwordTokenCheck(mtoken, emref, tokenref)
        if (result.data.ResponseStatus === "Success") {
            setUsername(result.data.ResponseContent.data.username);
            setTokenError(false)
        } else {
            setTokenError(true)
            setStep(0);
            if (result.data.ResponseContent?.data !== undefined) {
                setUsername(result.data.ResponseContent.data.username)
            }
        }
    }, [])

    // const [tokenref, settokenref] = useState("");
    // const [mtoken, setmtoken] = useState("");
    // const [emref, setemref] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [formSubmit, setFormSubmit] = useState(false);
    const [newPassError, setNewPassError] = useState("")
    const [confPassError, setConfPassError] = useState(false)
    const [step, setStep] = useState(1);
    const [portalurl, setPortalurl] = useState("")

    const validatePassword = async (e) => {
        const result = await PasswordValidator(e.target.value)
        if (result !== true) {
            setNewPassError(result)
        }
    }

    const confirmPasswordCheck = () => {
        if (newPassword !== confirmPassword) {
            setConfPassError(true);
        }
    }

    const handleStartNowClick = () => {
        window.location.replace(portalurl);
    }

    const onSubmit = async () => {
        setFormSubmit(true);
        if (newPassword === "" || confirmPassword === "" || newPassError !== "" || confPassError || tokenError) {
            return;
        }
        if (newPassword !== confirmPassword) {
            setConfPassError(true);
            return;
        }

        const result = await forgotPasswordReset(newPassword, mtoken, emref, tokenref)
        if (result.data.ResponseStatus === "Success") {
            setStep(2);
            setPortalurl(result.data.ResponseData.data.portalUrl);
        } else {
            alert('ERROR pass');
            setStep(1);
        }
    }

    return (
        <MKBox mb='250px'>

            <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                <MKBox sx={{ justifyContent: "center", textAlign: "center", align: "center" }}>
                    <img src={logo} alt="LOGO"></img>
                </MKBox>
            </Grid>  

            < Container component="main" maxWidth="xs" justify="center" style={{ overflowX: "hidden" }}>
                {step === 1 ?
                    <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="60vh"
                        mt="60px"
                    >
                        {username !== "" ?
                            <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>

                                

                                <Grid item xs={12} lg={12} sx={{ mb: "0px", }}>
                                    <MKTypography sx={{
                                        fontSize: "22px",
                                        textAlign: "left",
                                        fontFamily: "OpenSansRegular",
                                        fontWeight: "600",
                                        color: "#2E4455",
                                        mb:"10px"
                                    }}>
                                        Reset your Password
                                    </MKTypography>
                                    <MKTypography sx={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        fontFamily: "OpenSansRegular",
                                        color: "#2E4455",
                                        mb: "20px"
                                    }}>
                                        Hi {username}, you can set a new password for your account.
                                    </MKTypography>
                                </Grid>


                                <Grid item xs={12} lg={12} mb="20px">
                                    <MyTextField
                                        margin="normal"
                                        fullWidth
                                        value={newPassword}
                                        onChange={(e) => {
                                            setNewPassError("");
                                            setFormSubmit(false)
                                            setNewPassword(e.target.value);
                                        }}
                                        onBlur={validatePassword}
                                        error={(formSubmit && newPassword === "") || newPassError}
                                        helperText={(formSubmit && newPassword === "") ? "Password is required" : newPassError !== "" ? newPassError : ""}
                                        id="password"
                                        label="Create Password"
                                        name="password"
                                        placeholder="Enter your new password"
                                        variant="standard"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular" }
                                        }}
                                        type="password"
                                    />
                                </Grid>


                                <Grid item xs={12} lg={12} mb="30px">
                                    <MyTextField
                                        margin="normal"
                                        fullWidth
                                        id="confirmPwd"
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfPassError(false);
                                            setFormSubmit(false)
                                            setConfirmPassword(e.target.value);
                                        }}
                                        onBlur={confirmPasswordCheck}
                                        error={(formSubmit && confirmPassword === "") || confPassError}
                                        helperText={(formSubmit && confirmPassword === "") ? "Confirm Password is required" : confPassError ? "Password not matching" : ""}
                                        label="Confirm Password"
                                        placeholder="Confirm your password"
                                        name="confirmPwd"
                                        variant="standard"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular" }
                                        }}
                                        type="password"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12} >
                                    <ColorButton
                                        style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                        variant="contained"
                                        onClick={onSubmit}
                                        sx={{
                                            width: "100%",
                                            height: "48px",
                                            backgroundColor: "#50B748",
                                            color: "white",
                                            textTransform: "capitalize",
                                            fontFamily: "OpenSansSemiBold",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Submit
                                    </ColorButton>
                                </Grid>


                                <Grid item xs={12} lg={12} mt={2}>
                                    <MKTypography sx={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                        fontFamily: "OpenSansRegular",
                                        color: "#6D7175",
                                    }}>
                                        New to ShelfPerks?
                                        <a href="/" style={{
                                            color: "#50B748",
                                            fontWeight: "700"
                                        }}> Register Now</a>
                                    </MKTypography>
                                </Grid>

                            </Grid> : ""}
                    </MKBox> : ""}

                {step === 2 ?
                    <Grid sx={{ minHeight: "60vh" }} > 

                        <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                            <MKBox component="main"
                                sx={{
                                    mt: {
                                        lg: "180px",
                                        xs: "180px",
                                    },
                                    width: "86px",
                                    height: "85px",
                                    borderRadius: '43px',
                                    backgroundColor: "#50B748"
                                }}>
                                <div className='check-icon' style={{ align: "center" }}>
                                    <BsCheck2 />
                                </div>
                            </MKBox>
                        </Grid>

                        <Grid item xs={12} lg={12} justifyContent="center">
                            <MKTypography
                                fontSize="24px"
                                sx={{
                                    mt: "82px",
                                    fontFamily: "OpenSansSemiBold",
                                    textAlign: "center",
                                    color: "#2E4455"
                                }}>
                                Done! You have successfully reset your password.
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} lg={12} justifyContent="center" display="flex">
                            <ColorButton
                                style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                variant="contained"
                                onClick={handleStartNowClick}
                                sx={{
                                    backgroundColor: "#50B748",
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "OpenSansSemiBold",
                                    fontSize: "15px",
                                    margin: "40px"
                                }}
                            >
                                Login Now
                            </ColorButton>
                        </Grid>
                    </Grid> : ""}


                {tokenError?
                    <Grid>
                        <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                            <MKBox component="main" 
                                sx={{
                                    mt: {
                                        lg: "215px",
                                        xs: "215px",
                                    },
                                }}
                                style={{ align: "center" }}>
                                    <ErrorOutlineIcon style={{
                                        color: "#FF4040",
                                        fontSize: "60px"
                                        }}/>
                            </MKBox>
                        </Grid>

                        <Grid item xs={12} lg={12} justifyContent="center">
                            <MKTypography
                                fontSize="24px"
                                sx={{
                                    fontFamily: "OpenSansSemiBold",
                                    textAlign: "center",
                                    color:"#FF4040",
                                    mb: "20px",
                                    mt: "40px"
                                }}>
                                Hi {username}! You have Invalid Verification Code.
                            </MKTypography>
                        </Grid>
                        
                    </Grid>:""}

            </Container >
        </MKBox >
    )
}

export default ForgotPasswordReset;