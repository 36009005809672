import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import emailValidation from "../../../helpers/emailValidation"
import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MKBox from "../../../components//MKBox";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { BiInfoCircle } from 'react-icons/bi';
import CustomPhoneNumber from "../../../components/SPProfilePhone/index";
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import Lottie from "lottie-react";
import growLoader from "../../../assets/animation/growLoader.json";
import { sendEmailChangeCode, validateEmailChangeCode, sendPhoneChangeCode, validatePhoneChangeCode } from "../../../providers/profileChagne";
import { getMerchantDetails } from "../../../providers/portalURLValidation";
import { getCustomClaim } from '../../../providers/planService';
import Divider from '@mui/material/Divider';



const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "11px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }
    }
}))(TextField)


export default function Profile({ profileDetails, merchantURL, setProfileDetails }) {

    const [editForm, setEditForm] = useState(1);
    const [emailHashToken, setEmailHashToken] = useState('')
    const [phoneHashToken, setPhoneHashToken] = useState('')
    const [formValues, setFormValues] = useState({
        email: "",
        phone: "",
        emailPasscode : "",
        phonePassCode: ""
    })

    const [newEmail, setNewEmail] = useState("");
    const [newPhone, setNewPhone] = useState("");

    const [formError, setFormError] = useState({});



    const validateInput = (e) => {
        const type = e.target.name
        const value = e.target.value
        delete formError[type]
        setFormError(formError);
        setFormValues({...formValues, [type]: value })
        if(type === 'email'){
            delete formError['emailTaken']
            delete formError['emailCodeExpired']
            setFormError(formError);
            setNewEmail(value);
        }
    }

    const validateEmailonBlur = (email) => {
        const validated = emailValidation(formValues.email);
        if (!validated){
            setFormError({ 'email': true })
        }
    }

    const handlePhoneInputChange = (event) => {
        delete formError["phone"]
        delete formError['phoneTaken']
        delete formError['phoneCodeExpired']
        setFormError(formError)
        if (event !== "" || event !== undefined) {
            setFormValues({ ...formValues, "phone": event });
            setNewPhone(event);
        }
    }

    const formCancel = () => {
        setEditForm(1);
        resetForm();
        setNewEmail('');
        setNewPhone('');
    }

    const [reload, setReload] = useState("")

    useEffect(() => {
        async function fetchData() {
            const details = await getMerchantDetails(merchantURL);
            if (details.data.ResponseStatus === 'Success'){
                const data = details.data.ResponseData.data.data
                const detailObj = {
                    name: data.FIRST_NAME + " " + data.LAST_NAME,
                    email: data.MERCHANT_EMAIL,
                    phone: data.MERCHANT_PHONE
                }
                setProfileDetails(detailObj)
            }
            
        }
        fetchData();
    }, [reload])


    // const setUserDetails = async () => {
    //     const details = await getMerchantDetails(merchantURL);
    //     const data = details.data.ResponseData.data.data
    //     const detailObj = {
    //         name: data.FIRST_NAME + " " + data.LAST_NAME,
    //         email: data.MERCHANT_EMAIL,
    //         phone: data.MERCHANT_PHONE
    //     }
    //     setProfileDetails(detailObj)
    //     }

    const submitForm = async (type) => {
        if (type === 'email') {
                if (formValues.email === '' || formValues.email === null || formValues.email === undefined) {
                    setFormError({ 'email': true })
                } else {
                    const validated = emailValidation(formValues.email);
                    if (validated) {
                        const result = await sendEmailChangeCode(merchantURL, newEmail);
                        if (result.data.ResponseStatus === "Success"){
                            setEmailHashToken(result.data.ResponseData.data.authToken);
                            setEditForm(3)
                            resetForm();
                        }else {
                            if (result.data.ResponseMessage === 'EmailTaken'){
                                setFormError({ 'emailTaken': true })
                            } else if (result.data.ResponseMessage === 'Code Expired'){
                                setFormError({ 'emailCodeExpired': true })
                            }
                            else {
                                setFormError({ 'email': true })
                            } 
                        }
                    } else {
                        setFormError({ 'email': true })
                    }
                }
        } 
        else if (type === "emailPasscode"){
            if (formValues.emailPasscode === '' || formValues.emailPasscode === null || formValues.emailPasscode === undefined) {
                setFormError({ 'emailPasscode': true })
            }else {
                const paramsData = {
                    verifyCode: formValues.emailPasscode,
                    authToken: emailHashToken,
                    email: newEmail
                }
                const result = await validateEmailChangeCode(paramsData);
                if (result.data.ResponseMessage === 'Value Updated'){
                    setEditForm(6)
                    resetForm();
                    setTimeout(async () => {
                        // await setUserDetails();
                        setReload(emailHashToken)
                    }, 2000);
                    setTimeout(async () => {
                        // await setUserDetails();
                        formCancel();
                    }, 3000);

                    
                }else {
                    setFormError({ 'emailPasscode': true })
                }
            }
        }
        else if( type === 'phone'){
            if (formValues.phone === '' || formValues.phone === null || formValues.phone === undefined) {
                setFormError({ 'phone': true })
            } else {
                const result = isValidPhoneNumber(formValues.phone)
                if (!result) {
                    setFormError({ 'phone': true })
                } else {
                    const result = await sendPhoneChangeCode(merchantURL, newPhone);
                    if (result.data.ResponseStatus === "Success") {
                        setPhoneHashToken(result.data.ResponseData.data.authToken);
                        setEditForm(5)
                        resetForm();
                    } else {
                        if (result.data.ResponseMessage === 'PhoneTaken') {
                            setFormError({ 'phoneTaken': true })
                        } else if (result.data.ResponseMessage === 'Code Expired') {
                            setFormError({ 'phoneCodeExpired': true })
                        }
                        else{
                            setFormError({ 'phone': true })
                        }
                    }
                }
            }
        }
        else if (type === "phonePassCode") {
            if (formValues.phonePassCode === '' || formValues.phonePassCode === null || formValues.phonePassCode === undefined) {
                setFormError({ 'phonePassCode': true })
            } else {
                const paramsData = {
                    verifyCode: formValues.phonePassCode,
                    authToken: phoneHashToken,
                    phone: newPhone
                }
                const result = await validatePhoneChangeCode(paramsData);
                if (result.data.ResponseMessage === 'Value Updated') {
                    setEditForm(6)
                    resetForm();
                    setTimeout(async () => {
                        // await setUserDetails();
                        setReload(phoneHashToken)
                    }, 2000);
                    setTimeout(async () => {
                        // await setUserDetails();
                        formCancel();
                    }, 3000);  
                }else {
                    setFormError({ 'phonePassCode': true })
                }
        }
    }
}

    const resetForm = () => {
        setFormValues({
            email: "",
            phone: "",
            emailPasscode: "",
            phonePassCode: ""
        })
    }

    const styleobj = {
        borderBottom: "2px solid #BABFC5",
    }

    return (
        <Grid container>
            <Card sx={{ minWidth: 275, mt: "24px", width: "100%", borderRadius: 1, minHeight: 215, boxShadow: 3 }}
                // style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}
                >

                {editForm === 1 ?
                    <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                           

                            <Grid item container xs={12} >
                                <Grid item container xs={12}>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> Account Owner </span></MKTypography>

                                </Grid>
                                <Grid item xs={2.5} sm={1.5} md={1.5} justifyContent="start">
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }} className="semi-bold"> <span> Name: </span></MKTypography>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }} className="semi-bold"> <span> Email: </span></MKTypography>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }} className="semi-bold"> <span> Phone: </span></MKTypography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} justifyContent="start">
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> {profileDetails.name} </span></MKTypography>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> {profileDetails.email} </span></MKTypography>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> {profileDetails.phone} </span></MKTypography>

                                </Grid>
                                
                            </Grid>
                            {/* <div style={{ marginTop: "30px" }}>
                                <hr sx={
                                    { color: "#DCDCDC" }
                                } />
                            </div> */}
                        </CardContent>
                        <Divider variant="middle" sx={{ mt: '24px', mb: '8px' }} /> 
                        <CardActions>
                            {/* <Link to="/invoice" style={{ textDecoration: "none" }}> */}
                            <MKButton className="btn-txt opensans-medium"
                                onClick={() => {
                                    setEditForm(2)
                                }}
                                sx={
                                    {
                                        fontSize: "14px",
                                        marginRight: {
                                            xl: "90px", lg: "90px", md: "90px", sm: "45px", xs: "0px"
                                        },
                                        pr: { xs: "10px", sm: "20px" },
                                        pl: "8px"
                                    }


                                } size="small" variant="text" color="primary">Change Email Address</MKButton>
                            {/* </Link> */}

                            <MKButton className="btn-txt opensans-medium" 
                                onClick={() => {
                                    setEditForm(4)
                                }}
                            sx={
                                {
                                    fontSize: "14px",
                                    pr: { xs: "10px", sm: "20px" },
                                    pl: { xs: "10px", sm: "20px" }
                                }
                            } size="small" variant="text" color="primary">Change Phone Number</MKButton>
                        </CardActions>
                    </>
                    : ""
                }
                {editForm === 2 ?
                    <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                            <Grid item container xs={12} sx={{ justifyContent: "start" }} >
                                <Grid item xs={8} sm={5} md={3} lg={3}  justifyContent="center">

                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }}>Enter new Email
                                    </MKTypography>
                                    <MyTextField
                                        // margin="normal"
                                        fullWidth
                                        id="email"
                                        value={formValues.email}
                                        error={formError.email || formError.emailTaken || formError.emailCodeExpired}
                                        helperText={formError.email ? formValues.email === "" ? "Email is required" : "Email is invalid" : formError.emailTaken ? "Email already present" : formError.emailCodeExpired?"Code Expired":""}
                                        onChange={(e) => validateInput(e)}
                                        onBlur={(e) => validateEmailonBlur(e)}
                                        placeholder='Please enter new email'
                                        // label="Enter new email"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        variant="standard"
                                        key="email"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                {/* <Link to="/invoice" style={{ textDecoration: "none" }}> */}
                                <MKBox xs={4}>
                                    <MKButton
                                        className="btn-txt opensans-medium editCancel"
                                        onClick={formCancel}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                pl: "8px",
                                                mr: "30px"
                                            }


                                        } size="small" variant="text" color="primary">Cancel</MKButton>
                                    {/* </Link> */}

                                    <MKButton
                                        className="btn-txt opensans-medium"
                                        onClick={() => {
                                            submitForm('email');
                                        }}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                ml: "30px"
                                            }
                                        } size="small" variant="text" color="primary">Submit</MKButton>
                                </MKBox>
                            </Grid>
                        </CardActions>
                    </>

                    :
                    ""}

                {editForm === 3 ?
                <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                            <Grid item container xs={12} sx={{ justifyContent: "start" }} >
                                <Grid item xs={8} sm={5} md={3} lg={3} justifyContent="center">
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }}>One Time Passcode
                                        <Tooltip title="Just now we've sent a passcode to your registered email." arrow>
                                            <IconButton>
                                                <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                                            </IconButton>
                                        </Tooltip>
                                    </MKTypography>
                                    <MyTextField
                                        // margin="normal"
                                        fullWidth
                                        id="emailPasscode"
                                        value={formValues.emailPasscode}
                                        error={formError.emailPasscode}
                                        helperText={formError.emailPasscode ? formValues.emailPasscode === "" ? "Passcode is required" : "Passcode is invalid" : ""}
                                        onChange={(e) => validateInput(e)}
                                        placeholder='Please enter passcode'
                                        // label="Enter one time passcode"
                                        name="emailPasscode"
                                        // autoComplete="emailPasscode"
                                        autoFocus
                                        variant="standard"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12}>
                                {/* <Link to="/invoice" style={{ textDecoration: "none" }}> */}
                                <MKBox xs={4}>
                                    <MKButton
                                        className="btn-txt opensans-medium editCancel"
                                        onClick={formCancel}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                pl: "8px",
                                                mr: "30px"
                                            }


                                        } size="small" variant="text" color="primary">Cancel</MKButton>
                                    {/* </Link> */}

                                    <MKButton
                                        className="btn-txt opensans-medium"
                                        onClick={() => {
                                            submitForm('emailPasscode');
                                        }}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                ml: "30px"
                                            }
                                        } size="small" variant="text" color="primary">Submit</MKButton>
                                </MKBox>
                            </Grid>
                        </CardActions>
                
                
                </>
                :""}
                
                {editForm === 4 ?
                    <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                            <Grid item container xs={12} sx={{ justifyContent: "start" }} >
                                <Grid item xs={3} justifyContent="center">

                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }}>Enter new Phone Number
                                    </MKTypography>
                                    <PhoneInput
                                        defaultCountry="US"
                                        style={styleobj}
                                        inputComponent={CustomPhoneNumber}
                                        onChange={handlePhoneInputChange}
                                        value={formValues.phone}
                                        error={formError.phone || formError.phoneTaken || formError.phoneCodeExpired}
                                        helperText={formError.phone ? (formValues.phone === "" || formValues.phone === null || formValues.phone === undefined) ? "Phone number is required" : "Phone number is invalid" : formError.phoneTaken ? "Phone Number already present" : formError.phoneCodeExpired?"Code Expired":""}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid item xs={12} mt={2}>
                                {/* <Link to="/invoice" style={{ textDecoration: "none" }}> */}
                                <MKBox xs={4}>
                                    <MKButton
                                        className="btn-txt opensans-medium editCancel"
                                        onClick={formCancel}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                pl: "8px",
                                                mr: "30px"
                                            }


                                        } size="small" variant="text" color="primary">Cancel</MKButton>
                                    {/* </Link> */}

                                    <MKButton
                                        className="btn-txt opensans-medium"
                                        onClick={() => {
                                            submitForm('phone');
                                        }}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                ml: "30px"
                                            }
                                        } size="small" variant="text" color="primary">Submit</MKButton>
                                </MKBox>
                            </Grid>
                        </CardActions>
                    </>

                    :
                    ""}

                {editForm === 5 ?
                    <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                            <Grid item container xs={12} sx={{ justifyContent: "start" }} >
                                <Grid item xs={3} justifyContent="center">
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }}>One Time Passcode
                                        <Tooltip title="Just now we've sent a passcode to your registered phone number." arrow>
                                            <IconButton>
                                                <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                                            </IconButton>
                                        </Tooltip>
                                    </MKTypography>
                                    <MyTextField
                                        // margin="normal"
                                        fullWidth
                                        id="phonePassCode"
                                        value={formValues.phonePassCode}
                                        error={formError.phonePassCode}
                                        helperText={formError.phonePassCode ? formValues.phonePassCode === "" ? "Passcode is required" : "Passcode is invalid" : ""}
                                        onChange={(e) => validateInput(e)}
                                        placeholder='Please enter passcode'
                                        // label="Enter one time passcode"
                                        name="phonePassCode"
                                        // autoComplete="emailPasscode"
                                        autoFocus
                                        variant="standard"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid  item xs={12}>
                                {/* <Link to="/invoice" style={{ textDecoration: "none" }}> */}
                                <MKBox xs={4}>
                                    <MKButton
                                        className="btn-txt opensans-medium editCancel"
                                        onClick={formCancel}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                pl: "8px",
                                                mr: "30px"
                                            }


                                        } size="small" variant="text" color="primary">Cancel</MKButton>
                                    {/* </Link> */}

                                    <MKButton
                                        className="btn-txt opensans-medium"
                                        onClick={() => {
                                            submitForm('phonePassCode');
                                        }}
                                        sx={
                                            {
                                                fontSize: "14px",
                                                ml: "30px"
                                            }
                                        } size="small" variant="text" color="primary">Submit</MKButton>
                                </MKBox>
                            </Grid>
                        </CardActions>


                    </>
                    : ""}

                {editForm === 6 ?
                    <>
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                                <MKBox
                                    sx={{

                                        width: "150px",
                                        height: "150px",
                                    }}>
                                    <Lottie animationData={growLoader} loop={true} />
                                </MKBox>
                            </Grid>
                        </CardContent>

                    </>
                    : ""}

            </Card>

            


        </Grid>

    )
    
}