import logo from "../../../assets/images/logo.svg";
import MKTypography from "../../../components/MKTypography";
import { Grid } from "@mui/material";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import { signOut, getAuth } from 'firebase/auth';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {

    const [count, setCount] = useState(5)
    const logout = async () => {
        const auth = getAuth()
        signOut(auth);
        var customWindow = window.open('', '_blank', '');
        customWindow.close();
    }

    // const [userName, setUserName] = useState("AB")
    return (
        <Grid container py="21px" px={0} sx={{
            listStyle: "none",
            display: "flex",
            flexDirection: { xs: "row" },
        }} justifyContent="space-between">
            <MKTypography variant="button" fontWeight="bold">
                <img src={logo} alt="" width="203px" height="36px" />
            </MKTypography>
            <MKTypography fontSize="12px" fontWeight="bold" style={{ color: "#202223", letterSpacing: "0.29px", textAlign: "center", opacity: 1 }}>

                {/* <Link to={"/cart"} style={{ textDecoration: "none" }}>
                    <Button variant="text" sx={{
                        color: "#50B748",
                        fontFamily: "openSansSemiBold"
                    }} onClick={openCart}>
                        <ShoppingCartIcon style={{ fontSize: '27px' }} />
                        {count > 0 ? <span className="cartItemCount">{count}</span> : ""}
                    </Button>
                </Link> */}

                <Button variant="text" sx={{
                    color: "#50B748",
                    fontFamily: "openSansSemiBold"
                }} onClick={logout}>Logout</Button>
                {/* <MKBox sx={{
                height:"36px",
                width:"36px",
                borderRadius: "50%",
                backgroundColor:"#ACEAFF",
                background: "#ACEAFF",
                border: "1px solid #ACEAFF",
                lineHeight: 2.6

            }}>
                AB
            </MKBox> */}
            </MKTypography>
        </Grid>

    )
}


export default Header;