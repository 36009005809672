import {Grid } from "@mui/material";
import MKButton from "../../../components//MKButton";
import MKTypography from "../../../components//MKTypography";
import MKBox from "../../../components//MKBox";
import { useState } from "react";
import parse from "html-react-parser";
let CurrencyFormat = require('react-currency-format');

const Modal = ({ planData, merchName, handleCancel, handleSubmit}) => {

    const capitalizeFirstLetter = (planName) => {
        return planName.charAt(0).toUpperCase() + planName.slice(1);
    }

    const standardPlan = `
    <div style="marginTop:10px;lineHeight:2">Enjoy the advanced features to grow your business with Standard Plan.</div>
    <div style="marginTop:10px;lineHeight:2">It comes with all the benefits of Free Plan and: </div>
    <div style="lineHeight:2;"> 
        <ul> 
            <li>No revenue limit</li>
            <li>Advanced dashboard</li>
            <li>Realtime notifications and reports</li>
            <li>1 user</li>
            <li>1 location</li>
            <li>1 terminal per location</li>
            and more...
        </ul>
     </div>
    `

    const plusPlan = `
    <div style="marginTop:10px;lineHeight:2">Delight your customers with your own discount and gift card programs while enjoying advanced insights with Plus Plan. </div>
    <div style="marginTop:10px;lineHeight:2">It comes with all the benefits of Standard Plan and: </div>
    <div style="lineHeight:2;"> 
        <ul> 
            <li>Run your own discount programs</li>
            <li>Run your own gift card programs</li>
            <li>Point of sale in <br class="tool"> offline mode <div class="tooltip">&#9432;<span class="tooltiptext">Use Point of Sale feature in ShelfPerks App even without internet for 24 hours</span></div></br></li>
            <li>Your own website</li>
            <li>Marketplace</li>
            <li>Expiration reports and alerts</li>
            <li>Smart AI based Purchase Orders</li>
            <li>5 users</li>
            <li>2 locations</li>
            <li>2 terminals per location</li>
            and more...
        </ul>
     </div>
    `

    const premiumPlan = `
    <div style="marginTop:10px;lineHeight:2">Supercharge your business with self checkout, have your own website, and sell more in our marketplace with Premium Plan.</div>
    <div style="marginTop:10px;lineHeight:2">It comes with all the benefits of Plus Plan and: </div>
    <div style="lineHeight:2;"> 
        <ul> 
            <li>Customer loyalty program</li>
            <li>Self checkout</li>
            <li>Your own website</li>
            <li>Marketplace</li>
            <li>Up to 500 marketing emails</li>
            <li>Delivery app integration</li>
            <li>8 users</li>
            <li>2 locations</li>
            <li>3 terminals per location</li>
            and much more…
        </ul>
     </div>
    `

    let subText = "";
    let confirmTextPlan = "";
    let billPeriod = '';
    let price = "";
    if (planData.planPrice[0].price_type === 'monthly'){
        subText = " (Monthly Plan)"
        confirmTextPlan = " billed monthly"
        price = planData.planPrice[0].price;
        billPeriod = 'monthly';
    }else {
        subText = " (Yearly Plan)"
        confirmTextPlan = " billed yearly"
        price = (Number(planData.planPrice[0].price));
        billPeriod = 'yearly';

    }
    const planName = capitalizeFirstLetter(String(planData.planName));
    const heading = planName + subText

    

    let planContentIs = ""
    
    if (planData.planName === "standard"){
        planContentIs = standardPlan;
    } 
    else if (planData.planName === "premium"){
        planContentIs = premiumPlan;
    } 
    else if (planData.planName === "plus"){
        planContentIs = plusPlan;
    }



    const renderSpecifications = 
            <MKTypography
                variant="p"
                fontWeight="400"
            sx={{ lineHeight: "2", textAlign: "left !important", fontFamily: 'OpenSansRegular' }}
            >
            {parse(planContentIs)}
            </MKTypography>
    
    

    return (
        <div className="modal">
            <div className="overlay"><></></div>
            <div className="modal-content">
                <Grid className="modal-height-set" sx={{ p: {lg:"60px", md:"40px", sm:"30px", xs:"20px"},
                    height: { xl: "auto", lg: "auto", md: "auto", sm: "597px", xs: "597px"},
                    mt:{xs:"60px", sm:"60px", md:"0px"}
                    }}>
                    <MKTypography fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, }}> <span className="semi-bold">{heading}</span></MKTypography>
                    <MKBox>
                        <MKTypography className="modal-content-height-set" sx={{
                            height: {xs:"400px", sm:"450px", md:"auto", lg:"auto",},
                            overflow: "auto"
                        }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                            <div className="cancel-content">
                                <div>
                                    Dear {merchName},
                                </div>

                                <div>
                                    {renderSpecifications}
                                </div>

                                <div>
                                    You will be billed USD 
                                    <CurrencyFormat value={price} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} renderText={value => <span> {value} </span>} /> 
                                    {billPeriod}  
                                </div>

                                <div>
                                    If applicable, we may refund or prorate you as per your plan and our 
                                    <a class="link-button" href="https://shelfperks.com/terms-of-service" target="_blank"> refund policy</a>. 
                                </div>

                                <div>
                                    To contact us for questions, 
                                    <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                </div> <br />
                                <div>
                                    Would you like to change your plan to <span className="semi-bold">{planName}</span> {confirmTextPlan}? 
                                </div>
                            </div>
                        </MKTypography>

                        <Grid lg={12} sx={{ mt:"30px" }}container item>
                                <MKBox sx={{ display: "inline-block" }}>
                                    <MKButton
                                        variant="outlined"
                                        color="primary"
                                        className="upgrade"
                                        size="medium"
                                        sx={{
                                            
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            color:"#50B748",
                                            borderColor:"#50B748",
                                            mr: { xs: "50px", sm: "50px", md: "50px" },
                                            textTransform: "capitalize",
                                        }}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </MKButton>
                                    <MKButton
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        className="upgrade"
                                        sx={{
                                            ':hover': {
                                                bgcolor: '#3CAC33',
                                                color: '#FFFFFF',

                                            },
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            textTransform: "capitalize",
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Yes
                                    </MKButton>
                                </MKBox>
                            </Grid> 
                    </MKBox>
                </Grid>
            </div>
        </div>
    )
}

export default Modal;