import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import { Typography } from '@mui/material';
// import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import { styled } from '@mui/material/styles';

import { useParams } from "react-router-dom";

import "../../App.css"
import stepValidationService from "../../providers/stepValidationService"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import validateInviteCodeService from "../../providers/validateInviteCodeService";
import sendInviteCode from "../../providers/sendInviteCode";

const ColorButton = styled(MKButton)(() => ({
    backgroundColor: "#C2C2C2",
    '&:hover': {
        backgroundColor: "#3CAC33",
    },
}))

export function Verification({ setInviteCodeValidated, setStepperCount }) {
    useEffect(() => {
        setStepperCount(1)
    }, [])
    let params = useParams();
    const navigate = useNavigate();
    const verifyCode = params.verifycode;
    // Need to remove below line
    // const verifyCode = "XrdFElOTqL4ouOGrXUmHYR9oL6P69z"

    const [pageDisplay, setPageDisplay] = useState(false);
    const [registeredUserDetails, setRegisteredUserDetails] = useState({});
    const [inviteInputCode, setInviteInputCode] = useState("");
    const [inviteCodeStatus, setInviteCodeStatus] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [resendInviteCodeClicked, setResendInviteCodeClicked] = useState(false)



    const stepValidationFunction = async () => {

        const result = await stepValidationService(verifyCode);
        if (result.data.ResponseStatus === "Success") {
            const data = result.data.ResponseContent.data;
            const resDetails = {
                merchantName: data.merchName,
                merchantEmail: data.email,
                merchantURL: data.portalurl,
                portalDomain: data.portaldomain
            }
            if (data.merchStatus !== 1) {
                // window.location.replace(data.portalurl);
                navigate("/register/account/" + verifyCode, {
                    state: {
                        verifycode: verifyCode,
                        portalDomain: registeredUserDetails.portalDomain,
                        merchantURL: resDetails.merchantURL,
                        pageNumberToDisplay: 3,
                        merchantName: "",
                    }
                });
            } else {
                setPageDisplay(true)
            }
            setRegisteredUserDetails(resDetails);
        } else {
            navigate("/register");
        }
    }

    useEffect(() => {
        if (verifyCode === undefined) {
            navigate("/register");
            return;
        }
        stepValidationFunction(verifyCode);
    }, [verifyCode]);


    const handleInviteCodeInput = (e) => {
        setInviteInputCode(e.target.value)
        setInviteCodeStatus(false);
        setResponseMessage("")
    }

    const handleVerification = async (event) => {
        const result = await stepValidationService(verifyCode);
        if (result.data.ResponseStatus === "Success") {
            const data = result.data.ResponseContent.data;
            const resDetails = {
                merchantName: data.merchName,
                merchantEmail: data.email,
                merchantURL: data.portalurl,
                portalDomain: data.portaldomain
            }
            if (data.merchStatus !== 1) {
                // window.location.replace(data.portalurl);
                navigate("/register/account/" + verifyCode, {
                    state: {
                        verifycode: verifyCode,
                        portalDomain: registeredUserDetails.portalDomain,
                        merchantURL: resDetails.merchantURL,
                        pageNumberToDisplay: 3,
                        merchantName: data.merchName,
                    }
                });
                return;
            }
        }
        if (inviteInputCode !== "") {
            const regMer = {
                invite_code: inviteInputCode,
                url_token: verifyCode,
            };
            const result = await validateInviteCodeService(regMer)
            if (result.data.ResponseStatus === "Success") {
                setInviteCodeStatus(false);
                setResponseMessage(result.data.ResponseMessage);
                setInviteCodeValidated(true)
                navigate("/register/account/" + verifyCode, {
                    state: {
                        verifycode: verifyCode,
                        portalDomain: registeredUserDetails.portalDomain,
                        pageNumberToDisplay: 1,
                        merchantName: "",
                    }
                });

            } else {
                setInviteCodeValidated(false)
                setInviteCodeStatus(true);
                setResponseMessage(result.data.ResponseMessage);
            }
        } else {
            setInviteCodeStatus(true);
            setResponseMessage("")
        }
    }

    const changeEmail = () => {
        navigate("/register");
    }

    const verifyStepTwo = () => {
        // if(inviteCodeStatus){
        //     navigate("/register/account/"+verifyCode, {state:{verifycode:verifyCode}});
        // }
        
        handleVerification()
    }

    const resendInviteCode = async () => {
        sendInviteCode(verifyCode);
        setResendInviteCodeClicked(true);
    }

    const stylesObj = {
        color: "#FF4040",
        fontSize: '10px',
        fontFamily: "OpenSansRegular",
    }
    const resendcodeStyle = {
        // marginBottom: "101px",
        fontFamily: "OpenSansRegular",
        color: "#202223",
        fontSize: '14px',
    }

    return (
        <>
            <MKBox mb='250px'>
                < Container component="main" maxWidth="xs" style={{ overflowX: "hidden" }
                }>

                    <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>

                        {pageDisplay ?
                            <>
                                <Grid item xs={12} lg={12}>
                                    <MKTypography sx={{
                                        fontSize: "14px",
                                        align: "left",
                                        fontFamily: "OpenSansRegular",
                                        mb: "20px",
                                    }}>
                                        Thank you for registering with Shelfperks <span className='super'>TM</span>! 
                                        <div style={{marginTop: '4px'}}>
                                        Please enter your special invite code sent to 
                                        </div>
                                        <strong>
                                            {registeredUserDetails.merchantEmail}</strong>
                                    </MKTypography>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="inviteCode"
                                        label="Invite Code"
                                        name="inviteCode"
                                        value={inviteInputCode}
                                        onChange={handleInviteCodeInput}
                                        // onBlur={handleVerification}
                                        error={inviteCodeStatus}
                                        helperText={inviteCodeStatus ?
                                            responseMessage === "Invalid Invite Code" ?
                                                "Invalid Invite Code" : "Please Enter the Invite Code" : ""}
                                        FormHelperTextProps={{ style: stylesObj }}
                                        autoFocus
                                        variant="standard"
                                        InputLabelProps={{
                                            style: { fontFamily: "OpenSansRegular", fontSize: "14px" }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} justifyContent="end">
                                    <Stack spacing={3} direction="row"
                                        sx={{
                                            mb: {
                                                lg: resendInviteCodeClicked ? "16px" : "30px",
                                                xs: resendInviteCodeClicked ? "16px" : "30px",
                                            },
                                            justifyContent:"end",
                                            fontSize: "15px",
                                        }}>
                                        <Button
                                            variant="text"
                                            onClick={resendInviteCode}
                                            sx={{
                                                textTransform: "capitalize",
                                                fontFamily: "OpenSansSemiBold",
                                                color: '#50B748',
                                            }} >
                                            Resend Code
                                        </Button>
                                        <Button onClick={changeEmail}
                                            variant="text"
                                            sx={{
                                                textTransform: "capitalize",
                                                fontFamily: "OpenSansSemiBold",
                                                color: '#50B748',
                                                mb: "150px",
                                                pr: 0
                                            }} >
                                            Change Email ID
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}
                                    sx={{
                                        mb: {
                                            lg: resendInviteCodeClicked ? "20px" : "",
                                            xs: resendInviteCodeClicked ? "10px" : "",
                                        },
                                    }}>

                                    {resendInviteCodeClicked ?
                                        <div style={resendcodeStyle}>
                                            <p>We have resend the code on your email.</p>
                                        </div> : ""
                                    }
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <ColorButton onClick={verifyStepTwo}
                                        variant="contained"
                                        sx={{
                                            width: "100%",
                                            height: "48px",
                                            backgroundColor: "#50B748",
                                            color: "white",
                                            textTransform: "capitalize",
                                            fontFamily: "OpenSansSemiBold",
                                            fontSize: "15px"
                                        }}
                                    >
                                        Verify
                                    </ColorButton>
                                </Grid>
                            </> : <MKBox mb='1000px'><div style={{ height: "100%", width: "100px" }}>
                            </div></MKBox>}

                    </Grid>

                </Container >
            </MKBox >
        </>
    )
}