const axios = require('axios');

const forgotPasswordreq = async (data, merchname) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/requestresetpwd";
    const reqData = {
        email: data.email,
        username: data.username,
        site_url: window.spconfig?.config.siteURL,
        authmtoken: merchname
    };
    return await axios.post(urlLink, JSON.stringify(reqData), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}
export default forgotPasswordreq;
