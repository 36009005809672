import React, { useState, useEffect } from 'react';
import { Container, Grid } from "@mui/material";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import MKButton from "../../../components/MKButton";
import Header from "./header";
import { getCurrentAdons, getmyplan } from '../../../providers/planService';
import Loader from '../../../loader/globelLoader';
import WarningIcon from '@mui/icons-material/Warning';
import { withStyles } from "@material-ui/core/styles";

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "12px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }

    }
}))(TextField)

const CancelPlan = ({ title, merchName, handleCancel, handleSubmit, planDetail, addonList, storeName, userAddonQuantity,  setCancelQty }) => {

    const [addonListData, setAddonListData] = useState([]);
    const [planName, setPlanName] = useState('');
    const location = [
        'All the terminals attached to this location will also be canceled',
        '1 free user came with this location add-on will also be canceled',
        'Canceling the location will stop all the related activities. You will no longer have access to all the information of this location such as product and inventory.',
    ]
    const [userProceedForm, setUserProceedForm] = useState(false);
    const userWarningMessage = 'Before canceling any user add-on, please make sure to delete the user in Merchant Portal under People/Users. Otherwise, recently added user accounts will be disabled.'
    const [quantityValue, setQuantityValue] = useState(1);
    const [qtyError, setQtyError] = useState(false);

    useEffect(() => {

        if (title === 'Cancel Plan') {
            async function getMyAddOn() {
                const name = capitalizeFirstLetter(planDetail.planname)
                const period = capitalizeFirstLetter(planDetail.priceType)
                setPlanName('(' + name + "-" + period + ')');
                const addons = [];
                addonList.forEach((addon) => {
                    addon['shortName'] = getAddonShortName(addon.quantity, addon.name);
                    addons.push(addon)
                })
                setAddonListData(addons);
            }
            getMyAddOn();
        }
        if (title === 'Cancel User Add-On') {
            setUserProceedForm(true);
        }

    }, [])





    const capitalizeFirstLetter = (planName) => {
        return planName.charAt(0).toUpperCase() + planName.slice(1);
    }


    const getAddonShortName = (quantity, addon) => {

        const name = addon.toLowerCase();
        if (name.includes('location')) {
            if (quantity === 1) {
                return 'Location';
            } else {
                return 'Locations';
            }

        }
        if (name.includes('terminal')) {
            if (quantity === 1) {
                return 'Terminal';
            } else {
                return 'Terminals';
            }

        }
        if (name.includes('user')) {
            if (quantity === 1) {
                return 'User';
            } else {
                return "Users"
            }
        }
        if (name.includes('website')) {
            return 'Store Website';
        }
        else {
            return '';
        }
    }

    const userWarningProceed = () => {
        setUserProceedForm(false);
    }

    const preventMinusAndPeriod = (e) => {
        if (e.code === 'Minus' || e.code === 'Period' || e.code === 'KeyE' || e.code === 'NumpadSubtract') {
            e.preventDefault();
            return;
        }
    };

    const updateQuantity = (e) => {
        setQtyError(false);
        const qty = e.target.value;
        if (Number(qty) > Number(userAddonQuantity)){
            setQtyError(true);
        }
        if (qty === '') {
            setQtyError(true);
        }
        setQuantityValue(qty)
        if (qty != ''){
            setCancelQty(qty)
        }
        
    }

    return (
        <>

            <div className="cancelmodal">
                <div className="overlay"><></></div>
                <div className="modal-cancel-content">
                    <Grid sx={{
                        p: { lg: "60px", md: "40px", sm: "30px", xs: "20px" },
                        height: "auto",
                        mt: { xs: "60px", sm: "60px", md: "0px" }
                    }}>
                        {title === 'Cancel Plan' ?
                            <MKTypography fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, }}> <span className="semi-bold">{title}</span></MKTypography>
                            :
                            <>
                            {
                                !userProceedForm?
                            <>
                                <MKBox xs={12} sx={{ justifyContent: 'center' }}>
                                    <div style={{ textAlign: 'center' }}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                                </MKBox>
                                <MKTypography mt='35px' fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "center", opacity: 1, }}> <span className="semi-bold">{title}</span></MKTypography>
                            </>
                            : 
                            <>
                            <MKBox xs={12} sx={{ justifyContent: 'center' }}>
                                            <div style={{ textAlign: 'center' }}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                                        </MKBox>
                                        <MKTypography mt='35px' fontSize="16px" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight:2 }}> <span className="semi-bold">{userWarningMessage}</span></MKTypography>
                                        </>
                            }
                            </>

                        }
                        <MKBox>
                            <MKTypography sx={{
                                // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                overflow: "auto"
                            }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                                {title === 'Cancel Plan' ?
                                    <div className="cancel-content">
                                        Dear {merchName},

                                        <div>
                                            Per your request, we will cancel your current paid subscription {planName} and put you in a free tier.
                                        </div>

                                        {addonListData.length > 0 ?
                                            <>
                                                <div>
                                                    The following Add-ons will also be canceled since they will only work with a paid subscription plan.
                                                </div>

                                                <div>
                                                    <ul >
                                                        {addonList.map((addon) =>
                                                            <>
                                                                <li>
                                                                    Additional {addon.quantity} {addon.shortName} ({addon.type})
                                                                </li>
                                                            </>
                                                        )}
                                                    </ul>

                                                </div>
                                            </>
                                            : ""}

                                        <div>
                                            We are sorry to see you downgrade your subscription.
                                        </div>
                                        <div>
                                            If you want to learn more about the benefits and features of your current plan, <a class="link-button" href="https://shelfperks.com/key-features" target="_blank"> click here</a>.
                                        </div>
                                        <div>
                                            If applicable, we may refund or prorate you as per your plan and our
                                            <a class="link-button" href="https://shelfperks.com/terms-of-service" target="_blank"> refund policy</a>.
                                        </div>
                                        <div>
                                            To contact us for questions,
                                            <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                        </div> <br />
                                        <div>
                                            Are you sure you would like to cancel your current plan?
                                        </div> <br />
                                    </div>
                                    :

                                    <>
                                        {title === 'Cancel Location Add-On' ?
                                            <div className="cancel-content">
                                                Dear {merchName},

                                                <div>
                                                    Per your request, we will be canceling this Add-on. — {storeName}
                                                </div>

                                                <div>
                                                    <ul>
                                                        <li>
                                                            {location[0]}
                                                        </li>
                                                        <li>
                                                            {location[1]}
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div>
                                                    {location[2]}
                                                </div>
                                                <div>
                                                    If this is a recurring add-on, you will be refunded the prorated amount.
                                                    To contact us for questions,
                                                    <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                                </div> <br />
                                                <div>
                                                    Are you sure you want to cancel {planDetail} ?
                                                </div> <br />
                                            </div>
                                            : ""}

                                        {title === 'Cancel Terminal Add-On' ?
                                            <div className="cancel-content">
                                                Dear {merchName},

                                                <div>
                                                    Per your request, we will be canceling this terminal add-on at {storeName}.
                                                </div>

                                                <div>
                                                    If this is a recurring add-on, you will be refunded the prorated amount.
                                                    To contact us for questions,
                                                    <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                                </div> <br />
                                                <div>
                                                    Are you sure you want to cancel {planDetail} ?
                                                </div> <br />
                                            </div>
                                            : ""}

                                        {title === 'Cancel User Add-On' ?

                                            <>

                                                {userProceedForm ?
                                                    ""
                                                    :


                                                    <div className="cancel-content">
                                                        

                                                        <div style={{marginBottom: '30px'}}>
                                                            Number of users in this Add-on: <span className='semi-bold'>{userAddonQuantity}</span>
                                                        </div>

                                                        <div>
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className="semi-bold">How many users to cancel?</span></MKTypography>

                                                            <Grid sx={{
                                                                width: '100px'
                                                            }}>
                                                            <MyTextField
                                                            margin="normal"
                                                            
                                                            fullWidth
                                                            id="userCount"
                                                            label="# of users"
                                                            name="userCount"
                                                            type="number"
                                                            variant="standard"
                                                            error={qtyError}
                                                            helperText={qtyError ? 'Enter a valid quantity' : ''}
                                                            value={quantityValue}
                                                            onKeyDown={preventMinusAndPeriod}
                                                            onChange={updateQuantity}
                                                            InputLabelProps={{
                                                                style: { fontFamily: "OpenSansRegular" }
                                                            }}
                                                        />
                                                            </Grid>
                                                   
                                                        </div>
                                                        <div>
                                                            If this is a recurring add-on, you will be refunded the prorated amount.
                                                            To contact us for questions,
                                                            <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                                        </div> <br />
                                                        <div>
                                                            Are you sure you want to cancel {planDetail} ?
                                                        </div> <br />
                                                    </div>
                                                }








                                            </>
                                            : ""}

                                        {title === 'Cancel Store Website Add-On' ?
                                            <div className="cancel-content">
                                                Dear {merchName},

                                                <div>
                                                    Per your request, we will be canceling Store Website Add-on.
                                                </div>
                                                
                                                <div>
                                                    Only My Account page will be available on your store website(s) for your customers. <br />
                                                    All other pages on your store website(s) will be disabled.
                                                </div>

                                                <div>
                                                    You are required to fulfill all the un-fulfilled orders received from the customers until the time of cancellation.
                                                </div>

                                                <div>
                                                    Since this is a recurring add-on, you will be refunded the prorated amount.
                                                    To contact us for questions,
                                                    <a class="link-button" href="https://support.shelfperks.com" target="_blank"> click here</a>.
                                                </div> <br />
                                                <div>
                                                    Are you sure you want to cancel this Store Website Add-on?
                                                </div> <br />
                                            </div>
                                            : ""}
                                        

                                    </>

                                }

                            </MKTypography>

                            {userProceedForm ?
                                <Grid xs={12} lg={12} sx={{ mt: '48px' }} container item>
                                    <MKBox xs={12}  sx={{ display: "inline-block", justifyContent: "start" }}>
                                        <MKButton
                                            variant="outlined"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"
                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                verticalAlign: "middle",
                                                lineHeight: 1.5,
                                                marginRight: '48px',
                                                textTransform: "capitalize",
                                                marginBottom: {xs:'20px',sm:'0px'}

                                            }}
                                            onClick={handleCancel}
                                        >
                                            Close
                                        </MKButton>

                                        <MKButton
                                            variant="contained"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"
                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize",
                                                lineHeight: 1.5,
                                                marginTop: {sx:'20px', sm:'0px'}
                                            }}
                                            onClick={userWarningProceed}
                                        >
                                            Proceed to Cancel User Add-on
                                        </MKButton>


                                    </MKBox>

                                </Grid>
                                :
                                <Grid lg={12} sx={{ mt: { xl: "20px", lg: "20px", md: "40px" } }} container item>
                                    <MKBox sx={{ display: "inline-block" }}>
                                        <MKButton
                                            variant="outlined"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"
                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                mr: { xs: "50px", sm: "50px", md: "50px" },
                                                verticalAlign: "middle",
                                                lineHeight: 1.5,
                                                textTransform: "capitalize",

                                            }}
                                            onClick={handleCancel}
                                        >
                                            No
                                        </MKButton>
                                        <MKButton
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className="upgrade"
                                            sx={{
                                                ':hover': {
                                                    bgcolor: '#3CAC33',
                                                    color: '#FFFFFF',

                                                },
                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize",

                                            }}
                                            onClick={() => {
                                                if(!qtyError){
                                                    handleSubmit();
                                                }
                                            }}
                                        >
                                            Yes
                                        </MKButton>
                                    </MKBox>
                                </Grid>
                            }
                        </MKBox>
                    </Grid>
                </div>
            </div>

        </>
    )
}

export default CancelPlan;