import * as React from 'react';

import banner from "../../assets/images/LoginBannerLatest.svg";
import banneripad from "../../assets/images/ipadBanner.png";

import MKBox from '../../components/MKBox';

import "../../App.css";
import { Grid } from '@mui/material';


export function Banner() {
    return (
        <MKBox className='banner' >
            <Grid container p={0} m={1}>
                <Grid item lg={12} md={12}>
                    <MKBox component="img"
                        sx={{
                            maxWidth: "100%",
                            height: "auto",
                            mt: "20px",
                            mb: "50px",
                        }}
                        src={banneripad}
                    >
                    </MKBox>
                </Grid>
            </Grid>
        </MKBox>
    )
}