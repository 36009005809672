const passwordValidator = (password) => {
    const pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (password.length < 8) {
        return "Password should have a minimum of 8 characters."
    } else if (!pattern.test(password)) {
        return "Must contain at least a number, an uppercase, a lowercase letter and a special character."
    } else {
        return true;
    }
}

export default passwordValidator;