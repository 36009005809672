import axios from "axios";
// import Loaderax from "../loader/globelLoader"

import {getAuth, signInWithCustomToken  } from "firebase/auth"


const login = (loginObj) => {
  const apiUrl = window.spconfig?.config.apiUrl;
    const url = apiUrl + "/growapi/authmyselfserve"
    
  return axios
    .post(url, loginObj)
    .then(async (response) => {
      let responseStatus = {
        status:false,
        data:{errorMsg: 'Invalid Data Posted'}
      }
      if (response.status === 200 && response.data.token) {
        const auth = getAuth();
       const tok = response.data.token;

       await signInWithCustomToken(auth, tok);
       responseStatus.status = true;
       responseStatus.data = response.data
        
      }
      return responseStatus;
    
    }, err => {
      return {
        status:false,
        data:{errorMsg: 'Invalid Data Posted'}
      };
    });
};


const logout = () => {
  const apiUrl = window.spconfig?.config.apiUrl;
  localStorage.removeItem("user");
  return axios.post(apiUrl + "signout").then((response) => {
    return response.data;
  });
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getToken = () => {
  return getAuth()
    .currentUser?.getIdToken(true)
    .then((idToken) => {
      return idToken;
    });
}





const AuthService = {
  login,
  logout,
  getCurrentUser,
  getToken
}
export default AuthService;