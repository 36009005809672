import React, { useState, useEffect } from 'react';
import { Container, Grid } from "@mui/material";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";
import MKButton from "../../../../components/MKButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import currencyConverter from '../../../../helpers/currencyConverter';
import { withStyles } from "@material-ui/core/styles";
import checkCircle from "../../../../assets/images/check_circle.svg"
import Lottie from "lottie-react";
import growLoader from "../../../../assets/animation/growLoader.json";
import { downloadInvoicePopup } from "../../../../providers/invoiceService"
import { getPaymentSessionDetails, getTermCount } from '../../../../providers/addonGrow';



const AddOnStatusModal = ({ type, statusData, toggleAddonStatusModal, merchName, session_id }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [storeCreationError, setStoreCreationError] = useState(false);
    const [termCount, setTermCount] = useState(1);
    useEffect(() => {

        setTimeout(async () => {
            if (type === 'Creation' && statusData.addOnName === 'Location') {
                if (statusData.storeProcessCompleted) {
                    setIsLoading(false);
                } else {
                    setTimeout(async () => {
                        const result = await getPaymentDetails()
                        if (result.storeProcessCompleted) {
                            setStoreCreationError(false);
                            setIsLoading(false);
                        } else {
                            setStoreCreationError(true);
                            setIsLoading(false);
                        }
                    }, 1000);

                }
            } else {
                if (statusData.addOnName === 'Location'){ 
                    setTermCount(statusData.terminals);
                }
                setIsLoading(false);
            }
        }, 2000);
    }, [])


    const getPaymentDetails = async () => {
       
        const sessionDet = await getPaymentSessionDetails(session_id)
        let data = '';
        if (sessionDet.data.ResponseStatus === 'Error') {
            data = 'Error'
        } else {
            data = sessionDet.data.ResponseData;
        }
        return data;

    }

    const handleClose = () => {
        toggleAddonStatusModal();
        const url = window.location.href;
        const baseURL = url.split('?')
        window.location.href = baseURL[0];
    }

    const downloadInvoice = async () => {

        const subType = statusData.addOnType.toLowerCase() === 'one_time'
            ? 'onetime' : statusData.addOnType.toLowerCase()
        const invoiceData = {
            id: statusData.addOnId,
            type: 'Addon',
            subType: subType
        }
        const result = await downloadInvoicePopup(invoiceData, merchName);
    }

    const addonTypeName = (addonName) => {
        if (addonName === 'one_time'){
            return 'One Time';
        }else {
            return 'Recurring'
        }
    }


    return (
        <>
            <div className="addonStatusmodal">
                <div className="overlay"><></></div>
                <div className="addon-status-modal-content">

                    {isLoading ?

                        <>
                            <MKTypography sx={{
                                overflow: "auto"
                            }} fontSize="20px" className="semi-bold" mt="24px" style={{ color: '#202223', letterSpacing: "0px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                <span style={{ fontSize: '20px' }}>Please wait...</span> <br />
                            </MKTypography>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                                <MKBox
                                    sx={{

                                        width: "150px",
                                        height: "150px",
                                    }}>
                                    <Lottie animationData={growLoader} loop={true} />
                                </MKBox>
                            </Grid>
                        </>
                        :

                        <>
                            {statusData !== 'Error' ?
                                < Grid sx={{
                                    p: '24px',
                                    // height: "auto",
                                    mt: { xs: "60px", sm: "60px", md: "0px" }
                                }}>
                                    <MKBox xs={12} mt={2} sx={{ justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center' }}> <img src={checkCircle} alt="check"></img> </div>
                                    </MKBox>
                                    <MKBox>
                                        <MKTypography sx={{
                                            // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                            overflow: "auto"
                                        }} fontSize="20px" className="semi-bold" mt="24px" style={{ color: '#202223', letterSpacing: "0px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                            <span style={{ fontSize: '20px' }}> {statusData.title}</span> <br />
                                        </MKTypography>

                                        {statusData.addOnName === 'Location' ?

                                            <>
                                                <Grid item container sx={12} mt={6}>
                                                    <Grid sm={3}>
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Location Name:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={6}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {statusData.storeName}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                                <Grid item container sx={12}>
                                                    <Grid sm={3}>
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Add-on Type:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {addonTypeName(statusData.addOnType)}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>

                                                <Grid item container sx={12}>
                                                    

                                                    {type === 'Creation' ?
                                                            <>
                                                            <Grid sm={3}>
                                                                {statusData.storeExist ?
                                                                
                                                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                                        Also revived:
                                                                    </MKTypography>
                                                                
                                                                    :
                                                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>

                                                                        Also comes with:
                                                                    </MKTypography>
                                                               
                                                                }
                                                                </Grid>
                                                                
                                                            </>
                                                            
                                                            :
                                                            <Grid sm={5.5}>
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                                Also canceled with this location:
                                                            </MKTypography>
                                                        </Grid>
                                }
                                                    

                                                </Grid>

                                                <Grid item container sx={12}>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={6}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            <ul>
                                                                {type === 'Created' ?
                                                                    <li>1 additional terminal</li>
                                                                    :
                                                                    <li>{termCount} additional terminal</li>
                                                                }
                                                                <li>1 additional user</li>
                                                            </ul>
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>


                                            </>
                                            : ""
                                        }

                                        {statusData.addOnName === 'Terminal' ?
                                            <>
                                                <Grid item container sx={12} mt={6}>
                                                    <Grid sm={3}>
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            To:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={5}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {statusData.storeName}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                                <Grid item container sx={12}>
                                                    <Grid sm={3}>
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Add-on Type:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {addonTypeName(statusData.addOnType)}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                            </>
                                            : ""
                                    }

                                        

                                        {statusData.addOnName === 'User' ?
                                            <>
                                                <Grid item container sx={12} mt={6}>
                                                    <Grid sm={5}>

                                                        {type === 'Creation' ?
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Number of Users:
                                                        </MKTypography>
                                                        :
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                                Number of Users canceled:
                                                            </MKTypography>
                                                        }
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {statusData.userQty}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                                <Grid item container sx={12}>
                                                    <Grid sm={5}>
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Add-on Type:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {addonTypeName(statusData.addOnType)}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                            </>
                                            : ""
                                        }

                                        {statusData.addOnName === 'Store Website' ?
                                            <>
                                                <Grid item container sx={12} mt={6}>
                                                    <Grid sm={12} style={{textAlign: 'center'}}>

                                                        {type === 'Creation' ?
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                                Thank you for the purchase. To set up your store website, please log into your ShelfPerks Merchant Portal and click Sell Online.
                                                            </MKTypography>
                                                            :
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                                Your add-on has been cancelled successfully. To continue selling online, you can upgrade your plan to Plus or Premium Plan.
                                                            </MKTypography>
                                                        }
                                                    </Grid>
                                                    

                                                </Grid>
                                                <Grid item container sx={12} style={{marginTop: '20px'}}>
                                                    <Grid sm={5} >
                                                        <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "right", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            Add-on Type:
                                                        </MKTypography>
                                                    </Grid>
                                                    <Grid sm={1}>

                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <MKTypography className="semi-bold" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                                            {addonTypeName(statusData.addOnType)}
                                                        </MKTypography>
                                                    </Grid>

                                                </Grid>
                                            </>
                                            : ""
                                        }


                                        <Grid lg={12} sx={{ mt: '50px', justifyContent: { md: 'start', sm: 'center', xs: 'center' }, }} container item>

                                            <MKBox sx={{ display: "inline-block", }}>
                                                <MKButton
                                                    variant="outlined"
                                                    color="primary"
                                                    className="upgrade"
                                                    size="medium"
                                                    sx={{
                                                        width: '100px',
                                                        height: '40px',
                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        color: "#50B748",
                                                        borderColor: "#50B748",
                                                        mr: "50px",
                                                        ml: {xs:'0px', sm:'30px', md:'30px', lg:'30px'},
                                                        mb: '50px',
                                                        verticalAlign: "middle",
                                                        lineHeight: 1.5,
                                                        textTransform: "capitalize",
                                                    }}
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </MKButton>
                                                <MKButton
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    className="upgrade"
                                                    sx={{
                                                        ':hover': {
                                                            bgcolor: '#3CAC33',
                                                            color: '#FFFFFF',

                                                        },
                                                        width: 'auto',
                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        verticalAlign: "middle",
                                                        mb: '50px',
                                                        textTransform: "capitalize",
                                                    }}
                                                    onClick={downloadInvoice}
                                                >
                                                    Download Invoice
                                                </MKButton>
                                            </MKBox>
                                        </Grid>



                                    </MKBox>
                                </Grid>
                                :
                                <Grid sx={{
                                    p: '24px',
                                    // height: "auto",
                                    mt: { xs: "60px", sm: "60px", md: "0px" }
                                }}>
                                    <MKBox xs={12} sx={{ justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center' }}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                                    </MKBox>
                                    <MKBox>


                                        <MKTypography sx={{
                                            // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                            overflow: "auto"
                                        }} fontSize="16px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                            <span className='semi-bold' style={{ fontSize: '20px' }}>Payment was not successful.</span> <br />

                                        </MKTypography>


                                        <Grid lg={12} sx={{ mt: '50px', justifyContent: 'center', }} container item>

                                            <MKBox sx={{ display: "inline-block", }}>
                                                <MKButton
                                                    variant="outlined"
                                                    color="primary"
                                                    className="upgrade"
                                                    size="medium"
                                                    sx={{
                                                        width: '100px',
                                                        height: '40px',
                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        color: "#50B748",
                                                        borderColor: "#50B748",
                                                        // mr: "80px",
                                                        // ml: '30px',
                                                        mb: '50px',
                                                        verticalAlign: "middle",
                                                        lineHeight: 1.5,
                                                        textTransform: "capitalize",
                                                    }}
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </MKButton>
                                                
                                            </MKBox>
                                        </Grid>
                                    </MKBox>
                                </Grid>
                            }

                        </>

                    }


                </div>
            </div>

        </>
    )
}

export default AddOnStatusModal