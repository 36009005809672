/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';
import logo from "../../assets/images/logo.svg"
import MKBox from "../../components//MKBox";
import Lottie from "lottie-react";
import growLoader from "../../assets/animation/growLoader.json";

import InputAdornment from '@mui/material/InputAdornment';
import { authenticationValidation } from "../../providers/portalURLValidation";
import Auth from '../../providers/authService';
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { BiInfoCircle } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import { withStyles, styled } from "@material-ui/core/styles";

const MyTextField = withStyles(() => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#FF4040",
      fontFamily: "OpenSansMedium",
      fontSize: "12px",
    },
  }
}))(TextField)

export function MerchantUrl({ setStepperCount }) {

  const navigate = useNavigate();

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      fontSize: '14px'
    },
  });

  const [authCount, setAuthCount] = useState(1);
  const [login, setLogin] = useState({
    userName: "",
    password: '',
    otp: '',
    token: ''
  });

  const [loginformError, setLoginFormError] = useState({});
  const [loginButtonDisabled, setLoginDisabled] = useState(true);
  const [merchErrorMessage, setmerchErrorMessage] = useState([]);

  const [fields, setFields] = useState({
    merchurlid: "",

  });
  const [formError, setFormError] = useState({});
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [commonError, setCommonError] = useState(false);


  useEffect(() => {
    setStepperCount(-1)
    if (authCount === 1) {
      if (fields.merchurlid === "" || fields.merchurlid === undefined) {

        setSubmitButtonDisabled(true);
      } else {
        setSubmitButtonDisabled(false);
        delete formError['merchurlid'];
        setFormError(formError);
      }
    } else {
      let emptyFields = 0;
      for (let key in login) {
        if (login[key] === "" || login[key] === undefined) {
          emptyFields++;
        } else {
          delete loginformError[key]
          setLoginFormError(formError);
        }
      }

      if (emptyFields <= 0) {
        setLoginDisabled(false)
      } else {
        setLoginDisabled(true)
      }
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, login, authCount, formError])


  const validatePortalUrl = async () => {
    if (fields.merchurlid !== "") {

      const urlValid = await authenticationValidation(fields.merchurlid);
      if (urlValid.data.ResponseStatus === 'Success') {
        const responseDataToken = urlValid.data.ResponseData.data.authToken;
        login.token = responseDataToken;
        setAuthCount(2);
        // 
      } else {
        // TODO
        const error = {
          merchurlid: true
        };
        setAuthCount(1);

        await setmerchErrorMessage(["Incorrect Portal URL or Incorrect Merchant ID"])
        await setFormError(error);

      }

    }
  }

  const validateLogin = async () => {
    if (login.userName !== "" && login.password !== '') {
      const loginObj = {
        username: login.userName,
        password: login.password,
        verifycode: login.otp,
        verifytoken: login.token
      };
      const urlValid = await Auth.login(loginObj)
      if (urlValid.status) {
        navigate('/plan');
      } else {
        // TODO
        setAuthCount(2);
        setCommonError(true);
      }
    }
  }

  const urlSubmit = async (event) => {
    setAuthCount(3);
    event.preventDefault();
    await validatePortalUrl();
    setFormError(validateFields(1))
  };

  const loginSubmit = async (event) => {
    setAuthCount(3);
    event.preventDefault();
    await validateLogin();
    setLoginFormError(validateFields(2))
  };

  const validateFields = (step) => {
    let errors = {}
    if ((fields.merchurlid === "" || fields.merchurlid === undefined)) {
      errors['merchurlid'] = true;
      setmerchErrorMessage(["merchantUrl (Or) merchantId is required"])
      setAuthCount(step);
    }
    return errors;
  }

  const handleInputChange = async (event) => {

    const { name } = event.target;
    const { value } = event.target;
    delete formError[name];
    setFormError(formError);
    setFields({ ...fields, [name]: value });

  };

  const handleLoginInputChange = async (event) => {

    const { name } = event.target;
    const { value } = event.target;
    delete formError[name];
    setLoginFormError(formError);
    setLogin({ ...login, [name]: value });

  };

  const tooltip1Text = "https://portal.shelfperks.com/merchant/<Portal URL's portion>/";
  const tooltip2Text = "Your Merchant ID was emailed to you when you signed up.";



  return (
    <>
      {authCount === 1 ?
        <Container component="main" maxWidth="xs" style={{minHeight:'500px', height:"80vh"}}>
          <CssBaseline />
          <Grid item justifyContent="center" style={{ textAlign: "center", width: "auto", margin: "auto 0" }} sx={{ textAlign: "center" }} pt="100px" xs={12} >
            <div className='logo'>
              <img src={logo} alt="LOGO" width="203px" height="36px"></img>
            </div>
          </Grid>
          <Grid container spacing={2} justifyContent="center" sx={{ mb: "20px", mt: "80px" }} >
            <Grid item xs={12} >
              <MKTypography fontSize="14px" className="opensans-regular" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, }}>Portal URL's portion or Your Merchant ID
                <CustomWidthTooltip  title={
                  <React.Fragment>
                    {tooltip1Text}
                    <br /> <br />
                    {tooltip2Text}
                  </React.Fragment>
                } arrow>
                  <IconButton>
                    <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                  </IconButton>
                </CustomWidthTooltip>
              </MKTypography>
              <MyTextField
                margin="normal"

                fullWidth
                id="merchurlid"
                // label=""
                name="merchurlid"
                autoFocus
                variant="standard"
                sx={{ mb: "10px" }}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                value={fields.merchurlid}
                error={formError.merchurlid}
                helperText={merchErrorMessage[0]}
                onChange={handleInputChange}
              />
            </Grid>

          </Grid>


          <Stack spacing={3} justifyContent="center" direction="row" sx={{ width: "100%", mb: "40px" }}>
            <MKButton variant="contained"
              disabled={submitButtonDisabled}
              className="authButton"
              onClick={urlSubmit}
              sx={{
                width: "342px",
                height: "48px",
                backgroundColor: "#50B748",
                color: "white",
                textTransform: "capitalize",
                fontFamily: "OpenSansSemiBold",
                fontSize: "15px",

              }}
            >
              Submit
            </MKButton>
          </Stack>

        </Container >
        : ""}

        {authCount === 2 ?        
        <Container component="main" maxWidth="xs" style={{ minHeight: '650px', height: "80vh" }}>
          <CssBaseline />
          <Grid item justifyContent="center" style={{ textAlign: "center", width: "350px", margin: "auto 0" }} sx={{ textAlign: "center" }} pt="100px" xs={12} >
            <div className='logo'>
              <img src={logo} alt="LOGO" width="203px" height="36px"></img>
            </div>
          </Grid>
          <Grid>
            <MKTypography fontSize="18px" className="opensans-regular" mt="50px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, fontWeight: "700" }}>Let's confirm it's you</MKTypography>
          </Grid>
          <Grid container spacing={2} justifyContent="center" sx={{ mb: "20px", mt:"10px" }} >
            <Grid item xs={12}>
              <MKTypography fontSize="14px" className="opensans-regular" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, }}>Username
                <Tooltip title="Enter the username created during signup." arrow>
                  <IconButton>
                    <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                  </IconButton>
                </Tooltip>
              </MKTypography>
              <TextField
                // margin="normal"

                fullWidth
                id="userName"
                // label="Username"
                placeholder='Please enter username here.'
                name="userName"
                autoFocus
                variant="standard"
                sx={{ mb: "10px" }}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                value={login.userName}
                error={loginformError.userName}
                helperText={loginformError.userName ? "userName is required" : ''}
                onChange={(e) => {
                  handleLoginInputChange(e)
                  setCommonError(false)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MKTypography fontSize="14px" className="opensans-regular" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, }}>Password
                <Tooltip title="Password corresponding to that username." arrow>
                  <IconButton>
                    <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                  </IconButton>
                </Tooltip>
              </MKTypography>
              <TextField
                // margin="normal"
                fullWidth
                id="password"
                // label="Password"
                name="password"
                placeholder='Please enter password here.'
                variant="standard"
                sx={{ mb: "24px" }}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                value={login.password}
                error={loginformError.password}
                helperText={loginformError.password ? "Password is required" : ''}
                onChange={(e) => {
                  handleLoginInputChange(e)
                  setCommonError(false)
                }}
                type="password"
              />
            </Grid>

            <Grid item xs={12}>
              <MKTypography fontSize="14px" className="opensans-regular" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1, }}>One Time Passcode
                <Tooltip title="Just now we've sent a passcode to your registered email and mobile number." arrow>
                  <IconButton>
                    <BiInfoCircle style={{ fontSize: "18px" }} className="merchant-tooltip" />
                  </IconButton>
                </Tooltip>
              </MKTypography>
              <TextField
                // margin="normal"
                required
                fullWidth
                id="otp"
                // label="One Time Password"
                placeholder='Please enter one time passcode here.'
                name="otp"
                variant="standard"
                sx={{ mb: "24px" }}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                value={login.otp}
                error={loginformError.otp}
                helperText={loginformError.otp ? "Otp is required" : ''}
                onChange={(e) => {
                  handleLoginInputChange(e)
                  setCommonError(false)
                }}
              />
            </Grid>

            {commonError ?
              <Grid item xs={12}>

                <MKTypography sx={{
                  fontSize: "14px",
                  fontFamily: "OpenSansRegular",
                  color: "#FF4040",
                  padding: "10px"
                }} >
                  Entered values are incorrect !
                </MKTypography>
              </Grid>
              : ""}

          </Grid>


          <Stack spacing={3} justifyContent="center" direction="row" sx={{ width: "100%", mb: "40px" }}>
            <MKButton variant="contained"
              disabled={loginButtonDisabled}
              onClick={loginSubmit}
              sx={{
                width: "342px",
                height: "48px",
                backgroundColor: "#50B748",
                color: "white",
                textTransform: "capitalize",
                fontFamily: "OpenSansSemiBold",
                fontSize: "15px",
              }}
              className="authButton"
            >
              Login
            </MKButton>
          </Stack>
        </Container > : ""}

        {authCount === 3?
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
        <MKBox
          sx={{
            mt: '250px',
            width: "150px",
            height: "150px",
          }}>
          <Lottie animationData={growLoader} loop={true} />
        </MKBox>
      </Grid> 
      : "" 
     }
    </>

  )
}