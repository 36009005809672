import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { InputAdornment } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { BiInfoCircle } from 'react-icons/bi';
import { withStyles, styled } from "@material-ui/core/styles";

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from '../../components/MKButton';


import "../../App.css"

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import completeMerchantService from '../../providers/completeMerchantService';
import { Processing } from "../../signup/processing";
import { Done } from "../../signup/done";
import checkMerchantStauts from "../../providers/checkMerchantStatus";
import {portalURLValidation} from "../../providers/portalURLValidation";
import passwordValidator from "../../helpers/passwordValidator";
import { useMediaQuery } from 'react-responsive';

const style = {
  color: '#F7931E',
  borderColor: "#F7931E",
  borderRadius: "50%",
}

const MyTextField = withStyles(() => ({
  root: {
    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color: "#F7931E",
      fontSize: "14px",
    },
    "& .MuiInput-root": {
      fontSize: "14px",
    },
    "& .MuiFormHelperText-root": {
      color: "#FF4040",
      fontFamily: "OpenSansMedium",
      fontSize: "10px",
    },
    "& .MuiFormLabel-root.Mui-error ": {
      color: "#FF4040",
      fontFamily: "OpenSansRegular",
    }
  }
}))(TextField)

const ColorButton = styled(MKButton)(() => ({
  '&:hover': {
    backgroundColor: "#3CAC33",
  },
}))



export function Account({ inviteCodeValidated, setStepperCount }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const inputCode = params.verifycode;

  const [pageNumber, setPageNumber] = useState(1);
  const [portalURL, setPortalURL] = useState("");
  const [portalURLStatus, setPortalURLStatus] = useState();
  const [portalURLstatusMessage, setPortalURLstatusMessage] = useState("");
  const [passwordError, setPaswordError] = useState(false)
  const [passwordRuleStatus, setPasswordRuleStatus] = useState(false)
  const [passwordRuleMessage, setPasswordRuleMessage] = useState(false)
  const [portalDoamin, setPortalDomain] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [merchantName, setMerchantName] = useState("");
  const [fields, setFields] = useState({
    user_name: "",
    password: "",
    confirmPwd: "",
    portal_url: "",
    url_token: inputCode,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alreadeyAdded, setAlreadyAdded] = useState(false);
  const [portalUrlValid, setPortalUrlValid] = useState(true);


  const checkValidation = () => {
    let errors = {}
    for (let key in fields) {
      if(key === 'user_name'){
        if(fields.user_name.length < 6){
          errors[key] = true;
        }
        if (fields.user_name.includes(' ')){
          errors[key] = true;
        }
      }
      if (fields[key] === "") {
        errors[key] = true;
      }
    }
    return errors;
  }


  const validateAccountPage = async () => {
    if (location.state) {
      setPortalDomain(location.state.portalDomain);
      setPortalURL(location.state.merchantURL);
      setPageNumber(location.state.pageNumberToDisplay);
      setMerchantName(location.state.merchantName);
      if (location.state.pageNumberToDisplay === 3) {
        setAlreadyAdded(true);
      }
      const verifyCode = location.state.verifycode;
      if (verifyCode !== null || verifyCode !== undefined) {
        if (verifyCode === "validation needed") {
          return false;
        } else {
          return true;
        }

      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (pageNumber === 1) {
      setStepperCount(2)
    } else if (pageNumber === 2) {
      setStepperCount(-1)
    }
    else if (pageNumber === 3) {
      setStepperCount(-1)
    }
  }, [pageNumber])

  useEffect(async () => {
    const result = await validateAccountPage();

    setPortalURLStatus(false);
    if (result === false) {
      if (inputCode === undefined || inputCode === null) {
        navigate("/register")
      } else {
        navigate("/register/complete/" + inputCode);
      }
    } else {
      if (!inviteCodeValidated) {
        if (location.state.pageNumberToDisplay === 1) {
          navigate("/register/complete/" + inputCode);
        }

      }
    }


  }, [])

  const handleInputChange = async (event) => {
    const { name } = event.target;
    let { value } = event.target;
    delete formErrors[name];
    setFormErrors(formErrors);
    if(name === "portal_url"){
      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || /\s/.test(value)) {
        setPortalUrlValid(false)
         setFormErrors({portal_url : true})
      }
    } if (name === "user_name"){
      const check = value.includes(' ');
      if (check){
        setFormErrors({ user_name: true })
      }
    }
    if (name === "portal_url" || name === "user_name") {
      value = value.toLowerCase()
    }
    setFields({ ...fields, [name]: value });
    setPasswordRuleMessage("")
    setPasswordRuleStatus(false);
    setPaswordError(false)
    setPortalURLStatus(false)
  }

  const handlePasswordCheck = async () => {
    if (fields['confirmPwd'] === "") {
      return;
    }
    if (fields['password'] !== fields['confirmPwd']) {
      setPaswordError(true);
    } else {
      setPaswordError(false)
    }
  }

  const validatePassword = async () => {
    if (fields.password === "") {
      setFields({ ...fields, "confirmPwd": "" });
      setPaswordError(false);
      setPasswordRuleStatus(false);
      setPasswordRuleMessage("")
      return;
    }
    const passwordResult = passwordValidator(fields.password)
    if (passwordResult === true) {
      setPasswordRuleStatus(false);
      setPasswordRuleMessage("")
    } else {
      setPasswordRuleStatus(true);
      setPasswordRuleMessage(passwordResult)
    }
  }

  const validatePortalUrl = async () => {
    if (fields.portal_url !== "") {
      if (String(fields.portal_url).length < 5) {
        setPortalURLstatusMessage("Minimum length is five");
        setPortalURLStatus(true);
        return;
      }
      if (String(fields.portal_url).length > 20) {
        setPortalURLstatusMessage("Maxmum length is twenty");
        setPortalURLStatus(true);
        return;
      }
      const urlValid = await portalURLValidation(fields.portal_url)
      if (urlValid.data.ResponseStatus === 'Error') {
        setPortalURLstatusMessage(urlValid.data.ResponseMessage);
        setPortalURLStatus(true);
      }
      if (urlValid.data.ResponseStatus === 'Success') {
        setPortalURLStatus(false)
      }
    } else {
      setPortalURLStatus(false)
    }
  }

  const handleSubmit = async (event) => {
    if (fields.user_name.length < 6){
      setFormErrors({ user_name: true })
      setFormErrors(checkValidation())
      return;
    }
    if (Object.keys(formErrors).length === 0){
      await validatePortalUrl();
      await handlePasswordCheck();
      await validatePassword()
      event.preventDefault();
      setIsSubmitted(true);
      setFormErrors(checkValidation())
    }
  };

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && isSubmitted && !portalURLStatus && !passwordError && !passwordRuleStatus) {
      const result = await completeMerchantService(fields);
      if (result.data.ResponseStatus === 'Success') {
        setPageNumber(2)
        const statusAuth = result.data.ResponseContent.data.statusauth;
        handleProcessingStep(statusAuth);
        setFields({
          merchantName: "",
          user_name: "",
          password: "",
          confirmPwd: "",
          portal_url: "",
          url_token: " ",
        })
      }
    }
  }, [formErrors]
  );

  const handleProcessingStep = async (authurl) => {
    const statusResult = await checkMerchantStauts(authurl);
    if (statusResult.data.ResponseStatus === "Success") {
      const resultStatus = statusResult.data.ResponseContent.data.domainStatus;
      const portalurl = statusResult.data.ResponseContent.data.portalurl;

      if (resultStatus === 2) {
        // progress success
        setPortalURL(portalurl);
        setPageNumber(3)
        return portalurl;
      } else {
        setTimeout(() => {
          handleProcessingStep(authurl);
        }, 3000);
      }
    } else {
      setTimeout(() => {
        handleProcessingStep(authurl);
      }, 3000);
    }

  };

  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  const isNotMobile = useMediaQuery({ query: '(min-width: 600px)' })


  return (
    <>{pageNumber === 1 ?
      <MKBox mb='250px'>
        <Container component="main" maxWidth="xs" style={{ overflowX: "hidden" }}>
          <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>
            <Grid item xs={12} lg={12}>
              <MKTypography variant="subtitle1" sx={{ fontFamily: "OpenSansRegular" }}>
                Create your own merchant portal
                {/* <span className='info-icon' style={style}>
                  <BiInfoCircle />
                </span> */}
              </MKTypography>
              <MKTypography sx={{ fontFamily: "OpenSansRegular", color:'#8C9196', letterSpacing:'0px', fontSize:'10px', alignText:'left' }}>
                Eg: https://portal.shelfperks.com/merchant/dallasgrocery
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <MyTextField
                margin="normal"
                id="merchantName"
                // label="Set "
                label={isMobile ? `${portalDoamin}/merchant/` : ""}
                InputProps={isNotMobile ? {
                  startAdornment:
                    <InputAdornment position="start" style={{ fontFamily: "OpenSansRegular" }}>{portalDoamin}/merchant/  <span style={{ color:'#1A73E8', opacity:'0.3', marginLeft: '5px'}}>|</span></InputAdornment>
                } : ""
                }
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                onChange={handleInputChange}
                
                value={fields.portal_url}
                onBlur={validatePortalUrl}
                error={portalURLStatus || formErrors.portal_url}
                helperText={portalURLStatus ? portalURLstatusMessage : formErrors.portal_url ? !portalUrlValid?"Invalid value" : "Portal URL is required" : ""}
                name="portal_url"
                autoFocus
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={12} sx={{
              borderRadius: '8px',
              backgroundColor:'#EBFFEA',
              mt: 1
            }}>
              <MKTypography sx={{
                color:'#202223',
                fontSize: '12px',
                letterSpacing: '0px',
                fontFamily: "OpenSansMedium",
                p:2

              }}>
                This is your link to access your store operating system from a browser. For tablets and smartphones you can use the ShelfPerks Merchant App.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <MyTextField
                margin="normal"
                required
                fullWidth
                id="userName"
                label="Create Username"
                name="user_name"
                variant="standard"
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                onChange={handleInputChange}
                error={formErrors.user_name}
                helperText={formErrors.user_name ? fields.user_name === '' ? "Username is required" : fields.user_name.includes(' ')?"Space is not allowed":'Must contain 6 characters':''}
                value={fields.user_name}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MyTextField
                margin="normal"
                fullWidth
                required
                id="password"
                label="Create Password"
                name="password"
                variant="standard"
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                error={passwordRuleStatus || formErrors.password}
                helperText={passwordRuleStatus ? passwordRuleMessage : formErrors.password ? "Password is required" : ""}
                onChange={handleInputChange}
                onBlur={validatePassword}
                value={fields.password}
                type="password"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <MyTextField
                margin="normal"
                required
                fullWidth
                id="confirmPwd"
                label="Confirm Password"
                name="confirmPwd"
                variant="standard"
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
                onChange={handleInputChange}
                error={passwordError || formErrors.confirmPwd}
                helperText={passwordError === true ? "The password does not match. Please try again" : formErrors.confirmPwd ? "Confirm Password is required" : ""}
                onBlur={handlePasswordCheck}
                value={fields.confirmPwd}
                type="password"
              />
            </Grid>
            <Grid item xs={12} lg={12} mt="50px">
              <ColorButton
                variant="contained"
                sx={{
                  width: "100%",
                  height: "48px",
                  backgroundColor: "#50B748",
                  color: "white",
                  textTransform: "capitalize",
                  fontFamily: "OpenSansSemiBold",
                  fontSize: "15px"
                }}
                onClick={handleSubmit}
              >
                Register

              </ColorButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox> : ""}

      {pageNumber === 2 ? <Processing /> : ""}
      {pageNumber === 3 ? <Done portalurl={portalURL} merchantName={merchantName} alreadeyAdded={alreadeyAdded} /> : ""}
    </>
  )
};