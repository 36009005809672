import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import Grid from "@mui/material/Grid";
import MKTypography from "./components/MKTypography";
import MKBox from "./components/MKBox";
import tiktok from "./assets/images/tiktok.png";
import Insta from "./assets/images/footer/instaSvg.svg";
import fb from "./assets/images/footer/fbSvg.svg"
import tick from "./assets/images/footer/tiktokSvg.svg"
import tweet from "./assets/images/footer/twitterSvg.svg"
import ytube from "./assets/images/footer/youtubeSvg.svg"
import linkedin from "./assets/images/footer/linkedinSvg.svg"
import threads from "./assets/images/footer/threadsSvg.svg"

export default {
    menus: [
        {
            items: [
                { name: "About", href: "https://shelfperks.com/about", place:'', },
                { name: "Resources", href: "https://shelfperks.com/resources", place: '', },
                { name: "Careers", href: "https://2497inc.com/careers/", place: '_blank', },
            ],
        },
        {
            items: [
                { name: "Change Plan", href: "https://grow.shelfperks.com/#/authenticate", place: '', },
                { name: "FAQ", href: "https://shelfperks.com/faq", place: '', },
                { name: "Get Help", href: "http://support.shelfperks.com/", place: '', },
            ],
        },
        {
            items: [
                // { name: "Manage Cookies", href: null },
                { name: "Terms of Service", href: "https://shelfperks.com/terms-of-service", place: '', },
                { name: "Privacy Policy", href: "https://shelfperks.com/privacy-policy", place: '', },
            ],
        },
    ],
    
    // copyright: (

    //     <Grid container
    //         direction="row"
    //         justifyContent="space-between"
    //         alignItems="center">
    //         <Grid item xs={12} lg={6} sm={6} md={7}
    //             sx={{
    //                 textAlign:
    //                 { xs: "center", sm: "left"},
    //                 pl: { xs: 0, lg: 1 }
    //             }}
    //             justifyItems="center"
    //             pt={0.5}
    //             pb={0.5}
    //             >
    //             <MKTypography sx = {
    //                 {
    //                     ml : { lg: '0px', md: "0px", sm: "0px"}
    //                 }
    //             } fontWeight="regular" color="white" fontSize="14px" display="inline-block">
    //                 Copyright © 2022 ShelfPerks.com All rights reserved.
    //             </MKTypography>
    //         </Grid>
    //         <Grid item lg={6} sm={6} md={5} container direction="column" justifyContent="center" pt={0.5} pb={0.5}>
    //             <MKBox mt={1} sx={{
    //                 textAlign: { xs: "center", md: "right", sm: "right", lg: "right" },
    //                 pl:{sm:2 }
                    
    //             }}
    //                 justifyItems="center">
    //                 <a href="/" aria-label="instagramLink" style={{color:'white'}}>
    //                     <MKTypography display="inline-block" color="white" mr="36px" >
    //                         <InstagramIcon />
    //                     </MKTypography >
    //                 </a>
    //                 <a href="/" aria-label="twitterLink" style={{color:'white'}}>
    //                     <MKTypography display="inline-block" color="white" mr="36px" >
    //                         <TwitterIcon />
    //                     </MKTypography >
    //                 </a>
    //                 <a href="/" aria-label="FacebookLink" style={{color:'white'}}>
    //                     <MKTypography display="inline-block" color="white" mr="36px" >
    //                         <FacebookRoundedIcon />
    //                     </MKTypography >
    //                 </a>
    //                 <a href="/" aria-label="tiktokLink" style={{color:'white'}}>
    //                     <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "15px", position: "relative", top: '-3px' }}>
    //                         <img src={tiktok} alt="" />
    //                     </MKTypography >
    //                 </a>
    //                 <a href="/" aria-label="youTubeLink" style={{color:'white'}}>
    //                     <MKTypography display="inline-block" color="white" pt={0} sx={{mr:{sm:"15px", md:0, lg:'35px' }}}>
    //                         <YouTubeIcon />
    //                     </MKTypography >
    //                 </a>
    //             </MKBox>
    //         </Grid>
    //     </Grid>
    // ),

    copyright: (

        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // style={{fontFamily: "Arial" }}
        >
            <Grid item xs={12} lg={6} sm={6} md={7} className="copyright" mb="0px"
                sx={{
                    textAlign: { xs: "center", sm: "start", },
                }}
                justifyItems="center"
            >
                <MKTypography style={{ fontFamily: "OpenSansRegular" }} sx={{ fontSize: "14px" }} display="inline-block" className="copyright white open-sans footer-text">
                    Copyright © {new Date().getFullYear()} ShelfPerks.com All rights reserved.
                </MKTypography>
            </Grid>
            <Grid item lg={6} sm={6} md={5} container direction="column" justifyContent="center" mb="0px" >
                <MKBox mt={1} className="footer-icons" sx={{
                    textAlign: { xs: "center", md: "right", sm: "right", lg: "right" }, pl: { sm: 2 },
                }}
                    justifyItems="center">

                    <a href='https://www.linkedin.com/company/shelfperks/'>
                            <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                            <img src={linkedin} height={18} width={18} />
                            </MKTypography >
                        </a>

                    <a href='https://www.threads.net/@shelfperks/'>
                        <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                        <img src={threads} height={18} width={20} />
                        </MKTypography >
                    </a>
                    
                    <a href='https://www.instagram.com/shelfperks/'>
                            <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                            <img src={Insta} height={18} width={18} />
                            </MKTypography >
                        </a>
                    
                    
                    <a href='https://twitter.com/shelfperks'>
                            <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                            <img src={tweet} height={17} width={18} />
                            </MKTypography >
                        </a>
                   
                    
                    <a href='https://www.facebook.com/ShelfPerks'>
                            <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                                <img src={fb} height={20} width={21} />
                            </MKTypography >
                        </a>
                   
                    
                    <a href='https://www.tiktok.com/@shelfperks'>
                            <MKTypography display="inline-block" color="white" mr="36px" sx={{ height: "18px", }}>
                            <img src={tick} height={20} width={18} />
                            </MKTypography >
                        </a>
                   
                    <a href='https://www.youtube.com/channel/UCbcwcwtDvQd87PciEEql5PQ'>
                            <MKTypography display="inline-block" color="white" mr="0px" sx={{ height: "18px", position: "relative", top: "4px" }}>
                            <img src={ytube} height={26} width={22} />
                            </MKTypography >
                        </a>
                   
                </MKBox>
            </Grid>
        </Grid>
    ),

};