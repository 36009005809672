import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../components/MKTypography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { getPaymentProviders } from '../../../providers/planService'
import { getAllStore } from '../../../providers/addonGrow'

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "11px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }
    }
}))(TextField)


export default function PaymentProvider() {



    const [providerData, setProviderData] = useState([]);
    const [showCard, setShowCard] = useState(false)

    useEffect(() => {

        async function getproviders() {
            
            const providers = await getPaymentProviders();
            const paymentProviders = providers.data.listOfProviders
            if (paymentProviders.length === 0){
                return;
            }
            setShowCard(true);
            const dataArray = [];
            const getstores = await getAllStore();
            const allStores = getstores.data.ResponseData.data



            paymentProviders.forEach((det) => {
                const storeDet = allStores.find((store) => store.key === det.location_id)
                dataArray.push({
                    expiry: det.expiry,
                    storeName: storeDet ? storeDet.storeName: " ",
                    provider: det.provider,
                    price: (det.price / 100)
                })
            })
            setProviderData(dataArray);



            // providerData.forEach((element) => {
            //     const storeData = storeAll.find((store) => store.key === element.location_key)
            //     element['storename'] = storeData.storeName;
            // })
        }
        getproviders();

    }, [])

    const converToCamelCase = (name) => {
        return name.substring(0, 1).toUpperCase() + name.substring(1, name.length).toLowerCase();
    }



    return (
        <>
            {showCard ?
                <Grid container>
                    <Card sx={{ minWidth: 275, mt: "24px", width: "100%", borderRadius: 1, height: 'auto', boxShadow: 3 }}
                        // style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}
                        >
                        <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>


                            <Grid item container xs={12} >
                                <Grid item container xs={12}>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> Payment Providers Added </span></MKTypography>

                                </Grid>

                                <Grid item xs={4} sm={4} md={4} lg={4} justifyContent="start" sx={{ pr: { sx: '10px', sm: '10px' } }}>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }} className="semi-bold"> <span> Location </span></MKTypography>

                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={5.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }} className="semi-bold"> <span> Payment Provider </span></MKTypography>



                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={2.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                                    <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", opacity: 1, lineHeight: 2 }} className="semi-bold" sx={{ textAlign: { sm: "center", xs: "center", md: 'left' }, }}> <span> Total Monthly Recurring Fee </span></MKTypography>
                                </Grid>

                                {providerData.map((ele) => {
                                    return (
                                        <Grid key={ele.expiry} item container xs={12}>
                                            <Grid item xs={4} sm={4} md={4} lg={4} justifyContent="start" sx={{ pr: { sx: '10px', sm: '10px' } }}>
                                                <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }}> <span>{ele.storeName} </span></MKTypography>

                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={5.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                                                <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }}> <span> {converToCamelCase(ele.provider)} </span></MKTypography>


                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={2.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                                                <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }} sx={{ textAlign: { sm: "center", xs: "center", md: 'left' }, }}> <span> ${ele.price}</span></MKTypography>
                                            </Grid>
                                        </Grid>

                                    )
                                })}


                                {/* <Grid item xs={4} sm={4} md={4} lg={4} justifyContent="start" sx={{ pr: { sx: '10px', sm: '10px' }}}>
                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }}> <span>San Jose San Jose San Jose </span></MKTypography>

                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={5.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }}> <span> Stripe, Clover/Fiserv, Stax </span></MKTypography>


                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={2.5} justifyContent="start" sx={{ pl: { sx: '10px', sm: '10px' }, pr: { sx: '10px', sm: '10px' } }}>
                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", opacity: 1, lineHeight: 2.5, fontFamily: 'OpenSansRegular' }} sx={{ textAlign: { sm: "center", xs: "center", md:'left' }, }}> <span> $60</span></MKTypography>
                        </Grid> */}




                            </Grid>
                            {/* <div style={{ marginTop: "30px" }}>
                                <hr sx={
                                    { color: "#DCDCDC" }
                                } />
                            </div> */}
                        </CardContent>
                    </Card>




                </Grid>

                :
                ""
            }
        </>
    )

}