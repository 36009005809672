import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import parse from "html-react-parser";
import { BiInfoCircle } from 'react-icons/bi';
import MKTypography from '../../../components/MKTypography';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import tickImage from "../../../assets/images/tickicon.svg"
import { Scrollbars } from 'react-custom-scrollbars';

function createData(feature, free, standard, plus, premium) {
    return { feature, standard, plus, premium };
}

const check = <div style={{ paddingTop: "5px", }}><img src={tickImage} alt="check" height={22} width={22} layout="intrinsic" /></div>
const checkout = 'Self Checkout'
const comingSoon = '<strong>More exciting features are in the pipeline</strong>'
const multipleLocations =
    <MKTypography className="table-features">
        Manage Multiple <br /> Locations
        <Tooltip arrow title="Add additional location for $100 per new location. It also comes with 1 new terminal and 1 user." placement= 'bottom-start'>
            <IconButton size='medium' style={{ fontWeight: "700", fontSize: "14px" }}>
                <BiInfoCircle />
            </IconButton>
        </Tooltip>
    </MKTypography>

const terminals = <MKTypography className="table-features">
    Numbers of Terminals
    <Tooltip arrow title="Add additional terminal for $35 per new terminal" >
        <IconButton size='medium' style={{ fontWeight: "700", fontSize: "14px" }}>
            <BiInfoCircle />
        </IconButton>
    </Tooltip>
</MKTypography>

const users = <MKTypography className="table-features">
    Users
    <Tooltip arrow title="Add additional user for $5 per new user"  placement="bottom-start">
        <IconButton size='medium' style={{ fontWeight: "700", fontSize: "14px" }}>
            <BiInfoCircle />
        </IconButton>
    </Tooltip>
</MKTypography>

const additionalLocation = <MKTypography className="table-features">
    Additional Location *** (cost per month)
    <Tooltip arrow title="1 new Location add-on comes with 1 additional terminal and 1 additional users" placement="bottom-start">
        <IconButton size='medium' style={{ fontWeight: "700", fontSize: "14px" }}>
            <BiInfoCircle />
        </IconButton>
    </Tooltip>
</MKTypography>

const rows = [
    createData('Price per month paid annually', '$0', '$29.95', '$99.95', '$199.95'),
    createData('Price per month paid monthly', '$0', '$34.99', '$119.99', '$249.99'),
    createData('Unlimited Products', check, check, check, check),
    createData('Turnover Limit', '$4,999/month', 'Unlimited', 'Unlimited', 'Unlimited'),
    createData('Real-time Inventory Management', check, check, check, check),
    createData('Secure Cloud-based Infrastructure', check, check, check, check),
    createData('Point Of Sale', check, check, check, check),
    createData(multipleLocations, 'Up to 1 location', 'Up to 1 location', "Up to 2 locations", 'Up to 2 locations'),
    createData(terminals, 'Up to 1 terminal per location', 'Up to 1 terminal per location', 'Up to 2 terminals per location', 'Up to 3 terminals per location'),
    createData(users, 'Up to 1 user', 'Up to 1 user', 'Up to 5 users', 'Up to 8 users'),
    createData('Setup Tax Rates Based On Location', check, check, check, check),
    // createData('Add New Products - One by One', check, check, check, check),
    // createData('Add New Products - Bulk Import', check, check, check, check),
    // createData('Add New Products - Batch Scan', check, check, check, check),
    createData('Onboard New Products in multiple easy way', check, check, check, check),
    createData('Receipt Printer Integration', check, check, check, check),
    createData('Email Receipts', check, check, check, check),
    createData('Customer Management', check, check, check, check),
    createData('Supplier Management', check, check, check, check),
    createData('Create Purchase Orders', check, check, check, check),
    createData('Pick Any Payment Provider', check, check, check, check),
    createData('Account Support / Knowledge Base', check, check, check, check),
    createData('Real-time Dashboard', 'Basic', 'Advanced', 'Advanced', 'Advanced'),
    createData('Product Promotions', "", check, check, check),
    createData('User Roles and Permissions', "", check, check, check),
    createData('Customize Receipts', "", check, check, check),
    createData('Reprint Receipts', "", check, check, check),
    createData('Customer Age Verifications', "", check, check, check),
    createData('Real-time Notifications', "", check, check, check),
    createData('Real-time Register Reports', "", check, check, check),
    createData('Advanced Actionable Reports', "", check, check, check),
    createData('Product Quantity Alerts', "", check, check, check),
    createData('Print Product Labels', "", check, check, check),
    createData('Create Shipments', "", "Internal transfer shipments only", check, check),
    createData('Suspend and Recall Transactions', " ", " ", check, check),
    createData('Run Your Own Discount Programs', " ", " ", check, check),
    createData('Run Your Own Gift Card Programs', " ", " ", check, check),
    createData('Mobile and Tablet Offline Operations', " ", " ", check, check),
    createData('Product Expirations Report', " ", " ", check, check),
    createData('Real-time Product Expiration Alerts', " ", " ", check, check),
    createData('Intelligent Refill Alerts', " ", " ", check, check),
    createData('Suggested Purchase Orders', " ", " ", check, check),
    createData('Expense Management', " ", " ", check, check),
    createData('Generate Quotations', " ", " ", check, check),
    createData(parse(checkout), " ", " ", " ", check),
    createData('Merchant Website (Coming soon)', " ", " ", check, check),
    createData('Marketplace (Coming soon)', " ", " ", check, check),
    createData('Delivery Apps Integration (Coming soon)', " ", " ", " ", check),
    createData('Social Payment Integration (Coming soon)', "", check, check, check),
    createData('Payment Provider Integration*', "$20", "$20", "$20", "$20"),
    createData('Stripe Integration Fee**', '0.05% or $0.05 whichever is higher + 10c per transaction', '0.05% or $0.05 whichever is higher + 10c per transaction', "0.05% or $0.05 whichever is higher + 10c per transaction", '0.05% or $0.05 whichever is higher + 10c per transaction'),
    createData('Better Transaction Fees', " ", " ", " ", check),
    createData('Run Your Own Customer Loyalty Program (Coming Soon)', " ", " ", " ", check),
    createData('Better Hardware Pricing (Coming soon)', " ", " ", " ", check),
    createData('Unlimited Marketing Email Campaign (Coming soon)', " ", " ", " ", check),
    createData('Included Marketing Emails (Coming soon)', " ", " ", " ", "Up to 500"),
    createData('Account Support - Live Support (Coming Soon)', " ", " ", "", check),
    // createData(additionalLocation, "No add ons", "$100", "$100", "$100"), 
    createData("Additional Location *** (cost per month)", "No add ons", "$100", "$100", "$100"), 
    createData('Additional Terminal (cost per month)', "No add ons", "$35", "$35", "$35"),
    createData('Additional User (cost per month)', "No add ons", "$5", "$5", "$5"),
    createData('Additional Marketing email (cost per 1000 emails)', "No add ons", "not applicable", "not applicable", "$25"),
    createData('Enable Self Checkout', "No add ons", "$100", "$100", "Included"),

];

export default function CompareTable() {
    return (
        <TableContainer variant="outlined" component={Paper}
            style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}   
        >
            <Scrollbars style={{ width: "auto", height: 650 }}>
                <Table sx={{ minWidth: 650, zIndex: -1 }} aria-label="simple table" size='small' className='compareTableHeader'  stickyHeader>
                <TableHead>
                    
                        <TableRow className='compareTableHeader'>
                            <TableCell className="tableHeader semi-bold compareTableHeader">Features</TableCell>
                            <TableCell className="tableHeader semi-bold compareTableHeader" align="center">Standard</TableCell>
                            <TableCell className="tableHeader semi-bold compareTableHeader" align="center">Plus</TableCell>
                            <TableCell className="tableHeader semi-bold compareTableHeader" align="center">Premium</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ color: "#202223", overflowX: "auto" }}>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } , 
                        }}
                        >
                            <TableCell className='tableContent' component="th" scope="row">
                                {row.feature}
                            </TableCell>
                            <TableCell className='tableContent' align="center">{row.standard}</TableCell>
                            <TableCell className='tableContent' align="center">{row.plus}</TableCell>
                            <TableCell className='tableContent' align="center">{row.premium}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className="tableHeader semi-bold" colSpan={5} align="center">
                            {parse(comingSoon)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Scrollbars>
        </TableContainer>
    );
}
