/* eslint-disable react-hooks/exhaustive-deps */

import {Navigate} from "react-router-dom";
import {getAuth,  onAuthStateChanged ,  } from "firebase/auth";

import React ,{ useState,useEffect } from 'react';
import {InvoiceLayout} from '../signup/form/invoiceLayout';




export const InvoiceGuard  = () => {

  


  const [tokenResult, setTokenResult] = useState(true);

  const [authUser, setUser] = useState(null);

  

  useEffect( () => {

    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in.
          setUser(user)
          setTokenResult(true)
        } else {
          setTokenResult(false)
          // No user is signed in.
        }
      });

  

  }, [tokenResult, authUser])


  return (
    <>
     { tokenResult === true ? <InvoiceLayout authUser = {authUser} /> :<Navigate replace to="/authenticate" />}
    </>
   );

    // page loaded only issue otherwise working
    
  }
  


