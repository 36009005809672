import PropTypes from "prop-types";
import { Grid, Container, } from "@mui/material";
import MKBox from "../components/MKBox";
import MKTypography from "../components/MKTypography";
// import appStore from "assets/img/footer/appstore.svg";
// import googlePlay from "assets/img/footer/googleplay.svg";
import playstore from "../assets/images/googleplay.png";
import appstore from "../assets/images/appstore.png";
import { Link } from '@mui/material';
import "./style.css";
import { useEffect, useState } from "react";

function Download() {
    return (
        <>
            <MKBox component="li" p={0} pb={0} m={0} >
                <MKTypography
                    className='link-text'
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    fontSize="16px"
                    key="download"
                    style={{ fontFamily: "OpenSansRegular", color: "#202223" }}
                >
                    Download our App
                </MKTypography>
            </MKBox>
            <MKBox component="li" p={0} mt="12px" sx={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
                
                <a href="https://apple.co/3v9EUou" aria-label="appstore">
                    <MKBox className="footer-download-img" component="img" src={appstore} alt="" style={{ height: "36px", }} key="appstore" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.shelfperks.android.sp" aria-label="playstore">
                    <MKBox component="img" className="footer-download-img playstore" src={playstore} alt="" style={{ height: "36px", }} sx={{ pl: { xs: 0, sm: 0, md: 0, lg: 1 }, }} key="playstore" />
                </a>
            </MKBox>
        </>
    )
}

function DefaultFooter({ content }) {
    const { menus, copyright } = content;
    console.log("content:>>", content);

    return (
        <>
            <MKBox component="footer">
                <div >
                    <Container maxWidth="xl" className="footer-padding">
                        <Grid  container
                            style={{
                                // Doing this because to change the color when the changeplan popupn showsup.
                                // backgroundColor: "#EFF2EF",
                                maxHeight: { xs: "730px", lg: "122px" },
                                pl: { xl: 6, lg: 8, xs: 2, sm: 2 },
                                mt: 5, pt: 10,
                            }}
                            className="open-sans footer-first"
                            // spacing={0}
                            justifyContent="space-between"
                            align="start"

                        >
                            {menus.map(({ items }, index) => (
                                <Grid item xs={12} lg={2.99} md={2} sm={2.23} key={"men1_" + index} p={0}
                                    sx={{
                                        ml: { xs: 0, sm: "0.5px" },
                                        pl: { lg: 0, md: 1, xs: 0, sm: 1 },
                                        mt: { xs: 1, lg: 0.25, md: 1, sm: 0 },
                                        pb: 0,
                                    }}
                                    width="fit-content"
                                    justifyContent="start"
                                >
                                    <MKBox component="ul" key={items.name}
                                        sx={{
                                            listStyle: "none",
                                            mt: { xs: "-10px", md: 0, sm: 1, },
                                            display: "flex",
                                            flexDirection: { xs: "column" },
                                            padding: { lg: "15px 0px", xs: 0 },
                                            mb:0
                                        }}
                                        width="auto"
                                        justifyContent="center"
                                    >
                                        {items.map(({ name, route, href, place }, index) => (
                                            <MKBox key={index}  lineHeight={{ lg: 1.25, md: 1.25, xs: 2.5, sm: 1.25 }} component="li" p={0} m={0}
                                                sx={{ height: { xl:'25px', lg: '25px', md: '25px', sm:'auto' }}} >
                                                {href ? (
                                                    <a target={place} href={href} style={{textDecoration:"none"}}>
                                                        <MKTypography
                                                                className="link-text"
                                                                rel="noreferrer"
                                                                variant="button"
                                                                fontWeight="regular"
                                                                textTransform="capitalize"
                                                            style={{ fontFamily: "OpenSansRegular", color: "#202223", cursor: "pointer",  }}
                                                            >
                                                                {name}
                                                            </MKTypography>
                                                        </a>
                                                ) : 
                                                <>
                                                    { name === 'Manage Cookies'?
                                                            <>
                                                                <MKTypography
                                                                    className="link-text"
                                                                    variant="button"
                                                                    fontWeight="regular"
                                                                    textTransform="capitalize"
                                                                    color="#202223"
                                                                    style={{ fontFamily: "OpenSansRegular", color: "#202223", cursor: "pointer", }}
                                                                >
                                                                    {name}
                                                                </MKTypography>
                                                            </>
                                                :
                                                            <>
                                                                <MKTypography
                                                                    className="link-text"
                                                                    variant="button"
                                                                    fontWeight="regular"
                                                                    textTransform="capitalize"
                                                                    color="#202223"
                                                                    style={{ fontFamily: "OpenSansRegular", color: "#202223", cursor: "pointer", }}
                                                                >
                                                                    {name}
                                                                </MKTypography>
                                                            </>
                                                        }
                                                    </>
                                                    }
                                            </MKBox>
                                        ))}
                                    </MKBox>
                                </Grid>
                            ))}
                            <Grid item xs={12} md={3} sm={4} lg={3}
                                sx={{
                                    mt: { xs: 1, lg: 0.125, md: -.5, sm: 1 },
                                    display: "flex",
                                    justifyContent: { sx: "start", sm: "end", lg: "end", xl: "end" },
                                    pl: { md: 0, lg: 1, xl: 0 }
                                }}>
                                <MKBox mb="12px" sx={{ listStyle: "none", ml: 0, padding: { lg: "0px 0px", xs: 0 }, mt: { lg: 1.5, md: 1.5 } }}>
                                    <Download />
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Container>
                    <Grid container sx={{ color: "white", }} className="footer-padding footer-second">
                        <Grid item xs={12} md={12} fontFamily="Arial">
                            {copyright}
                        </Grid>
                    </Grid>
                </div>
            </MKBox>
        </>
    );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
    content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
