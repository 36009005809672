const axios = require('axios');

const validateMerchantID = async (merchantid) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/validatemerchid";
    return await axios.post(urlLink, JSON.stringify(merchantid), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}
export default validateMerchantID;
