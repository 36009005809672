import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { withStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import emailValidation from "../../helpers/emailValidation"
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import "../../App.css"
import 'react-phone-number-input/style.css'
import emailService from "../../providers/emailService";
import registerMerchantDetailsService from "../../providers/registerMerchantDetailsService";
import sendInviteCode from "../../providers/sendInviteCode";
import cloudStartService from "../../providers/cloudStartService"
import { useNavigate } from "react-router-dom";
import CustomPhoneNumber from "../../components/SPPhoneBlock/index";
import { debounce } from 'lodash';
import AddressForm from '../../components/SPAddressForm';

const MyTextField = withStyles(() => ({
  root: {
    "&.MuiInputLabel": {
      fontFamily: "OpenSansRegular"
    },
    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color: "#F7931E",
      fontSize: "14px",
    },
    "& .MuiInput-root": {
      fontSize: "14px",
      fontFamily: "OpenSansMedium"
    },
    "& .MuiFormHelperText-root": {
      color: "#FF4040",
      fontFamily: "OpenSansMedium",
      fontSize: "11px",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#FF4040",
      fontSize: "12px",
      fontFamily: "OpenSansRegular",
    }
  }
}))(TextField)

const MyInputLabel = withStyles(() => ({
  root: {
    "& .MuiInputLabel-asterisk": {
      color: "#F7931E",
    }
  }
}))(InputLabel)


const MyFormField = withStyles(() => ({
  root: {
    "& .MuiInput-root": {
      fontSize: "14px",
      fontFamily: "OpenSansMedium"
    },
    "& .MuiInputBase-input:focus": {
      backgroundColor: "#FFFFFF"
    }
  }
}))(FormControl)

const ColorButton = styled(MKButton)(() => ({
  backgroundColor: "#C2C2C2",
  '&:hover': {
    backgroundColor: "#3CAC33",
  },
}))

export function Detailsform({ setStepperCount }) {
  useEffect(() => {
    setStepperCount(0);
  }, [])

  const navigate = useNavigate();
  useEffect(() => {
    cloudStartService();
  }, [])
  const businessTypesArray =
    ['Grocery store', 'Liquor Store', 'Convenience store', 'Arts and Craft', 'Auto Parts', 'Bakery', 'Bicycle', 'Bookstore', 'Clothing',
      'Computer Hardware', 'Footwear', 'Hardware', 'Home and Garden', 'Pharmacy & Drugstore', 'Specialty Meats', 'Sporting Goods',
      'Toys and Kids', 'Variety store', 'Cannabis', 'Other'];

  const [fields, setFields] = useState({
    email: "",
    first_name: "",
    last_name: "",
    bussiness_type: "",
    bussiness_name: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zipCode: "",
    country: "",
    phone: "",
  });

  const [emailErrorStatus, setEmailErrorStatus] = useState(-1);
  const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(true);
  const [formError, setFormError] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailResponseData, setEmailResponseData] = useState({});
  const [phonevalue, setPhoneValue] = useState("");
  const [validPhoneNumberStatus, setValidPhoneNumberStatus] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [emailData, setEmailData] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [countryCode, setCountryCode] = useState('US')

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setFormError(validateFields())
  };

  useEffect(async () => {
    let emptyFields = 0;
    for (let key in fields) {
      if (fields[key] === "" || fields[key] === undefined) {
        emptyFields++;
      } else {
        delete formError[key]
        setFormError(formError);
      }
    }
    if (phonevalue !== "" && phonevalue !== undefined) {
      emptyFields--;
    }

    if (emptyFields <= 0) {
      setSubmitButtonDisabled(false)
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [fields])

  useEffect(async () => {
    if (Object.keys(formError).length === 0 && formSubmitted === true && validPhoneNumberStatus) {
      const result = await registerMerchantDetailsService(fields, phonevalue);
      if (result.data.ResponseStatus === 'Success') {
        setFields({
          email: "",
          first_name: "",
          last_name: "",
          bussiness_type: "",
          bussiness_name: "",
          address1: "",
          address2: "",
          state: "",
          city: "",
          zipCode: "",
          country: "",
          phone: "",
        })
        navigate('/register/complete/' + result.data.ResponseContent.data.steplink);
      }

    }
  }, [formError])


  const validateFields = () => {
    let errors = {}
    for (let key in fields) {
      if (key !== 'address2' && key !== 'phone') {
        if (fields[key] === "") {
          errors[key] = true
        }
      }
    }
    if (phonevalue === "" || phonevalue === undefined) {
      errors["phonerequired"] = true
    } else if (!validPhoneNumberStatus) {
      errors["phonevalid"] = true
    }
    return errors;
  }


  const delayedQuery = useCallback(debounce(async (value) => setEmailData(await emailService(value)), 500), []);

  useEffect(() => {
    if (emailData !== "") {
      const emailResponseNumber = Number(emailData.data.ResponseContent.data.EXISTTYPE);
      setEmailErrorStatus(Number(emailData.data.ResponseContent.data.EXISTTYPE));

      const stepLinkToken = emailData.data.ResponseContent.data.STEPLINKTOKEN;
      const emailResponse = {
        steptwolink: '/register/complete/' + stepLinkToken,
        portalLogin: emailData.data.ResponseContent.data.PORTALDOMAIN + '/merchant',
        stepLinkId: stepLinkToken,
      }
      setEmailResponseData(emailResponse)
      if (emailResponseNumber === 0) {
        setOtherFieldsDisabled(false)
      } else {
        setOtherFieldsDisabled(true)
        setSubmitButtonDisabled(true)
      }
    }
  }, [emailData]);

  const emailValidates = (event) => {
    const validEmail = emailValidation(event.target.value);
    validEmail ? setInvalidEmail(false) : setInvalidEmail(true);
  }

  const handleInputChange = async (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setOtherFieldsDisabled(true);
    delete formError[name];
    setFormError(formError);
    setFields({ ...fields, [name]: value });
    if (name === 'email') {
      setInvalidEmail(false);
      setEmailErrorStatus(-1)
      const validEmail = emailValidation(value);
      if (validEmail) {
        delayedQuery(value)
      }
    } else {
      if (emailErrorStatus === 0) {
        setOtherFieldsDisabled(false)
      }
    }
  };

  const handlePhoneInputChange = (event) => {
    setFields({ ...fields, "phone": event });
    if (event === "" || event === undefined) {
      setSubmitButtonDisabled(true)
    }
    delete formError['phonerequired'];
    delete formError['phonevalid'];
    setFormError(formError);
    setPhoneValue(event)
    const result = isValidPhoneNumber(String(event))
    setValidPhoneNumberStatus(result)
  }

  const navigateToNextStep = async () => {
    sendInviteCode(emailResponseData.stepLinkId);
    navigate('/register/complete/' + emailResponseData.stepLinkId);
  };

  const navigateToLogin = async () => {
    window.open(emailResponseData.portalLogin);
  }

  const emailErrorObj = {
    color: "#FF4040",
  }

  const emailNavObj = {
    color: "#50B748",
    textDecoration: "none",
    marginTop: "12px",
    height: "19px",
    fontSize: "14px",
    cursor: "pointer"
  }

  const styleobj = {
    marginTop: "36px",
    borderBottom: otherFieldsDisabled ? "1px dotted #BABFC5" : "2px solid #BABFC5",
  }

  return (
    <MKBox >
      <Container className="registerFirstForm" component="main" maxWidth="xs" >
        <Grid  container justifyContent="center" maxWidth="xs" style={{overflowX: 'unset'}} >
          <Grid item xs={12} mt="-15px">
            <MyTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={fields.email}
              error={formError.email || invalidEmail}
              helperText={formError.email ? "Please enter a valid email address"
                : invalidEmail && emailErrorStatus === -1 ? fields.email === "" ? "Please enter a valid email address"
                  : "Please enter a valid email address" : ""}
              onChange={handleInputChange}
              onBlur={emailValidates}
              onKeyDown={(e) => {
                if (e.key === "Tab" && otherFieldsDisabled) e.preventDefault();
              }}
              autoComplete="email"
              autoFocus
              variant="standard"
              InputLabelProps={{
                style: { fontFamily: "OpenSansRegular", fontSize: '14px' }
              }}
            />
            {emailErrorStatus === 2 ?
              <div>
                <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "33px", fontFamily: "OpenSansRegular" }}>
                  Looks like you already have a merchant account with us!
                </MKTypography>
                <MKTypography onClick={navigateToLogin} fontSize="16px" sx={{ mt: "0px", height: "19px", fontFamily: "OpenSansRegular", cursor: 'pointer', color: '#50B748' }}>
                  Login Now
                </MKTypography>
              </div> : (emailErrorStatus === 1 ?
                <div>
                  <MKTypography style={emailErrorObj} fontSize="14px" sx={{ height: "42px", fontFamily: "OpenSansRegular" }}>
                    Looks like you have already completed Step 1. We are resending your invite email.
                  </MKTypography>
                  <MKTypography onClick={navigateToNextStep} style={emailNavObj} fontSize="14px" sx={{ height: "32px", fontFamily: "OpenSansRegular" }}>
                    Go to Next Step
                  </MKTypography>
                </div> : '')

            }

          </Grid>
          <Grid item xs={12} sm={6}>
            <MyTextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="first_name"
              value={fields.first_name}
              error={formError.first_name}
              helperText={formError.first_name ? "First Name is required" : ''}
              disabled={otherFieldsDisabled}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{
                style: { fontFamily: "OpenSansRegular" }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pl: { lg: 0.5, sm: 1 } }}>
            <MyTextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="last_name"
              value={fields.last_name}
              error={formError.last_name}
              helperText={formError.last_name ? "Last Name is required" : ''}
              disabled={otherFieldsDisabled}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{
                style: { fontFamily: "OpenSansRegular" }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MyFormField fullWidth variant="standard" error={formError.bussiness_type}>
              <MyInputLabel required style={{ fontFamily: "OpenSansRegular" }}>Business Type</MyInputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Business Type"
                name="bussiness_type"
                value={fields.bussiness_type}
                disabled={otherFieldsDisabled}
                onChange={handleInputChange}
                required
              >
                {businessTypesArray.map(element => <MenuItem key={element} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element}</MenuItem>)}
              </Select>
              <FormHelperText>{formError.bussiness_type ? "Bussiness Type is required" : ''}</FormHelperText>
            </MyFormField>
          </Grid>
          <Grid item xs={12}>
            <MyTextField
              margin="normal"
              required
              fullWidth
              id="businessName"
              label="Business Name"
              name="bussiness_name"
              value={fields.bussiness_name}
              error={formError.bussiness_name}
              helperText={formError.bussiness_name ? "Bussiness name is required" : ''}
              disabled={otherFieldsDisabled}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{
                style: { fontFamily: "OpenSansRegular" }
              }}
            />
          </Grid>

          <Grid item xs={12}  >
            <AddressForm
              fieldsdisabled={otherFieldsDisabled}
              fields={fields}
              setFields={setFields}
              formError={formError}
              setFormError={setFormError}
              setCountryCode={setCountryCode}
              pageName={"Register"}
            />
          </Grid>


          <Grid item xs={12}>
            <PhoneInput
              style={styleobj}
              defaultCountry={countryCode}
              inputComponent={CustomPhoneNumber}
              value={phonevalue}
              onChange={handlePhoneInputChange}
              error={formError.phonerequired ? true : formError.phonevalid ? true : false}
              helperText={formError.phonerequired ? "Phone Number is required" : formError.phonevalid ? "Phone Number is invalid" : ''}
              disabled={otherFieldsDisabled}
            />
          </Grid>


          <Grid item xs={12} mb="24px" mt="24px">
            <MKTypography sx={{ fontFamily: "OpenSansRegular", fontSize: "12px", mb:'10px' }}>
              By providing your mobile number, you are opting in and consenting to receive SMS, Text, Passcodes, and OTP messages. Message and data rates may apply. Reply STOP to unsubscribe.              
            <br/>
            </MKTypography>
            <MKTypography sx={{ fontFamily: "OpenSansRegular", fontSize: "12px" }}>
              By clicking on Register, you agree to our
              <span className='privacy-terms'>
                <a href='https://shelfperks.com/terms-of-service' target="_blank"> Terms of Service, </a>
              </span>
               and <span className='privacy-terms'>
                <a href='https://shelfperks.com/privacy-policy' target="_blank"> Privacy Policy. </a>
              </span>
            </MKTypography>
          </Grid>

          <Grid item xs={12}>
            <MKBox >
              <ColorButton
                style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                disabled={submitButtonDisabled}
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: submitButtonDisabled ? "#C2C2C2" : "#50B748",
                  textTransform: "capitalize",
                }} >
                Next Step
              </ColorButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>

  );
}
