import React from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import GoogleAutoCompelete from './Google/GoogleAutoCompelete';
import GetPostalCode from './Google/GetPostalCode';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
// import Geocode from "react-geocode";
// import getCountryList from '../../helpers/countryList';

import CustomPhoneNumber from "../../components/SPPhoneBlock/index";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useState } from 'react';


const MyTextField = withStyles(() => ({
  root: {
    "&.MuiInputLabel": {
      fontFamily: "OpenSansRegular"
    },
    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color: "#F7931E",
      fontSize: "14px",
    },
    "& .MuiInput-root": {
      fontSize: "14px",
      fontFamily: "OpenSansMedium"
    },
    "& .MuiFormHelperText-root": {
      color: "#FF4040",
      fontFamily: "OpenSansMedium",
      fontSize: "12px",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#FF4040",
      fontSize: "12px",
      fontFamily: "OpenSansRegular",
    }

  }
}))(TextField)

const MyAutocomplete = withStyles(() => ({
  root: {
    "&.MuiInputLabel": {
      fontFamily: "OpenSansRegular"
    },
    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color: "#F7931E",
      fontSize: "14px",
    },
    "& .MuiInput-root": {
      fontSize: "14px",
      fontFamily: "OpenSansMedium"
    },
    "& .MuiAutocomplete-input": {
      padding: "2px 0 3px",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "2px 0 3px",
    }

  }
}))(Autocomplete)

const MyInputLabel = withStyles(() => ({
  root: {
    "& .MuiInputLabel-asterisk": {
      color: "#F7931E",
    }
  }
}))(InputLabel)

const MyFormField = withStyles(() => ({
  root: {
    "& .MuiInput-root": {
      fontSize: "14px",
      fontFamily: "OpenSansMedium"
    },
    "& .MuiInputBase-input:focus": {
      backgroundColor: "#FFFFFF"
    },
    "& .MuiFormHelperText-root": {
      color: "#FF4040",
      fontFamily: "OpenSansMedium",
      fontSize: "12px",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#FF4040",
      fontSize: "12px",
      fontFamily: "OpenSansRegular",
    }
  }
}))(FormControl)

class AddressForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.initialState()
    // this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    // this.handleSubmit = this.handleSubmit.bind(this)
    this.autocomplete = null
  }



  loadScript(url, callback) {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };



  componentDidMount() {
    this.getAndSetCountryList();
    const gMapApiKey = window.spconfig?.config.gMapApiKey;
    this.loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${gMapApiKey}&libraries=places`,
      () => { }
    );


    // 

    //  this.autocomplete =  new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})

    // this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
  }

  async getAndSetCountryList() {
    // const countrylist = [];
    // const result = await getCountryList();
    // result.data.ResponseData.data.forEach((cName) => {
    //   countrylist.push(cName.name)
    // });

    // // this.setState({ 'countryList': countrylist });
    this.setState({ 'countryList': ['United States', 'Canada'] });
    setTimeout(() => {
      if (this.props.pageName === "BillingAddress") {
        this.setState({ 'add1': this.props.fields.address1 });
        this.setState({ 'city': this.props.fields.city });
        this.setState({ 'state': this.props.fields.state });
      }

    }, 500);

  }





  initialState() {
    return {
      addresses: [],
      add1: "",
      city: "",
      state: "",
      countryList: [],
      countryNum: ''
    }
  }

  removeErrors() {
    for (let key in this.props.formError) {
      if (key != 'storeName' && key != 'email' && key != 'phone') {
        delete this.props.formError[key]
      }
    }
    this.props.setFormError(this.props.formError);
  }

  handleChange(event) {
    // this.setState({ [event.target.name]: event.target.value });
    const { name } = event.target;
    const { value } = event.target;
    const fields = this.props.fields
    this.props.setFields({ ...fields, [name]: value })


    if (this.props.pageName === "AddonLocation"){
      this.removeErrors()
    }

    // if(event.target.name === "zip_code"){
    //   this.props.setFields({...this.props.fields, 'zipCode':event.target.value});
    // }
    // if(event.target.name === "addressline1"){
    //   this.props.setFields({...this.props.fields, 'address1':event.target.value});
    // }
    // if(event.target.name === "street_address")
    //   this.props.setFields({...this.props.fields, 'address2':event.target.value});
  }

  // handleSubmit(event) {
  //   event.preventDefault()

  // }

  

  async handleChangeAddress(searchValue, field) {
    if (this.props.pageName === "AddonLocation") {
      this.removeErrors()
    }
    let type = ""
    delete this.props.formError["address1"];
    this.props.setFormError(this.props.formError);
    if (!searchValue.target.value) {
      return null;
    }
    if (field === "addressline1") {
      this.props.setFields({ ...this.props.fields, ['address1']: searchValue.target.value });
      type = "address_components";
    }
    if (field === "city") {
      this.props.setFields({ ...this.props.fields, ['city']: searchValue.target.value });
      type = "cities";
    }
    if (field === "state") {
      this.props.setFields({ ...this.props.fields, ['state']: searchValue.target.value });
      type = "regions";
    }

    const results = await GoogleAutoCompelete(searchValue.target.value, type);
    if (results) {
      // this.state.addresses = results;
      this.setState({ 'addresses': results });
    }
  }


  // props.setAddressData()
  async changeAddress(value, field) {

    if (this.props.pageName === "AddonLocation") {
      this.removeErrors() 
    }

    if (field === "addressline1") {
      this.setState({ 'add1': value });
    }
    if (field === "city") {
      this.setState({ 'city': value });
    }
    if (field === "state") {
      this.setState({ "state": value })
    }

    if (field === 'city') {
      this.props.setFields({ ...this.props.fields, ['city']: value });
    }
    if (field === 'address1') {
      this.props.setFields({ ...this.props.fields, ['address1']: value });
    }
    if (field === 'state') {
      this.props.setFields({ ...this.props.fields, ['state']: value });
    }
    if (field === 'country') {
      this.props.setFields({ ...this.props.fields, ['country']: value });
    }

    let result = null;
    for (let x = 0; x < this.state.addresses.length; x++) {
      if (value === this.state.addresses[x].description) {
        result = await GetPostalCode(this.state.addresses[x].place_id);
        // Get Zip code
      }
    }

    if (!result) { return; }
    //   setFieldValue('companyAddress', value);
    let address1 = "";
    let postcode = "";

    const cityComponentType = result.address_components.find((value) => {
      return value.types[0] === 'locality'
    })


    for (const component of result.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality": {
          //  document.querySelector("#locality").value = component.long_name;
          // this.setState({ 'city': component.long_name });

          if (field === "addressline1" || field === "city") {
            this.props.setFields({ ...this.props.fields, 'city': component.long_name });
            this.setState({ 'city': component.long_name });
          }
          break;
        }
        case "postal_town": {
          //  document.querySelector("#locality").value = component.long_name;
          // this.setState({ 'city': component.long_name });
          if (cityComponentType === undefined) {
            if (field === "addressline1" || field === "city") {
              this.props.setFields({ ...this.props.fields, 'city': component.long_name });
              this.setState({ 'city': component.long_name });
            }
          }
          break;
        }
        case "sublocality_level_1": {
          // For some regions of the New York
          if (cityComponentType === undefined) {
            if (field === "addressline1" || field === "city") {
              this.props.setFields({ ...this.props.fields, 'city': component.long_name });
              this.setState({ 'city': component.long_name });
            }
          }
          break;
        }
        case "administrative_area_level_1": {
          //  document.querySelector("#state").value = component.short_name;
          // this.setState({ 'state': component.long_name });
          if (field === "addressline1" || field === "state" || field === "city") {
            this.props.setFields({ ...this.props.fields, 'state': component.long_name });
            this.setState({ 'state': component.long_name });
          }
          break;
        }
        case "country": {
          //  document.querySelector("#country").value = component.long_name;

          // this.setState({ 'country': component.long_name });
          const countryFound = this.state.countryList.find((cn) => component.long_name === cn)
          if (countryFound) {
            this.props.setFields({ ...this.props.fields, 'country': component.long_name });
            this.props.setCountryCode(component.short_name)
          }

          break;
        }
        default: {

        }
      }
    }

    // let fullAdress = '';
    // fullAdress += (this.props.fields['addressline1']) ? this.props.fields['address'] + " ," : ' ';
    // fullAdress += (this.props.fields['city']) ? this.props.fields['city'] + " ," : ' ';
    // fullAdress += (this.props.fields['state']) ? this.props.fields['state'] + " ," : ' ';
    // fullAdress += (this.props.fields['country']) ? this.props.fields['country'] + " ," : ' ';



    // Code For getting the GEO code (LAT and LONG)
    // #*********************************************************#

    // Geocode.setApiKey(gMapApiKey);
    // Geocode.setLanguage("en");

    // Geocode.fromAddress(fullAdress).then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //    
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );

    // #*********************************************************#

    // this.setState({ 'addressline1': address1 });
    // this.setState({ 'zip_code': postcode });
    if (field === "addressline1") {
      this.setState({ 'add1': address1 });
      this.props.setFields({ ...this.props.fields, 'address1': address1 });
    }

    if (this.props.fields.zipCode === '' || postcode !== '') {
      this.props.setFields({ ...this.props.fields, 'zipCode': postcode });
    }


    // if(address1 !== ""){
    //   this.props.setFields({...this.props.fields, 'address1':address1});
    // }else {
    //   this.setState({'add1' : ""});
    // }




    //     let postcode = null;
    //     for(let i = 0; i < result.address_components.length; i++){
    //       if(result.address_components[i].types[0] === 'postal_code'){
    //           postcode = result.address_components[i].long_name;
    //       }
    //     }
    //    // setFieldValue('zipCode', postcode);
    //     // Get city
    //     let city = null;
    //     for(let i = 0; i < result.address_components.length; i++){
    //       if(result.address_components[i].types[0] === 'locality'){
    //           city = result.address_components[i].long_name;
    //       }
    //     }
    //     if(!city){
    //       for(let i = 0; i < result.address_components.length; i++){
    //         if(result.address_components[i].types[0] === 'administrative_area_level_2'){
    //             city = result.address_components[i].long_name;
    //         }
    //       }
    //     }

    //     this.setState({'city': city});
    //     // setFieldValue('city', city);

    //     // Get State
    //     let state = null;
    //     for(let i = 0; i < result.address_components.length; i++){
    //       if(result.address_components[i].types[0] === 'administrative_area_level_1'){
    //         state = result.address_components[i].long_name;
    //       }
    //     }
    //     if(!state){
    //       for(let i = 0; i < result.address_components.length; i++){
    //         if(result.address_components[i].types[0] === 'administrative_area_level_2'){
    //           state = result.address_components[i].long_name;
    //         }
    //       }
    //     }
    //     this.setState({'state': state});
    //     // setFieldValue('stateOfAddress', state);

    //       // Get State
    //       let country = null;
    //       for(let i = 0; i < result.address_components.length; i++){
    //         if(result.address_components[i].types[0] === 'country'){
    //             country = result.address_components[i].long_name;
    //         }
    //       }
    //       this.setState({'country': country});


  }

  // handlePlaceSelect() {
  //   let addressObject = this.autocomplete.getPlace()
  //   let address = addressObject.address_components
  //   this.setState({
  //     name: addressObject.name,
  //     street_address: `${address[0].long_name} ${address[1].long_name}`,
  //     city: address[4].long_name,
  //     state: address[6].short_name,
  //     zip_code: address[8].short_name,
  //     googleMapLink: addressObject.url
  //   })
  // }


  render() {
    const { countryList } = this.state;
    const { countryNum } = this.state;
    

    // <Grid item xs={12} sm={6}>
    //         <MyTextField
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="firstName"
    //           label="Name"
    //           name="first_name"
    //           value={fields.first_name}
    //           error={formError.first_name}
    //           helperText={formError.first_name ? "First Name is required" : ''}
    //           disabled={otherFieldsDisabled}
    //           onChange={handleInputChange}
    //           // autofocus
    //           variant="standard"
    //           InputLabelProps={{
    //             style: { fontFamily: "OpenSansRegular" }
    //           }}
    //         />
    //       </Grid>

    const styleobj = {
      marginTop: "36px",
      borderBottom: "2px solid #BABFC5",
    }


    const handlePhoneInputChange = (event) => {
      this.props.setFields({ ...this.props.fields, 'phone': event });
      this.setState({ 'countryNum': event });
      delete this.props.formError['phone']
      this.props.setFormError(this.props.formError)
      // 
    }

    const validatePhone =  (event) => {
      const result = isValidPhoneNumber(String(countryNum))
      if(!result){
        this.props.setFormError({ ...this.props.formError, 'phone': true })
      }else {
        delete this.props.formError['phone']
        this.props.setFormError(this.props.formError)
      }
    }

    return (

      <>
        {this.props.pageName === "AddonLocation" ?

          <Grid item container xs={12} sm={12} sx={{ display: 'flex', direction: 'column', justifyContent: 'start' }}>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: { sm: '120px', xs: '0px' }, mt: '11px' }}>
              <PhoneInput
                style={styleobj}
                defaultCountry={'US'}
                inputComponent={CustomPhoneNumber}
                value={this.props.fields.phone}
                onChange={handlePhoneInputChange}
                onBlur={validatePhone}
                error={this.props.formError.phone}
                helperText={this.props.formError.phone ? this.props.fields.phone == ''?"Phone Number is required": "Phone Number is not valid" : ""}
                disabled={false}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start' }} >
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                // closeIcon= { () => { return; } }
                style={{ fontFamily: "OpenSansRegular" }}
                inputValue={this.state.add1}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, "addressline1"); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    label="Address line 1"
                    name="address1"
                    id="addressLine1"
                    value={this.props.fields.address1}
                    onChange={(value) => { this.handleChangeAddress(value, "addressline1"); }}
                    variant="standard"
                    required
                    margin="normal"
                    error={this.props.formError.address1}
                    helperText={this.props.formError.address1 ? "Address Line 1 is required" : ''}
                    disabled={this.props.fieldsdisabled}
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: { sm: '120px', xs: '0px' } }}>
              <MyTextField
                name="address2"
                value={this.props.fields.address2}
                // placeholder={"Street Address"}
                label="Address line 2"
                onChange={this.handleChange}
                variant="standard"
                fullWidth
                margin="normal"
                disabled={this.props.fieldsdisabled}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start' }} >
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                inputValue={this.state.city}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, 'city'); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    name={"city"}
                    value={this.props.fields.city}
                    error={this.props.formError.city}
                    helperText={this.props.formError.city ? "City is required" : ''}
                    // placeholder={"City"}
                    label="City"
                    onChange={(value) => { this.handleChangeAddress(value, "city"); }}
                    variant="standard"
                    disabled={this.props.fieldsdisabled}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />

            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start', mr: { sm: '120px', xs: '0px' } }} >
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                inputValue={this.state.state}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, 'state'); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    name={"state"}
                    value={this.props.fields.state}
                    error={this.props.formError.state}
                    helperText={this.props.formError.state ? "State is required" : ''}
                    label="State"
                    onChange={(value) => { this.handleChangeAddress(value, "state"); }}
                    variant="standard"
                    disabled={this.props.fieldsdisabled}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />

            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start' }}>
              <MyTextField
                name="zipCode"
                value={this.props.fields.zipCode}
                error={this.props.formError.zipCode}
                helperText={this.props.formError.zipCode ? "Zip Code is required" : ''}
                // placeholder={"Zipcode"}
                label="Zipcode"
                onChange={this.handleChange}
                variant="standard"
                disabled={this.props.fieldsdisabled}
                fullWidth
                required
                margin="normal"
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
              />
            </Grid>
            <Grid xs={12} sm={7} md={7} lg={5} sx={{ justifyContent: 'start' }}>


              <MyFormField fullWidth variant="standard" error={this.props.formError.country} style={{ marginTop: '16px', marginBottom: '8px' }}>
                <MyInputLabel required style={{ fontFamily: "OpenSansRegular" }}>Country</MyInputLabel>
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Country"
                  name="country"
                  fullWidth
                  value={this.props.fields.country}
                  disabled={this.props.fieldsdisabled}
                  onChange={this.handleChange}
                  required
                  margin="normal"
                >
                  {countryList.map(element => <MenuItem key={element} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element}</MenuItem>)}
                </Select>
                <FormHelperText>{this.props.formError.country ? "Country is required" : ''}</FormHelperText>
              </MyFormField>

            </Grid>
            <Grid xs={12} sm={5} lg={5}  sx={{ justifyContent: 'start' }}>
            </Grid>
          </Grid>

          :

          <div>
            <Grid item xs={12}>
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                // closeIcon= { () => { return; } }
                style={{ fontFamily: "OpenSansRegular" }}
                inputValue={this.state.add1}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, "addressline1"); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    label="Address line 1"
                    name="address1"
                    id="addressLine1"
                    value={this.props.fields.address1}
                    onChange={(value) => { this.handleChangeAddress(value, "addressline1"); }}
                    variant="standard"
                    required
                    margin="normal"
                    error={this.props.formError.address1}
                    helperText={this.props.formError.address1 ? "Address Line 1 is required" : ''}
                    disabled={this.props.fieldsdisabled}
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="address2"
                value={this.props.fields.address2}
                // placeholder={"Street Address"}
                label="Address line 2"
                onChange={this.handleChange}
                variant="standard"
                fullWidth
                margin="normal"
                disabled={this.props.fieldsdisabled}
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
              />
            </Grid>
            <Grid item xs={12} >
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                inputValue={this.state.city}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, 'city'); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    name={"city"}
                    value={this.props.fields.city}
                    error={this.props.formError.city}
                    helperText={this.props.formError.city ? "City is required" : ''}
                    // placeholder={"City"}
                    label="City"
                    onChange={(value) => { this.handleChangeAddress(value, "city"); }}
                    variant="standard"
                    disabled={this.props.fieldsdisabled}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />

            </Grid>
            <Grid item xs={12} >
              <MyAutocomplete
                options={this.state.addresses.map((option) => option.description)}
                inputValue={this.state.state}
                freeSolo
                disabled={this.props.fieldsdisabled}
                onInputChange={(event, value) => { this.changeAddress(value, 'state'); }}
                autoComplete={false}
                renderInput={(params) => (
                  <MyTextField {...params}
                    name={"state"}
                    value={this.props.fields.state}
                    error={this.props.formError.state}
                    helperText={this.props.formError.state ? "State is required" : ''}
                    label="State"
                    onChange={(value) => { this.handleChangeAddress(value, "state"); }}
                    variant="standard"
                    disabled={this.props.fieldsdisabled}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{
                      style: { fontFamily: "OpenSansRegular" }
                    }}
                  />
                )}
              />

            </Grid>
            <Grid item xs={12}>
              <MyTextField
                name="zipCode"
                value={this.props.fields.zipCode}
                error={this.props.formError.zipCode}
                helperText={this.props.formError.zipCode ? "Zip Code is required" : ''}
                // placeholder={"Zipcode"}
                label="Zipcode"
                onChange={this.handleChange}
                variant="standard"
                disabled={this.props.fieldsdisabled}
                fullWidth
                required
                margin="normal"
                InputLabelProps={{
                  style: { fontFamily: "OpenSansRegular" }
                }}
              />
            </Grid>
            <Grid item xs={12}>


              <MyFormField fullWidth variant="standard" error={this.props.formError.country} style={{ marginTop: '16px', marginBottom: '8px' }}>
                <MyInputLabel required style={{ fontFamily: "OpenSansRegular" }}>Country</MyInputLabel>
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Country"
                  name="country"
                  fullWidth
                  value={this.props.fields.country}
                  disabled={this.props.fieldsdisabled}
                  onChange={this.handleChange}
                  required
                  margin="normal"
                >
                  {countryList.map(element => <MenuItem key={element} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element}</MenuItem>)}
                </Select>
                <FormHelperText>{this.props.formError.country ? "Country is required" : ''}</FormHelperText>
              </MyFormField>

            </Grid>
          </div>}
      </>
    )
  }

}

export default AddressForm