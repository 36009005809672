import React, { useState } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
// import WarningIcon from '@mui/icons-material/Warning';
import AddressForm from '../../../components/SPAddressForm';
import { updateMerchantAddress } from "../../../providers/profileChagne"
import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';


const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "12px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }
    }
}))(TextField)

const AddressEditPopup = ({ toggleModal, merchantURL, updateAddressDisplay, merchDetails }) => {

    const [fields, setFields] = useState({
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        country: "",
        zipCode: "",
    });
    const [formError, setFormError] = useState({});
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        const addressObj = {
            first_name: merchDetails.FIRST_NAME,
            last_name: merchDetails.LAST_NAME,
            address1: merchDetails.MERCHANT_ADDRESS,
            address2: merchDetails.MERCHANT_ADDRESS2,
            state: merchDetails.MERCHANT_STATE,
            city: merchDetails.MERCHANT_CITY,
            country: merchDetails.MERCHANT_COUNTRY,
            zipCode: merchDetails.MERCHANT_ZIPCODE,
        }
        setFields(addressObj);
    }, [])

    const updateBillingAddress = async () => {
        
        setFormError(validateFields());
        if (Object.keys(validateFields()).length === 0){
            toggleModal()
            const updateObj = {
                merchURL: merchantURL,
                address1: fields.address1,
                address2: fields.address2,
                state: fields.state,
                city: fields.city,
                country: fields.country,
                zipcode: fields.zipCode,
                addresstype: "Billing",
                first_name: fields.first_name,
                last_name: fields.last_name
            } 
            const result = await updateMerchantAddress(updateObj)
            if (result.data.ResponseMessage === 'Address Updated Successfully'){
                updateAddressDisplay();
            }
        }

    }

    const validateFields = () => {
        let errors = {}
        for (let key in fields) {
            if (key !== 'address2') {
                if (fields[key] === "") {
                    errors[key] = true
                }
            }
        }
        return errors;
    }

    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name] : e.target.value})
    }
    return (
        <>
            <div className="addressEditmodal">
                <div className="overlay"><></></div>
                <div className="addressEdit-modal-content">
                    <MKTypography fontSize="20px" style={{ color: "#202223", letterSpacing: "0.4px", textAlign: "left", opacity: 1, margin: '40px', marginBottom:'20px' }}> <span > Edit Billing Address </span></MKTypography>
                    <Grid container justifyContent="start" maxWidth="xs" style={{ overflowX: "hidden" }} mb={5}  >

                        <Grid item xs={7} sx={{ justifyContent: 'start' }} ml="60px">
                            <MyTextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="first_name"
                                value={fields.first_name}
                                error={formError.first_name}
                                helperText={formError.first_name ? "First Name is required" : ''}
                                onChange={handleInputChange}
                                variant="standard"
                                InputLabelProps={{
                                    style: { fontFamily: "OpenSansRegular" }
                                }}
                            />
                        </Grid>
                        <Grid item xs={7} sx={{ justifyContent: 'start' }} ml="60px">
                            <MyTextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="last_name"
                                value={fields.last_name}
                                error={formError.last_name}
                                helperText={formError.last_name ? "Last Name is required" : ''}
                                onChange={handleInputChange}
                                variant="standard"
                                InputLabelProps={{
                                    style: { fontFamily: "OpenSansRegular" }
                                }}
                            />
                        </Grid>

                        <Grid item xs={7} sx={{ justifyContent: 'start' }} ml="60px" >
                            <AddressForm
                                fieldsdisabled={false}
                                fields={fields}
                                setFields={setFields}
                                formError={formError}
                                setFormError={setFormError}
                                setCountryCode={setCountryCode}
                                pageName={'BillingAddress'}
                            />
                        </Grid>
                    </Grid>
                    <Grid lg={12} sx={{
                        mt: '32px',
                        mb: '32px',
                        pr: {sm:'64px', xs: '0px'},

                        justifyContent: { sm: "end", xs: 'center' }, alignContent: { sm: "end", xs: 'center' }, textAlign: "center"
                    }} container item>
                        <MKBox sx={{ display: "inline-block" }}>
                            <MKButton
                                variant="outlined"
                                color="primary"
                                className="upgrade"
                                size="medium"
                                sx={{

                                    fontSize: "14px",
                                    borderRadius: "4px",
                                    color: "#50B748",
                                    borderColor: "#50B748",
                                    mr: { xs: "50px", sm: "50px", md: "50px" },
                                    verticalAlign: "middle",
                                    lineHeight: 1.5
                                }}
                                onClick={toggleModal}
                                
                            >
                                CANCEL
                            </MKButton>
                            <MKButton
                                variant="contained"
                                color="primary"
                                size="medium"
                                className="upgrade"
                                sx={{
                                    ':hover': {
                                        bgcolor: '#3CAC33',
                                        color: '#FFFFFF',

                                    },
                                    fontSize: "14px",
                                    borderRadius: "4px",
                                    verticalAlign: "middle"
                                }}
                                onClick={updateBillingAddress}
                            >
                                SUBMIT
                            </MKButton>
                        </MKBox>
                    </Grid>
                </div>
            </div>

        </>
    )
}

export default AddressEditPopup;