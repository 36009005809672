import React, { useState, useEffect } from 'react';
import { Container, Grid } from "@mui/material";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MKBox from "../../../components//MKBox";
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom'
// import SimplePricingCard from "./pricecard";
import NewSimplePricingCard from "./newpricecard"
import Modal from "./modal"
import CancelPlan from './cancelplan'
import Header from "./header";
// import StickyFooter from "../../../footer";
import StickyFooter from "../../../DefaultFooter/index";
import footerroutes from "../../../footerroutes";
import CompareTable from "./compareTable";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Profile from "./profile"
import PaymentProvider from "./payment-provider"
import AlertPopup from "./alertPopup";
import ConfirmPopup from './confirmPopup';
import BillingAddressDetails from "./billingAddressDetails"
import AddonsList from './addOns/addonsList';
import CurrentAddons from './addOns/currentAddons'

import "./style.css";
import "../../../assets/theme/base/globals";
import { getmyplan, getPlans, handleSubscribe, getCustomClaim, getmyPaymentMethods, changePaymentMethods, removePaymentMethods, taxAddressCheck } from '../../../providers/planService';
import { getMerchantDetails } from "../../../providers/portalURLValidation"
import { formatDate } from '../../../helpers/dateFormat';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import Loader from '../../../loader/globelLoader'
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import Lottie from "lottie-react";
import growLoader from "../../../assets/animation/growLoader.json";
import { useRef } from "react"
import { useLocation } from "react-router-dom";
import { getPaymentSessionDetails } from "../../../providers/addonGrow";
import AddOnStatusModal from "./addOns/addonStatusModal";
import Divider from '@mui/material/Divider';


let CurrencyFormat = require('react-currency-format');



const ChangePlanForm = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const { id } = useParams();
    const search = useLocation().search;
    const session_id = new URLSearchParams(search).get('session_id');


    const [planDetail, setPlanDetail] = useState({
        expirydate: "",
        planname: '',
        plancode: '',
        priceType: '',
        amount: 0,
        interval: '',
        isFree: false,
        nextBillingPeriod: ''
    });

    const [paymentMethod, setPaymentMethod] = useState({
        card: "",
        cardLastDigit: '',
        expiry: '',
        name: '',
        email: '',
        phone: '',
    });

    const [planList, setPlanList] = useState([]);
    const [merchName, setmerchName] = useState('')
    const [merchantURL, setMerchantURL] = useState('');
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false);
    const [profileDetails, setProfileDetails] = useState({
        name: "",
        email: "",
        phone: ""
    })
    const [merchDet, setMerchDet] = useState({});
    const [addonList, setAddonList] = useState([]);
    const [alignment, setAlignment] = React.useState('yearly');
    const [displayPlanList, setDisplayPlanList] = React.useState([]);
    const [cancelQty, setCancelQty] = useState(1);



    const [modal, setModal] = useState(false);
    const [cancelmodal, setCancelModal] = useState(false);

    const [comparePlanShow, setComparePlanShow] = useState(false);

    const [selectPlan, setSelectPlan] = useState(null);

    const [showPageLoader, setPageLoader] = useState(true);

    const [alertMessage, setAlertMessage] = useState('');
    const [alertReason, setAlertReason] = useState('');
    const [confirmPopup, setconfirmPopup] = useState(false);
    const [addOnLoading, setAddOnLoading] = useState(false);
    const [alertPopup, setAlertPopup] = useState(false);
    const [addonStatusModal, setAddonStatusModal] = useState(false);
    const [addonStatusData, setAddonStatusData] = useState({});


    const standard = `
  <ul style="margin-left: 10px;line-height:2; padding-inline-start:0px">
  <b>All the benefits of Free, and:</b>
  <li>No revenue limit </li>
  <li>Advanced dashboard </li>
  <li>Realtime notifications and reports </li>
  
  and more...
  </ul>
  `
    // Removed content
    // < li > 3 users</li>
    //   <li>1 locations</li>
    //   <li>2 terminals per location</li>

    const plus = `
  <ul style="margin-left: 10px;line-height:2; padding-inline-start:0px">
  <b>All the benefits of Standard, and:</b>
  <li>Your own website</li>
  <li>Marketplace</li>
  <li>Run your own discount program</li>
  <li>Run your own gift card programs</li>
  <li>Point of sale in <br class="tool"> offline mode <div class="tooltip">&#9432;<span class="tooltiptext">Use Point of Sale feature in ShelfPerks App even without internet for 24 hours</span></div></br></li>
  <li>Expiration reports & alerts</li>
  <li>Smart AI based Purchase Orders</li>
  <li>5 users</li>
  <li>2 locations</li>
  <li>2 terminals per location</li>
  and more...
  </ul>
  `
    const premium = `
  <ul style="margin-left: 10px;line-height:2; padding-inline-start:0px">
  <b style="textAlign:left">All the benefits of Plus, and</b>:
  <li> Customer loyalty program</li>
  <li>Self checkout</li>
  <li>Your own website</li>
  <li>Marketplace</li>
  <li>Up to 500 marketing emails</li>
  <li>Delivery app integration</li>
  <li>Live support</li>
  <li>8 users</li>
  <li>2 locations</li>
  <li>3 terminals per location</li>
  and much more...
  </ul>
  `
    //  Removed content
    //  Your own website
    //  Marketplace
    useEffect(() => {

        if (id) {
            upgradePlan(id, 'paid_regular')
        }
        async function fetchData() {
            // You can await here
            setPaymentMethodLoading(false);
            const myplanResult = await planApi()
            paymentMethods()
            const planDetail = myplanResult.data;

            const planResult = await getPlans();
            const allPlanList = planResult.data;

            if (allPlanList && allPlanList.length) {
                setPlanList(allPlanList)
                // planTypeBaseList(planDetail.subscriptions.priceType, allPlanList)
                planTypeBaseList("yearly", allPlanList)

            }

            const token = await getCustomClaim();
            setmerchName(token.claims.merchName)
            setMerchantURL(token.claims.merchUrl);
            const details = await getMerchantDetails(token.claims.merchUrl);
            const data = details.data.ResponseData.data.data;
            setMerchDet(data)
            const detailObj = {
                name: data.FIRST_NAME + " " + data.LAST_NAME,
                email: data.MERCHANT_EMAIL,
                phone: data.MERCHANT_PHONE
            }
            setProfileDetails(detailObj)
            setPageLoader(false);
            if (session_id) {
                getPaymentDetails(session_id)
            }
            // ...
        }
        fetchData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const doggleChange = (event, newAlignment) => {
        planTypeBaseList(newAlignment, planList)
    };


    const planApi = async () => {
        setLoader(true)
        const myplanResult = await getmyplan()
        const planDetail = myplanResult.data;
        setLoader(false)
        setPlanDetail(planDetail.subscriptions);
        return await myplanResult;
    };

    const paymentMethods = async () => {
        const result = await getmyPaymentMethods()
        if (!result.data.status) {
            const expiryMonth = getMonthTwoDigit(result.data.card.exp_month)
            const details = {
                card: result.data.card.brand ? (result.data.card.brand).toUpperCase() : "",
                cardLastDigit: result.data.card.last4 ? result.data.card.last4 : "",
                expiry: expiryMonth + "/" + (result.data.card.exp_year ? result.data.card.exp_year : ""),
                name: result.data.billing_details.name ? result.data.billing_details.name : "",
                email: result.data.billing_details.email ? result.data.billing_details.email : "",
                phone: result.data.billing_details.phone ? result.data.billing_details.phone : "",
            }
            setPaymentMethod(details);
        }
        if (result.data.status === 'no card added yet') {
            setPaymentMethod(
                {
                    card: "",
                    cardLastDigit: '',
                    expiry: '',
                    name: '',
                    email: '',
                    phone: '',
                }
            );
        }
        setPaymentMethodLoading(true);
    };

    const getMonthTwoDigit = (value) => {
        let month = ""
        if (value) {
            if (value < 10) {
                month = "0" + value;
            } else {
                month = value;
            }
        }
        return month;
    }

    const planTypeBaseList = (type, allPlanList) => {
        if (type) {
            setAlignment(type);
            const dispList = [];
            for (const planObj of allPlanList) {
                const planpriceList = planObj.plan_price;
                let filterList = planpriceList.filter(x => x.price_type === type)

                if (planpriceList.length === 1) {
                    filterList = planpriceList
                }

                const clonePlanObj = {
                    planName: planObj.plan_name,
                    planCode: planObj.plancode,
                    planPrice: filterList
                };
                dispList.push(clonePlanObj)
            }
            setDisplayPlanList(dispList)
        }
    };


    const getDisplayPlanDetail = (index) => {
        return displayPlanList && displayPlanList.length ? displayPlanList[index] : null
    }



    const priceAction = (param) => {
        setSelectPlan(param)
        toggleModal()
    };


    async function upgradePlan(param, type) {
        navigateToPlanCard();
        setLoader(true);
        const checkTax = await taxAddressCheck()
        if (checkTax.status === 200) {

            if (checkTax.data.tax_enabled !== 0) {
                setAlertReason('')
                setAlertMessage(checkTax.data.tax_status)
                setAlertPopup(true)
                return;
            }

        }
        const handleResult = await handleSubscribe(param, type);
        const respUrl = handleResult['data']['card not setup']
        if (respUrl) {
            window.location.href = respUrl
        } else {
            if (!handleResult.data.subscriptionUpdated) {
                setAlertMessage(handleResult.data.message);
                setAlertReason(handleResult.data.error_reason);
                toggleAlertModal();
                // alert(handleResult.data.details.paymentIntent.message);

            } else {
                window.location.href = window.spconfig?.config.siteURL + "/#/plan"
            }

        }

        const token = await getCustomClaim();
        setTimeout(async () => {
            await planApi()
        }, 2000);

        // 

    };

    async function changePaymentMethod() {
        const result = await changePaymentMethods()
        if (result.data.sessionURL) {
            window.location.href = result.data.sessionURL;
        }
    };

    async function removePaymentMethod() {
        setCardLoading(true);
        setPaymentMethodLoading(true);
        toggleConfirmModal()
        const result = await removePaymentMethods()
        if (result.data.card_detached) {
            await paymentMethods();
        }
        setCardLoading(false);
    }


    const toggleModal = () => {
        setModal(!modal);
    };

    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    const toggleCancelModal = () => {
        setCancelModal(!cancelmodal);
    };

    const toggleConfirmModal = () => {
        setconfirmPopup(!confirmPopup)
    };

    const toggleAddonStatusModal = () => {
        setAddonStatusModal(!addonStatusModal)
    }

    if (addonStatusModal) {
        document.body.classList.add('active-addonstatusmodal')
    } else {
        document.body.classList.remove('active-addonstatusmodal')
    }


    if (cancelmodal) {
        document.body.classList.add('active-cancelmodal')
    } else {
        document.body.classList.remove('active-cancelmodal')
    }



    if (alertPopup) {
        document.body.classList.add('active-alertPopup')
    } else {
        document.body.classList.remove('active-alertPopup')
    }

    if (confirmPopup) {
        document.body.classList.add('active-confirmPopup')
    } else {
        document.body.classList.remove('active-confirmPopup')
    }

    const toggleAlertModal = () => {
        setAlertPopup(true)
    }

    const handleAlertClose = () => {
        setAlertPopup(false)
    }

    const confirmNo = () => {
        setconfirmPopup(false);
    }


    const cancelPlanToFree = () => {
        toggleCancelModal();
    }

    const cancelModalClose = () => {
        toggleCancelModal();
    }

    const proceedCancelModal = async () => {
        setLoader(true);
        toggleCancelModal();
        await upgradePlan("sp_free365", 'free')
        setTimeout(async () => {
            setLoader(false);
            await planApi()
            childCompRef.current.refreshAddOn();
        }, 3000);

    }


    const modalCancel = () => {
        toggleModal()
    };
    const modalContinue = async () => {
        toggleModal()
        const planId = selectPlan.planPrice[0].code
        await upgradePlan(planId, 'paid_regular')
    };

    const capitalizeFirstLetter = (planName) => {
        return planName.charAt(0).toUpperCase() + planName.slice(1);
    }

    const NavigateToSubscribeCards = () => {
        // navigate("/plan#planCardDetails")
        document.getElementById('planCardDetails').scrollIntoView();
    }

    const moveToAddons = () => {
        document.getElementById('addons').scrollIntoView();
    }

    const navigateToPlanCard = () => {
        // navigate("/plan#planCardDetails")
        document.getElementById('planCard').scrollIntoView();
    }

    const comparePlan = () => {
        setComparePlanShow(!comparePlanShow);
    }

    const getPaymentDetails = async (id) => {
        toggleAddonStatusModal();
        const sessionDet = await getPaymentSessionDetails(id)
        let data = '';
        if (sessionDet.data.ResponseStatus === 'Error') {
            data = 'Error'
        } else {
            data = sessionDet.data.ResponseData;
        }
        setAddonStatusData(data);

        return data
    }

    const [editBillingToggle, setEditBillingToggle] = useState(false);

    const clickEditbillingButton = () => {
        setEditBillingToggle(!editBillingToggle)
    }

    const childCompRef = useRef()

    return (
        <>


            <Container maxWidth="xxl" id="planCard"> < Header /> </Container>

            {showPageLoader ?
                < Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                    <MKBox
                        sx={{
                            mt: '250px',
                            mb: '250px',
                            width: "150px",
                            height: "150px",
                        }}>
                        <Lottie animationData={growLoader} loop={true} />
                    </MKBox>
                </Grid>
                :
                <Container >



                    <Grid container justifyContent="start" pt={2.5}>
                        <Grid lg={8} container item justifyContent="start">
                            <MKTypography fontSize="28px" style={{ fontFamily: 'openSansSemiBold', color: "#2E4455", letterSpacing: "0.28px", textAlign: "left", opacity: 1 }}>Hello, {merchName}</MKTypography>
                        </Grid>
                    </Grid>




                    <Grid container >
                        <>
                            {loader ?
                                <Card sx={{ minWidth: 275, mt: "24px", width: "100%", boxShadow: "#2E445529", borderRadius: 1, boxShadow: 3 }}
                                    // style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}
                                >
                                    {/* <Loader.GlobaLoader /> */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                                        <MKBox
                                            sx={{

                                                width: "150px",
                                                height: "150px",
                                            }}>
                                            <Lottie animationData={growLoader} loop={true} />
                                        </MKBox>
                                    </Grid>
                                </Card>
                                :

                                <Card sx={{ minWidth: 275, mt: "32px", width: "100%", boxShadow: "#2E445529", borderRadius: 1, boxShadow: 3 }}
                                    // style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}
                                    >
                                    <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" }, }}>


                                        <Grid item container xs={12} xl={6} lg={6} sm={12} md={6} justifyContent="space-between" >
                                            <Grid item container xs={12} lg={12} md={12} sm={12} xl={12} direction="column" >
                                                <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Current plan</MKTypography>
                                                <MKTypography fontSize="24px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1 }}> <span className="semi-bold"> {capitalizeFirstLetter(planDetail?.planname)} </span><span style={{ fontSize: "14px", fontFamily: 'OpenSansRegular' }}><CurrencyFormat value={planDetail?.amount} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} renderText={value => <span> {value} </span>} />  {planDetail?.priceType && planDetail?.priceType === 'monthly' ? 'billed Monthly' : 'billed Annually'}</span></MKTypography>
                                            </Grid>
                                            {planDetail?.isFree === false ?
                                                <Grid item container xs={12} lg={12} md={12} sm={12} direction="row">
                                                    <Grid item xs={12} sx={{
                                                        listStyle: "none",
                                                        display: "flex",
                                                        flexDirection: { xs: "row" },
                                                    }} justifyContent="space-between" >
                                                        <MKBox>
                                                            <MKTypography fontSize="14px" className="opensans-regular" sx={{ mt: 4 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Expiry Date</MKTypography>
                                                            <MKTypography fontSize="16px" className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>on {formatDate(planDetail?.expirydate, 'billing')}</MKTypography>
                                                        </MKBox>
                                                        <MKBox>
                                                            <MKTypography fontSize="14px" className="opensans-regular" sx={{ mt: 4 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Next billing date</MKTypography>
                                                            <MKTypography fontSize="16px" className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}>on {formatDate(planDetail?.nextBillingPeriod, 'billing')}</MKTypography>
                                                        </MKBox>
                                                    </Grid>
                                                </Grid> : ""}
                                        </Grid>
                                        <Grid item lg={6} xl={6} sm={12} md={6} xs={12} mt="32px" container sx={{ justifyContent: { md: "end", xs: 'start' }, }} >
                                            <MKBox ml={2} sx={{ display: "inline-block", ml: { xl: "120px", lg: "10px", md: "60px", sm: "0px", xs: "0px" } }}>

                                                <MKButton
                                                    variant="outlined"
                                                    color="primary"
                                                    className="upgrade"
                                                    size="medium"
                                                    sx={{

                                                        fontSize: "14px",
                                                        borderRadius: "4px",
                                                        color: "#50B748",
                                                        borderColor: "#50B748",
                                                        mr: { xs: "50px", sm: "50px", md: "50px" },
                                                        verticalAlign: "middle",
                                                        lineHeight: 1.5,
                                                        textTransform: "capitalize",
                                                    }}
                                                    onClick={moveToAddons}
                                                >
                                                    Add-Ons
                                                </MKButton>
                                                <MKButton
                                                    onClick={NavigateToSubscribeCards}
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    sx={{
                                                        ':hover': {
                                                            bgcolor: '#50B748',
                                                            color: '#FFFFFF',

                                                        },
                                                        textTransform: "capitalize",
                                                        fontSize: "14px",
                                                        width: { sm: "142px", xs: "142px", md: "auto" },
                                                        mb: { xs: "10px", sm: "0px" }
                                                    }}
                                                    className="upgrade"
                                                >
                                                    Upgrade
                                                </MKButton>
                                            </MKBox>
                                        </Grid>


                                        {/* <div style={{ marginTop: "30px" }}>
                                            <hr sx={
                                                { color: "#DCDCDC" }
                                            } />
                                        </div> */}
                                    </CardContent>
                                    <Divider variant='middle'  sx={{ mt:'24px'}}/>
                                    <CardActions>
                                        {
                                            planDetail?.isFree === false ?
                                                <MKButton className="btn-txt opensans-medium"
                                                    onClick={cancelPlanToFree}
                                                    sx={
                                                        {
                                                            fontSize: "14px",
                                                            marginRight: {
                                                                xl: "90px", lg: "90px", md: "90px", sm: "45px", xs: "0px"
                                                            },
                                                            pr: { xs: "10px", sm: "20px" }
                                                        }
                                                    } size="medium" variant="text" color="primary">Cancel Plan</MKButton> : ''
                                        }
                                        <Link to={"/invoice"} style={{ textDecoration: "none" }}>
                                            <MKButton className="btn-txt opensans-medium" sx={
                                                {
                                                    fontSize: "14px",
                                                    marginRight: {
                                                        xl: "90px", lg: "90px", md: "90px", sm: "45px", xs: "0px"
                                                    },
                                                    pr: { xs: "10px", sm: "20px" },
                                                    pl: { xs: "10px", sm: "20px" }
                                                }

                                            } size="small" variant="text" color="primary">Past Invoices</MKButton>
                                        </Link>
                                        <a href="https://support.shelfperks.com" target="_blank" style={{ textDecoration: "none" }}>
                                            <MKButton className="btn-txt opensans-medium" sx={
                                                {
                                                    fontSize: "14px",
                                                    pr: { xs: "10px", sm: "20px" },
                                                    pl: { xs: "10px", sm: "20px" }
                                                }
                                            } size="small" variant="text" color="primary">Get Help</MKButton>
                                        </a>
                                    </CardActions>
                                </Card>
                            }
                        </>
                    </Grid>

                    <PaymentProvider />

                    <Profile profileDetails={profileDetails} merchantURL={merchantURL} setProfileDetails={setProfileDetails} />


                    <Grid container>
                        <Card sx={{ minWidth: 275, mt: "24px", width: "100%", boxShadow: "#2E445529", borderRadius: 1, minHeight: 215, boxShadow: 3 }}
                            // style={{ boxShadow: "0px 2px 1px -1px #2E445529" }}
                            >
                            {/* <MKTypography fontSize="14px" style={{ mb: 1, color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span> Payment Method </span></MKTypography> */}

                            {!cardLoading && !paymentMethodLoading ? 

                                <Grid item xs={12} sx={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                                    <MKBox
                                        sx={{
                                            ml: {lg:65, sm: 35, md:50, xs:20 },
                                            width: 100,
                                            height: 100,
                                        }}>
                                        <Lottie animationData={growLoader} loop={true} />
                                    </MKBox>
                                </Grid>

                                :
                                <CardContent sx={{ pb: 0, display: { xs: "block", md: "flex" } }}>
                                    <Grid item container xs={12}>



                                        <Grid item container xs={12} sm={12} md={12} lg={4} >

                                            {paymentMethod.card ?

                                                <>
                                                    {/* {cardLoading ?

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                                                        <MKBox
                                                            sx={{

                                                                width: "150px",
                                                                height: "1500px",
                                                            }}>
                                                            <Lottie animationData={growLoader} loop={true} />
                                                        </MKBox>
                                                    </Grid>

                                                    :
                                                    <> */}

                                                    <Grid item container xs={12} direction="row">

                                                        <Grid item container xs={12} lg={11}>
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> <span> Payment Method </span></MKTypography>

                                                        </Grid>

                                                        <Grid item container xs={5.5} sm={2} md={2} lg={2.5} justifyContent="flex-start" >
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", opacity: 1, lineHeight: 2 }} sx={{ textAlign: { lg: 'left', md: 'left', sm: 'left', xs: 'left' } }}> <span className="semi-bold"> Card:</span></MKTypography>

                                                        </Grid>
                                                        <Grid item container xs={5.5} sm={8} md={8} lg={8} justifyContent="flex-start" sx={{ ml: 3 }} >

                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {paymentMethod.card} card ending in {paymentMethod.cardLastDigit}</span></MKTypography>

                                                        </Grid>

                                                        <Grid item container xs={5.5} sm={2} md={2} lg={2.5} direction="column" justifyContent="start start" >
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", opacity: 1, lineHeight: 2 }} sx={{ textAlign: { lg: 'left', md: 'left', sm: 'left', xs: 'left' } }}> <span className="semi-bold"> Expiry On:</span></MKTypography>
                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", opacity: 1, lineHeight: 2 }} sx={{ textAlign: { lg: 'left', md: 'left', sm: 'left', xs: 'left' } }}> <span className="semi-bold"> Name:</span></MKTypography>

                                                        </Grid>
                                                        <Grid item container xs={5.5} sm={9} md={9} lg={7} direction="column" justifyContent="start" sx={{ ml: 3 }} >

                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {paymentMethod.expiry} </span></MKTypography>

                                                            <MKTypography fontSize="14px" style={{ color: "#202223", letterSpacing: "0.5px", textAlign: "left", opacity: 1, lineHeight: 2 }}> <span className='opensans-regular'> {paymentMethod.name} </span></MKTypography>

                                                        </Grid>
                                                    </Grid>

                                                    {/* </>
                                                } */}
                                                </>
                                                :
                                                <>
                                                    {paymentMethodLoading ?
                                                        <Grid item xs={12} sx={{ justifyContent: { lg: "center", sm: "flex-start", md: "flex-start" }, alignContent: { lg: "center", sm: "left", md: "left" }, textAlign: { lg: "center", sm: "left", md: "left" }, mb: { lg: "0px", sm: "20px", md: "20px" } }} >
                                                            <Grid item container xs={12} sm={12} md={12} direction="column" justifyContent="start" sx={{ mt: '20px' }} >
                                                                <MKTypography fontSize="16px" mb={2} style={{ color: "#202223", letterSpacing: "0px", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }} sx={{ textAlign: { lg: "center", sm: "left", md: "left" }  }}> Please add the payment method</MKTypography>
                                                            </Grid>
                                                            <MKBox sx={{
                                                                // float: { md:"right", sm:"left"}
                                                            }}>

                                                                <MKButton
                                                                    // component="a"
                                                                    // href="https://portal.shelfperks.com/"
                                                                    // target="_blank"
                                                                    onClick={changePaymentMethod}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="medium"
                                                                    sx={{
                                                                        ':hover': {
                                                                            bgcolor: '#50B748',
                                                                            color: '#FFFFFF',

                                                                        },
                                                                        textTransform: "capitalize",
                                                                        fontSize: "14px",
                                                                        // width: { sm: "142px", xs: "142px" },
                                                                        height: "36px",
                                                                        mb: { xs: "10px", sm: "0px" }
                                                                    }}
                                                                    className="upgrade"
                                                                >
                                                                    Add Payment Method
                                                                </MKButton>
                                                            </MKBox>
                                                        </Grid>
                                                        :
                                                        
                                                         <Grid item xs={12} sx={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                                                            <Grid item container xs={12} sm={12} md={12} direction="column" justifyContent="start" sx={{ mt: '20px' }} >
                                                                <MKTypography fontSize="16px" mb={2} style={{ color: "#202223", letterSpacing: "0px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}> Please add the payment method</MKTypography>
                                                            </Grid>
                                                            <MKBox
                                                                sx={{
                                                                    ml: 15,
                                                                    width: 100,
                                                                    height: 100,
                                                                }}>
                                                                <Lottie animationData={growLoader} loop={true} />
                                                            </MKBox>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                        <Grid item container xs={12} sm={12} md={12} lg={8} >
                                            < BillingAddressDetails merchantURL={merchantURL} merchDet={merchDet} toggleChange={setEditBillingToggle} toggleValue={editBillingToggle} />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            }
                            <Divider variant='middle' sx={{ mt: '24px', mb:'8px'}} />

                            <CardActions>

                                {
                                    paymentMethod.card ?
                                        <>
                                            <MKButton className="btn-txt opensans-medium"
                                                onClick={() => {
                                                    toggleConfirmModal()
                                                }}
                                                sx={
                                                    {
                                                        fontSize: "14px",
                                                        marginRight: {
                                                            xl: "90px", lg: "90px", md: "90px", sm: "45px", xs: "0px"
                                                        },
                                                        pr: { xs: "10px", sm: "20px" },
                                                        pl: "8px"
                                                    }


                                                } size="small" variant="text" color="primary">Remove Card</MKButton>
                                            {/* </Link> */}



                                            <MKButton className="btn-txt opensans-medium"
                                                onClick={() => {
                                                    changePaymentMethod()
                                                }}
                                                sx={
                                                    {
                                                        fontSize: "14px",
                                                        marginRight: {
                                                            xl: "90px", lg: "90px", md: "90px", sm: "45px", xs: "0px"
                                                        },
                                                        pr: { xs: "10px", sm: "20px" },
                                                        pl: "8px"
                                                    }
                                                } size="small" variant="text" color="primary">Change Payment Method</MKButton>

                                        </>
                                        :
                                        ""
                                }
                                <MKButton className="btn-txt opensans-medium"
                                    onClick={() => {
                                        clickEditbillingButton()
                                    }}
                                    sx={
                                        {
                                            fontSize: "14px",
                                            pr: { xs: "10px", sm: "20px" },
                                            pl: { xs: "10px", sm: "20px" }
                                        }
                                    } size="small" variant="text" color="primary">Update Billing Address</MKButton>
                            </CardActions>

                        </Card>


                    </Grid>


                    {/* < BillingAddressDetails merchantURL={merchantURL} merchDet={merchDet} /> */}




                    <MKTypography id="planCardDetails" mt="60px" className="upgrade" mb={3} sx={{ fontSize: { xs: "18px", md: "18px" }, letterSpacing: "0.18px", opacity: 1 }} style={{ fontWeight: "600", color: "#202223" }} textAlign="center">Upgrade your subscription to</MKTypography>
                    <MKBox className="upgrade" mb={4}  sx={{ fontSize: { xs: "18px", md: "18px" }, letterSpacing: "0.18px", opacity: 1 }} style={{ fontWeight: "600", color: "#202223" }} textAlign="center">
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            onChange={doggleChange}
                            value={alignment}
                        >
                            <ToggleButton size='large' sx={{ color: "#202223", fontSize: "18px", height: '46px', width:'115px' }} value="yearly">Yearly</ToggleButton>
                            <ToggleButton size='large' sx={{ color: "#202223", fontSize: "18px", height: '46px', width: '115px' }} value="monthly">Monthly</ToggleButton>
                        </ToggleButtonGroup>
                    </MKBox>

                    <Grid container sx={{ mt: 1 }} direction="row" justifyContent="center" alignItems="center">
                        <Grid item ml="15px" mr="15px" maxWidth="314px">
                            <NewSimplePricingCard

                                variant="outlined"
                                color="primary"
                                title="Standard"
                                description={parse("<b>Advanced features to grow your business with no revenuew cap</b>")}
                                currentPlan={planDetail}
                                planDetail={getDisplayPlanDetail(4)}
                                alignment={alignment}
                                price={{ value: "34.99", currency: "$", duration: parse("<b>per month</b>") }}
                                action={{ variant: "outlined", type: "internal", route: "/", label: "Upgrade" }}
                                specifications={parse(standard)}
                                handleAction={e => priceAction(e)}
                            />
                        </Grid>
                        <Grid item ml="15px" mr="15px" maxWidth="314px">
                            <NewSimplePricingCard
                                variant="outlined"
                                color="primary"
                                title="Plus"
                                currentPlan={planDetail}
                                planDetail={getDisplayPlanDetail(3)}
                                alignment={alignment}
                                description={parse(" <b>Sell more at our marketplace, have your own website and attract more customers with cool features</b>")}
                                price={{ value: "119.99", currency: "$", duration: parse("<b>per month</b>") }}
                                action={{ variant: "outlined", type: "internal", route: "/", label: "Upgrade" }}
                                specifications={parse(plus)}
                                handleAction={e => priceAction(e)}
                            />
                        </Grid>
                        <Grid item ml="15px" mr="15px" maxWidth="314px">
                            <NewSimplePricingCard
                                variant="outlined"
                                color="primary"
                                title="Premium"
                                currentPlan={planDetail}
                                planDetail={getDisplayPlanDetail(1)}
                                alignment={alignment}
                                description={parse("<b>Save labour cost with self checkout feature and superchage your business with customer loyalty program</b>")}
                                price={{ value: "249.99", currency: "$", duration: parse("<b>per month</b>") }}
                                action={{ variant: "outlined", type: "internal", route: "/", label: "Upgrade" }}
                                specifications={parse(premium)}
                                handleAction={e => priceAction(e)}
                            />
                        </Grid>
                    </Grid>
                    <MKTypography mt={3} mb={3} sx={{ fontSize: { xs: "14px", md: "14px" }, letterSpacing: "0px", opacity: 1, color: "#50B748" }} textAlign="center">

                        {/* <ArrowRightAltIcon style={{ verticalAlign: "middle" }}>
                        ArrowRightAlt
                    </ArrowRightAltIcon> */}
                        <Button variant="text" sx={{
                            color: "#50B748",
                            fontFamily: "openSansSemiBold"
                        }} onClick={comparePlan}>COMPARE PLANS +</Button>
                    </MKTypography>
                    {comparePlanShow ?
                        <>
                            <Grid container id="planComparison">
                                <Grid item xs={12} sx={{ mt: { xs: 5, lg: 10 } }}>
                                    <CompareTable />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <MKTypography variant="span" className="tableNote">
                                        *  $20 is a fee to enable integrated payment providers. It is charged monthly per provider account per location.<br />
                                        ** Stripe Integration Fee is a fee per transaction charged (0.05% or $0.05 whichever is higher + 10 cents per transaction).  This is in addition to the fees directly charged by Stripe.<br />
                                        *** 1 new Location add-on comes with 1 additional terminal and 1 additional user. <br />
                                        During 90 day trial, you can choose any plan of your choice. After 90 day trial, if you do not select a plan, your account will be downgraded to the Free plan.
                                        All prices are exclusive of applicable taxes.
                                    </MKTypography>
                                </Grid>
                            </Grid>
                            <MKTypography mt={3} mb={3} sx={{ fontSize: { xs: "14px", md: "14px" }, letterSpacing: "0px", opacity: 1, color: "#50B748" }} textAlign="center">

                                {/* <ArrowRightAltIcon style={{ verticalAlign: "middle" }}>
                        ArrowRightAlt
                    </ArrowRightAltIcon> */}

                                <Button variant="text" sx={{
                                    color: "#50B748",
                                    fontFamily: "openSansSemiBold"
                                }} onClick={NavigateToSubscribeCards}>
                                    <KeyboardDoubleArrowUpIcon style={{ verticalAlign: "middle" }}>
                                        ArrowRightAlt
                                    </KeyboardDoubleArrowUpIcon>
                                    To Top</Button>
                            </MKTypography>
                        </>
                        :
                        ""
                    }
 

                    <CurrentAddons merchName={merchName} setAddonList={setAddonList} ref={childCompRef} />
                    <AddonsList planName={planDetail?.planname} addonListChange={addonList} />

                    <Grid>
                        {modal && (
                            < Modal planData={selectPlan} merchName={merchName} handleCancel={e => modalCancel(e)} handleSubmit={e => modalContinue(e)} />
                        )}
                    </Grid>

                    <Grid>
                        {cancelmodal && (
                            < CancelPlan title={"Cancel Plan"} merchName={merchName} handleCancel={e => cancelModalClose(e)} handleSubmit={e => proceedCancelModal(e)} planDetail={planDetail} addonList={addonList} storeName={''} userAddonQuantity={cancelQty} setCancelQty={setCancelQty} />
                        )}
                    </Grid>

                    <Grid>
                        {alertPopup && (
                            <AlertPopup message={alertMessage} alertReason={alertReason} handleAlertClose={handleAlertClose} />
                        )}
                    </Grid>
                    <Grid>
                        {confirmPopup && (
                            <ConfirmPopup confirmYes={removePaymentMethod} confirmNo={confirmNo} />
                        )}
                    </Grid>
                    <Grid>
                        {addonStatusModal && (
                            <AddOnStatusModal type={'Creation'} statusData={addonStatusData} toggleAddonStatusModal={toggleAddonStatusModal} merchName={merchName} session_id={session_id} />
                        )}
                    </Grid>
                </Container>
            }

            <MKBox pt={2} mt={0} mb={0} sx={{ backgroundColor: "#EFF2EF" }}>
                <Container maxWidth="lg">
                    <StickyFooter content={footerroutes} />
                </Container>
                <MKBox className="navybg" sx={{ backgroundColor: "#2E4455", }} />
            </MKBox>

        </>
    )
}

export default ChangePlanForm;




