import { Routes, Route } from "react-router-dom"
import {BasicLayout} from "./signup/basic";
import React from 'react';
import {RequireAuth} from './guard/AuthGuard';
import {InvoiceGuard} from './guard/InvoiceGuard';
import { CancelPlanGaurd } from './guard/CancelPlanGaurd';
import { CartGuard } from './guard/cartGuard';




 function SpGrow() {
  return (
    
    <div className="App">
     
      <Routes>
        <Route path="*" element={ <BasicLayout/> } />
        {/* <Route  path="/plan" exact element={ <ChangePlan/> } /> */}
        <Route  path="/plan" exact element={<RequireAuth></RequireAuth> } >
        <Route  path=":id" exact element={<RequireAuth></RequireAuth> } />
          </Route>
       
        <Route path="/invoice" exact element={<InvoiceGuard></InvoiceGuard>} />
        
        <Route path="/cancelPlan" exact element={<CancelPlanGaurd></CancelPlanGaurd>} />

        {/* <Route path="/cart" exact element={<CartGuard></CartGuard>} /> */}

      </Routes>
    </div>
  )
}

export default SpGrow;