import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";
import { ref as storeRef, getDownloadURL, uploadBytesResumable } from "firebase/storage";






export const firebaseFileUpload = async (file) => {
    let storage = {}
    let storageRef = {}
    if (!firebase.apps.length) {
    } else {
        storage =  getStorage(firebase.app());
    }

    return new Promise((resolve, reject) => {
        const fileName = 'addonProcessFile/' + file.name + '_' + String(new Date().getTime())
        storageRef = storeRef(storage, `file/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });

            },

        );
    })

}

