import React from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import WarningIcon from '@mui/icons-material/Warning';

const AlertPopup = ({ message, handleAlertClose, alertReason }) => {
   
    return (
        <>
            <div className="alertmodal">
                <div className="overlay"><></></div>
                <div className="alert-modal-content">
                    <Grid sx={{
                        p: '24px',
                        // height: "auto",
                        mt: { xs: "60px", sm: "60px", md: "0px" }
                    }}>
                        <MKBox xs={12} sx={{justifyContent:'center'}}>
                            <div style={{textAlign:'center'}}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                        </MKBox>
                        <MKBox>
                            <MKTypography sx={{
                                // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                overflow: "auto"
                            }} fontSize="16px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                <span className='semi-bold' style={{fontSize:'20px'}}> {message} </span> <br />
                                {alertReason}


                            </MKTypography>
                            
                                <Grid xs={12} lg={12} sx={{ mt: '48px', justifyContent: "Center" }} container item>
                                    <MKBox xs={12} sx={{ display: "inline-block", justifyContent: "Center" }}>
                                        <MKButton
                                            variant="text"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"
                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                verticalAlign: "middle",
                                                lineHeight: 1.5
                                            }}
                                            onClick={handleAlertClose}
                                        >
                                            OK
                                        </MKButton>

                                    </MKBox>
                                </Grid>
                            
                        </MKBox>
                    </Grid>
                </div>
            </div>

        </>
    )
}

export default AlertPopup;