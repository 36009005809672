import { getAuth } from "firebase/auth";
const axios = require('axios');

export const sendEmailChangeCode = async (merchanturl, email) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/sendChangeEmailCode";
    const data = {
        merchurlid: merchanturl,
        email: email,
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const validateEmailChangeCode = async (validateData) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const paramsData = {
        verifyCode: validateData.verifyCode,
        authToken: validateData.authToken,
        email: validateData.email
    }
    const urlLink = apiUrl + "/growapi/validateEmailChangeCode";
    return await axios.post(urlLink, JSON.stringify(paramsData), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const sendPhoneChangeCode = async (merchanturl, email) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/sendChangePhoneCode";
    const data = {
        merchurlid: merchanturl,
        phone: email,
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const validatePhoneChangeCode = async (validateData) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const paramsData = {
        verifyCode: validateData.verifyCode,
        authToken: validateData.authToken,
        phone: validateData.phone
    }
    const urlLink = apiUrl + "/growapi/validatePhoneChangeCode";
    return await axios.post(urlLink, JSON.stringify(paramsData), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const updateMerchantAddress = async (MerchAddressData) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    
    const urlLink = apiUrl + "/growapi/updateMerchantAddress";
    const token = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return await axios.post(urlLink, JSON.stringify(MerchAddressData), {
        headers: headers,
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}
 

export const getMerchantBillingAddress = async (merchanturl) => {
    const apiUrl = window.spconfig?.config.apiUrl;
    const urlLink = apiUrl + "/growapi/getMerchantBillingAddress";
    const data = {
        merchURL: merchanturl,
    };
    return await axios.post(urlLink, JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
    })
        .then((response) => {
            return response;
        },
            (error) => {
                return error;
            })
}


export const getToken = () => {
    return getAuth()
        .currentUser?.getIdToken(true)
        .then((idToken) => {
            return idToken;
        });
}