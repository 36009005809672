import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";
import MKButton from "../../../../components/MKButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { handleSubscribe, taxAddressCheck, handleSubscribeTerminal } from "../../../../providers/planService"
import WarningIcon from '@mui/icons-material/Warning';
import TextField from '@mui/material/TextField';
import currencyConverter from '../../../../helpers/currencyConverter';
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getAllowedStore } from '../../../../providers/addonGrow'
import clsx from "clsx";


const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },
        "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "#F7931E",
            fontSize: "14px",
        },
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiFormHelperText-root": {
            color: "#FF4040",
            fontFamily: "OpenSansMedium",
            fontSize: "12px",
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#FF4040",
            fontSize: "12px",
            fontFamily: "OpenSansRegular",
        }

    }
}))(TextField)

const MyInputLabel = withStyles(() => ({
    root: {
        "& .MuiInputLabel-asterisk": {
            color: "#F7931E",
        }
    }
}))(InputLabel)

const MyFormField = withStyles(() => ({
    root: {
        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input:focus": {
            backgroundColor: "#FFFFFF"
        }
    }
}))(FormControl)

const iconStyles = {
    selectIcon: {
        color: "#8C9196"
    }
};

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <KeyboardArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);


const AddOnModal = ({ planDetails, setModal }) => {

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [storeError, setStoreError] = useState(false);

    const [helperText, setHelperText] = useState('');

    const [message, setMessage] = useState('');
    const [errorResponse, setErrorResponse] = useState(false);
    const [quantityValue, setQuantityValue] = useState(1);
    const [typeSelected, setTypeSelected] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [qtyError, setQtyError] = useState(false);
    const [textFieldsShow, setTextFieldShow] = useState(true);
    const [storeList, setStoreList] = useState([]);
    const [oneTimeAmount, setOneTimeAmount] = useState('');
    const [recurringAmount, setRecurringAmount] = useState('');


    useEffect(() => {
        setOneTimeAmount(planDetails['onetime']?.price);
        setRecurringAmount(planDetails['recurring']?.price);
        async function getStores() {
            const storeList = await getAllowedStore();
            setStoreList(storeList.data.ResponseData.data);
        }
        getStores();
    }, [])





    const handleRadioChange = (event) => {
        const choice = event.target.value;
        setValue(choice);
        setError(false);
        setHelperText('');
        if (choice === '') {
            setTypeSelected(false)
        } else {
            setTotalAmount(planDetails[choice].price)
            setTypeSelected(true);
            if (!planDetails[choice].price || planDetails[choice].price === 0) {
                setTextFieldShow(false)
            } else {
                setTextFieldShow(true)
            }

        }
    };


    const addUserDet = [
        'Give more users access to your Shelfperks Store Operating System.',
        'One time add-on will be billed for a single time only and recurring add-on will be billed the same day of the every billing cycle.'
    ]

    const addTermDet = [
        'Increasing the terminals means being able to ring up more customers.',
        'Shorter waiting lines make happy customers!',
        'One time add-on will be billed for a single time only and recurring add-on will be billed the same day of the every billing cycle.',
    ]

    const addStoreWebsite = [
        'Increase your revenue with online sales.',
        'You can take your store online instantly.',
        'Integrated with in-store/online inventory updates and order fulfillment.',
        'Make your customers happy with convenient pickups and delivery options.'

    ]

    const handleSubmitTerminal = async (event) => {
        event.preventDefault();
        if (allowedStoreKey === '' || allowedStoreKey === undefined || allowedStoreKey === null) {
            setStoreError(true);
            return;
        }
        if (value === '') {
            setError(true);
            setHelperText('Please select an option.');
            return;
        } else {
            const checkTax = await taxAddressCheck()
            if (checkTax.status === 200) {
                if ((checkTax.data.tax_enabled !== 0)) {
                    setMessage(checkTax.data.tax_status)
                    setErrorResponse(true);
                    return;
                }
            }
            const addOnCode = planDetails[value].code;
            try {
                const result = await handleSubscribeTerminal([{ "sp_code": addOnCode, "quantity": quantityValue }], allowedStoreKey);
                if (result.data.url) {
                    window.location.href = result.data.url
                }
                setModal(false);
            } catch (err) {
                setMessage('Cannot use addons from Free plan.')
                setErrorResponse(true);
                return;
            }


        }
    };

    const handleSubmitUser = async (event) => {
        event.preventDefault();

        if (value === '') {
            setError(true);
            setHelperText('Please select an option.');
            return;
        } else if (quantityValue <= 0 || quantityValue === '') {
            setQtyError(true);
            return;
        }

        else {
            const checkTax = await taxAddressCheck()
            if (checkTax.status === 200) {
                if ((checkTax.data.tax_enabled !== 0)) {
                    setMessage(checkTax.data.tax_status)
                    setErrorResponse(true);
                    return;
                }
            }
            const addOnCode = planDetails[value].code;
            try {
                const result = await handleSubscribe([{ "sp_code": addOnCode, "quantity": quantityValue }], 'addons');
                if (result.data.url) {
                    window.location.href = result.data.url
                }
                setModal(false);
            } catch (err) {
                setMessage('Cannot use addons from Free plan.')
                setErrorResponse(true);
                return;
            }


        }
    };

    const handleSubmitWebsiteAddon = async (event) => {
        event.preventDefault();

        const checkTax = await taxAddressCheck();
        if (checkTax.status === 200) {
            if ((checkTax.data.tax_enabled !== 0)) {
                setMessage(checkTax.data.tax_status)
                setErrorResponse(true);
                return;
            }
        }

        const addOnCode = planDetails['recurring'].code;
        try {
            const result = await handleSubscribe([{ "sp_code": addOnCode, "quantity": 1 }], 'addons');
            if (result.data.url) {
                window.location.href = result.data.url
            }
            setModal(false);
        } catch (err) {
            setMessage('Cannot use addons from Free plan.')
            setErrorResponse(true);
            return;
        }
    }


    const preventMinusAndPeriod = (e) => {
        if (e.code === 'Minus' || e.code === 'Period' || e.code === 'KeyE') {
            e.preventDefault();
        }
    };

    const handleClose = () => {
        setModal(false);
    }

    const updateQuantity = (e) => {
        setQtyError(false);
        const qty = e.target.value;
        if (value === '') {
            setError(true);
            setHelperText('Please select an option.');
            return;
        }
        setQuantityValue(qty)
        setTotalAmount((qty * planDetails[value].price))
    }

    // const [responseError, setResponseError] = useState(false);
    const [allowedStoreKey, setAllowedStoreKey] = useState('');

    const handleAllowedOption = (event) => {
        setStoreError(false);
        setAllowedStoreKey(event.target.value.key)
    }

    return (
        <>
            <div className="addonmodal">
                <div className="overlay"><></></div>
                <div className="addon-modal-content">

                    {!errorResponse ?
                        <Grid sx={{
                            p: { lg: "40px", md: "40px", sm: "30px", xs: "20px" },
                            height: "auto",
                            mt: { xs: "20px", sm: "20px", md: "20px" }
                        }}>
                            <MKTypography fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, }}> <span className="semi-bold">{planDetails.name}</span>
                            </MKTypography>

                            {planDetails.name === 'Additional Terminal' ?
                                <MKBox>
                                    <MKTypography sx={{
                                        // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                        overflow: "auto"
                                    }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                                        <div>



                                            <div className='addon-content'>

                                                <div style={{ marginBottom: '30px' }}>
                                                    {addTermDet[0]} <br />
                                                    {addTermDet[1]}

                                                </div>
                                                <div>
                                                    {addTermDet[2]}
                                                </div>
                                            </div>





                                            <div style={{ marginTop: '40px', fontFamily: "OpenSansRegular", fontSize: '14px' }}>
                                                <MKTypography fontSize="14px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}>

                                                    <FormControl error={error} sx={{ fontSize: '14px' }}>
                                                        <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}>Please select a type of addon</MKTypography>

                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label" color='success'>Please select a type of addon</FormLabel> */}
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={handleRadioChange}
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontFamily: "OpenSansRegular",
                                                            }}
                                                        >
                                                            {planDetails.onetime?.code ?
                                                                <FormControlLabel

                                                                    mb={'40px'} value="onetime" control={<Radio sx={{
                                                                        color: '#50B748',
                                                                        fontSize: '14px',
                                                                        fontFamily: "OpenSansRegular",
                                                                        '&.Mui-checked': {
                                                                            color: '#50B748',
                                                                        },

                                                                    }} />} label="One Time" />
                                                                : ""}
                                                            <MKTypography fontSize="14px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.onetime.price)}</span>)</MKTypography>

                                                            {planDetails.recurring?.code ?
                                                                <FormControlLabel mb={'40px'} value="recurring" control={<Radio sx={{
                                                                    color: '#50B748',
                                                                    fontSize: '14px',
                                                                    fontFamily: "OpenSansRegular",
                                                                    '&.Mui-checked': {
                                                                        color: '#50B748',
                                                                    },
                                                                }} />} label="Recurring Monthly" />
                                                                : ""}
                                                            <MKTypography fontSize="14px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.recurring.price)}</span>)</MKTypography>

                                                        </RadioGroup>
                                                        <FormHelperText>{helperText}</FormHelperText>
                                                    </FormControl>
                                                </MKTypography>
                                            </div>

                                            <Grid>
                                                <MKTypography className="semi-bold" fontSize="16px" sx={{ marginTop: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>At which location do you want to use this terminal?</MKTypography>

                                                <Grid item container sm={9}>

                                                    <MyFormField required fullWidth variant="standard" error={storeError}>
                                                        <MyInputLabel style={{ fontFamily: "OpenSansRegular" }}>Store Location</MyInputLabel>

                                                        <Select
                                                            variant="standard"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Store Location"
                                                            name="location"
                                                            IconComponent={CustomExpandMore}

                                                            onChange={handleAllowedOption}
                                                            sx={{
                                                                width: '380px',
                                                                marginTop: '20px'
                                                            }}
                                                        >
                                                            {storeList.map((element) => <MenuItem key={element.key} value={element} sx={{ fontFamily: "OpenSansRegular" }}>{element.storeName}</MenuItem>)}


                                                        </Select>
                                                        <FormHelperText>{storeError ? "Please select a store" : ''}</FormHelperText>
                                                    </MyFormField>

                                                </Grid>
                                            </Grid>
                                        </div>


                                    </MKTypography>
                                    <Grid lg={12} sx={{ mt: '60px', mb: '30px', justifyContent: { md: 'start', sm: 'center', xs: 'center' }, }} container item>
                                        <MKBox sx={{ display: "inline-block", }}>
                                            <MKButton
                                                variant="outlined"
                                                color="primary"
                                                className="upgrade"
                                                size="medium"
                                                sx={{
                                                    width: '100px',
                                                    height: '40px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    color: "#50B748",
                                                    borderColor: "#50B748",
                                                    mr: "30px",
                                                    verticalAlign: "middle",
                                                    lineHeight: 1.5,
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </MKButton>
                                            <MKButton
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                className="upgrade"
                                                sx={{
                                                    ':hover': {
                                                        bgcolor: '#3CAC33',
                                                        color: '#FFFFFF',

                                                    },
                                                    width: '100px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    verticalAlign: "middle",
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleSubmitTerminal}
                                            >
                                                Buy now
                                            </MKButton>
                                        </MKBox>
                                    </Grid>
                                </MKBox>
                                : ""
                            }

                            {planDetails.name === 'Additional User' ?
                                <MKBox>
                                    <MKTypography sx={{
                                        // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                        overflow: "auto"
                                    }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                                        <div>



                                            <div className='addon-content'>

                                                <div style={{ marginBottom: '30px' }}>
                                                    {addUserDet[0]}

                                                </div>
                                                <div>
                                                    {addUserDet[1]}
                                                </div>
                                            </div>





                                            <div style={{ marginTop: '40px', fontFamily: "OpenSansRegular", fontSize: '14px' }}>
                                                <MKTypography fontSize="14px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}>

                                                    <FormControl error={error} sx={{ fontSize: '14px' }}>
                                                        <MKTypography fontSize="16px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}>Please select a type of addon</MKTypography>

                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label" color='success'>Please select a type of addon</FormLabel> */}
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={handleRadioChange}
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontFamily: "OpenSansRegular",
                                                            }}
                                                        >
                                                            {planDetails.onetime?.code ?
                                                                <FormControlLabel

                                                                    mb={'40px'} value="onetime" control={<Radio sx={{
                                                                        color: '#50B748',
                                                                        fontSize: '14px',
                                                                        fontFamily: "OpenSansRegular",
                                                                        '&.Mui-checked': {
                                                                            color: '#50B748',
                                                                        },

                                                                    }} />} label="One Time" />
                                                                : ""}
                                                            <MKTypography fontSize="14px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.onetime.price)}</span>)</MKTypography>

                                                            {planDetails.recurring?.code ?
                                                                <FormControlLabel mb={'40px'} value="recurring" control={<Radio sx={{
                                                                    color: '#50B748',
                                                                    fontSize: '14px',
                                                                    fontFamily: "OpenSansRegular",
                                                                    '&.Mui-checked': {
                                                                        color: '#50B748',
                                                                    },
                                                                }} />} label="Recurring Monthly" />
                                                                : ""}
                                                            <MKTypography fontSize="14px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.recurring.price)}</span>)</MKTypography>

                                                        </RadioGroup>
                                                        <FormHelperText>{helperText}</FormHelperText>
                                                    </FormControl>
                                                </MKTypography>
                                            </div>

                                            <Grid>
                                                <Grid item container>
                                                    <Grid xs={10} sm={4} md={3} lg={2}>
                                                        <MyTextField
                                                            margin="normal"

                                                            fullWidth
                                                            id="userCount"
                                                            label="# of users"
                                                            name="userCount"
                                                            type="number"
                                                            variant="standard"
                                                            error={qtyError}
                                                            helperText={qtyError ? 'Enter a valid quantity' : ''}
                                                            value={quantityValue}
                                                            onKeyPress={preventMinusAndPeriod}
                                                            onChange={updateQuantity}
                                                            InputLabelProps={{
                                                                style: { fontFamily: "OpenSansRegular" }
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid xs={10} sm={6} md={6} lg={8} sx={{ justifyContent: 'center center', verticalAlign: 'bottom' }}>
                                                        <MKTypography fontSize="16px" pt={4.5} ml={{ lg: '110px', xs: '0px', sm: '50px', md: '50px' }} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}> Sub Total: {currencyConverter(totalAmount)}</MKTypography>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>


                                    </MKTypography>
                                    <Grid lg={12} sx={{ mt: '60px', mb: '30px', justifyContent: { md: 'start', sm: 'center', xs: 'center' }, }} container item>
                                        <MKBox sx={{ display: "inline-block", }}>
                                            <MKButton
                                                variant="outlined"
                                                color="primary"
                                                className="upgrade"
                                                size="medium"
                                                sx={{
                                                    width: '100px',
                                                    height: '40px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    color: "#50B748",
                                                    borderColor: "#50B748",
                                                    mr: "30px",
                                                    verticalAlign: "middle",
                                                    lineHeight: 1.5,
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </MKButton>
                                            <MKButton
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                className="upgrade"
                                                sx={{
                                                    ':hover': {
                                                        bgcolor: '#3CAC33',
                                                        color: '#FFFFFF',

                                                    },
                                                    width: '100px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    verticalAlign: "middle",
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleSubmitUser}
                                            >
                                                Buy now
                                            </MKButton>
                                        </MKBox>
                                    </Grid>
                                </MKBox>
                                : ""
                            }
                            {planDetails.name === 'Store Website, Monthly' ?
                                <MKBox>
                                    <MKTypography sx={{
                                        // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                        overflow: "auto"
                                    }} fontSize="14px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "left", opacity: 1, lineHeight: 2 }}>
                                        <div>



                                            <div className='addon-content'>


                                                <div style={{ marginBottom: '30px', lineHeight: 2.3 }}>
                                                    
                                                    <div style={{marginBottom: '15px'}}>
                                                        {addStoreWebsite[0]} 
                                                    </div>
                                                    

                                                    <div>
                                                        {addStoreWebsite[1]}
                                                    </div>

                                                    <div>
                                                        {addStoreWebsite[2]}
                                                    </div>

                                                    <div>
                                                        {addStoreWebsite[3]}
                                                    </div>

                                                    <div>
                                                        {addStoreWebsite[4]}
                                                    </div>
                                                   
                                                    
                                                    
                                                </div>
                                            </div>






                                            <div style={{ marginTop: '40px', fontFamily: "OpenSansRegular", fontSize: '14px' }}>
                                                <MKTypography fontSize="14px" mb={2} className="semi-bold" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansRegular", }}>

                                                    <FormControl error={error} sx={{ fontSize: '14px' }}>

                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label" color='success'>Please select a type of addon</FormLabel> */}
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            // onChange={handleRadioChange}
                                                            value={"recurring"}
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontFamily: "OpenSansRegular",
                                                            }}
                                                        >
                                                            {planDetails.recurring?.code ?
                                                                <FormControlLabel mb={'40px'} value="recurring" control={<Radio sx={{
                                                                    color: '#50B748',
                                                                    fontSize: '14px',
                                                                    fontFamily: "OpenSansRegular",
                                                                    '&.Mui-checked': {
                                                                        color: '#50B748',
                                                                    },
                                                                }} />} label="Recurring Monthly" />
                                                                : ""}
                                                            <MKTypography fontSize="14px" sx={{ marginRight: '40px' }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, lineHeight: 3 }}>( <span className="semi-bold">{currencyConverter(planDetails.recurring.price)}</span>)</MKTypography>

                                                        </RadioGroup>
                                                        <FormHelperText>{helperText}</FormHelperText>
                                                    </FormControl>
                                                </MKTypography>
                                            </div>
                                        </div>


                                    </MKTypography>
                                    <Grid lg={12} sx={{ mt: '60px', mb: '30px', justifyContent: { md: 'start', sm: 'center', xs: 'center' }, }} container item>
                                        <MKBox sx={{ display: "inline-block", }}>
                                            <MKButton
                                                variant="outlined"
                                                color="primary"
                                                className="upgrade"
                                                size="medium"
                                                sx={{
                                                    width: '100px',
                                                    height: '40px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    color: "#50B748",
                                                    borderColor: "#50B748",
                                                    mr: "30px",
                                                    verticalAlign: "middle",
                                                    lineHeight: 1.5,
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </MKButton>
                                            <MKButton
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                className="upgrade"
                                                sx={{
                                                    ':hover': {
                                                        bgcolor: '#3CAC33',
                                                        color: '#FFFFFF',

                                                    },
                                                    width: '100px',
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    verticalAlign: "middle",
                                                    textTransform: "capitalize",

                                                }}
                                                onClick={handleSubmitWebsiteAddon}
                                            >
                                                Buy now
                                            </MKButton>
                                        </MKBox>
                                    </Grid>
                                </MKBox>
                                : ""
                            }












                        </Grid>
                        :
                        < Grid sx={{
                            p: '24px',
                            // height: "auto",
                            mt: { xs: "60px", sm: "60px", md: "0px" }
                        }}>
                            <MKBox xs={12} sx={{ justifyContent: 'center' }}>
                                <div style={{ textAlign: 'center' }}> <WarningIcon style={{ color: 'red', width: "52px", height: '50px' }} /> </div>
                            </MKBox>
                            <MKBox>
                                <MKTypography sx={{
                                    // height: { xl: "260px", lg: "260px", md: "340x", sm: "340px", xs: "340px" },
                                    overflow: "auto"
                                }} fontSize="16px" mt="24px" style={{ color: "#202223", letterSpacing: "0.18px", textAlign: "center", opacity: 1, lineHeight: 2, fontFamily: 'OpenSansRegular' }}>
                                    <span style={{ fontSize: '20px' }}> Cannot use Add-ons with Free Plan.</span> <br />


                                </MKTypography>
                                <Grid xs={12} lg={12} sx={{ mt: '48px', justifyContent: "Center" }} container item>
                                    <MKBox xs={12} sx={{ display: "inline-block", justifyContent: "Center" }}>
                                        <MKButton
                                            variant="text"
                                            color="primary"
                                            className="upgrade"
                                            size="medium"

                                            sx={{

                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                color: "#50B748",
                                                borderColor: "#50B748",
                                                verticalAlign: "middle",
                                                lineHeight: 1.5,
                                                textTransform: "capitalize",


                                            }}
                                            onClick={handleClose}
                                        >
                                            OK
                                        </MKButton>

                                    </MKBox>
                                </Grid>
                            </MKBox>
                        </Grid>

                    }

                </div>
            </div>

        </>
    )
}

export default AddOnModal

{/* 

// {planDetails.name === 'Additional Terminal' ?
//                         <div className='addon-content'>

//                             <div>
//                                 {addTermDet[0]}
//                             </div>
//                             <div>
//                                 {addTermDet[1]}
//                             </div>
//                             <div>
//                                 {addTermDet[2]}
//                             </div>
//                             <div>
//                                 {addTermDet[3]}
//                             </div>

//                         </div>
//                         : ""}

//                                     }


 */}
