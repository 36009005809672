import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";
import { getCurrentAdons, cancelAddons, handleSubscribeUpdate } from '../../../../providers/planService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getAllowedStore, getStoreDetails, getTermCount } from '../../../../providers/addonGrow';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { formatDate } from '../../../../helpers/dateFormat';
import growLoader from "../../../../assets/animation/growLoader.json";
import Lottie from "lottie-react";
import CancelPlan from "../cancelplan";
import CheckIcon from '@mui/icons-material/Check';
import { forwardRef, useImperativeHandle } from "react"
import AddOnStatusModal from "./addonStatusModal";


const CurrentAddons = forwardRef(({ merchName, setAddonList }, ref) => {


    useImperativeHandle(ref, () => ({
        async refreshAddOn() {
            await getAdonsList()
        },
    }))



    const AddOnBlock = (addonArray) => {

        return (
            <>
                {
                    addonArray.map(({ name, date, plancode = undefined, type, expiryDate, rec_addon_sub_id, quantity, storeName }, index) =>
                    (
                        <Grid key={index} item container xs={12} sm={8} lg={4} md={6} xl={4}>
                            <MKBox
                                sx={{

                                    width: { md: "363px", sm: "363px", xs: "363px" },
                                    // height: { md: "165px", xs: "200px" },
                                    borderRadius: "8px",
                                    border: "#DCDCDC 1px solid",
                                    backgroundColor: '#EBFFEA',
                                    mr: "30px",
                                    mb: '15px',
                                    mt: '15px'

                                }}>
                                <Grid item container xs={12} sx={{
                                    mt: 2,
                                    pl: "24px",
                                    listStyle: "none",
                                    display: "flex",
                                    flexDirection: { xs: "row" },
                                    // height: { md: "60px", xs: "80px" }
                                }} justifyContent="space-between">
                                    <div>
                                        <MKTypography mt="14x" sx={{ width: { sm: "165px", xs: '240px', lg: '165px', md: '165px' }, }} fontSize="20px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1,  height: '70px' }}> <span className="semi-bold">{shortNameForRecurring(name)}</span></MKTypography>
                                    </div>
                                    <Grid item sx={{
                                        listStyle: "none",
                                        display: "flex",
                                        flexDirection: { xs: "row" },
                                        justifyContent: "start start"
                                    }}>



                                        <MKBox sx={{
                                            // mr:2,
                                            pl: '10px',
                                            pr: '10px',
                                            pt: "4px",
                                            pb: "4px",
                                            border: "#c2c2c2 1px solid",
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                            // fontWeight: "550",
                                            backgroundColor: '#FFFFFF',
                                            fontFamily: "OpenSansMedium",
                                            textAlign: "center",
                                            width: "auto",
                                            height: '26px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                        }}>
                                            <CheckIcon style={{ color: '#c2c2c2', fontSize: '18px', marginRight: '10px' }} />
                                            Added
                                        </MKBox>

                                        {/* <MKBox component="img" sx={{width: '60px', height:'30px'}} src={addImage} alt="DONE" /> */}

                                        {plancode ?
                                            <>
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(event) => handleClick(event, index)}

                                                    style={{
                                                        maxHeight: '40px',
                                                        padding: '0px'

                                                    }}
                                                >
                                                    <MoreVertIcon style={{ color: '#000000' }} />
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <MenuItem onClick={() => cancelAddon()}>
                                                        <MKTypography mt="14x" fontSize="14px" style={{ color: "#202223", letterSpacing: "0.34px", textAlign: "center", opacity: 1, fontFamily: "OpenSansMedium" }}>Cancel Add on</MKTypography>

                                                    </MenuItem>


                                                </Menu>
                                            </>
                                            :
                                            <MKBox sx={{
                                                // mr:2,
                                                width: "20px",
                                                height: '20px'

                                            }}>
                                            </MKBox>
                                        }



                                    </Grid>


                                </Grid>
                                {/* <Grid item xs={12} ml="24px" sx={{justifyContent:'end end', mt:{md: '0px', sm: '15px'}}} >
                            <MKTypography fontSize="14px" sx={{ mt: 4 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Expiration Date</MKTypography>
                            <MKTypography fontSize="16px" fontWeight="medium" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}> on {formatDate(date, 'addons')}</MKTypography>
                        </Grid> */}

                                <Grid item container xs={12} lg={12} md={12} sm={12} direction="row" ml="24px" pb={1}>
                                    <Grid item xs={12} sx={{
                                        listStyle: "none",
                                        display: "flex",
                                        flexDirection: { xs: "row" },
                                    }} justifyContent="space-between" >

                                        <MKBox>
                                            <MKTypography fontSize="16px" className="opensans-regular" sx={{ mt: 3 }} style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1 }}> <span style={{ fontFamily: "OpenSansMedium", maxWidth: '280px', display: "block", wordWrap: 'break-word' }}> {getmiddleText(quantity, name, storeName)}</span></MKTypography>
                                            {/* <MKTypography fontSize="16px" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansMedium" }}>3</MKTypography> */}
                                        </MKBox>

                                    </Grid>
                                </Grid>

                                <Grid item container xs={12} lg={12} md={12} sm={12} direction="row" ml="24px" pb={2}>
                                    <Grid item xs={12} sx={{
                                        listStyle: "none",
                                        display: "flex",
                                        flexDirection: { xs: "row" },
                                    }} justifyContent="space-between" >

                                        <MKBox>
                                            <MKTypography fontSize="14px" className="opensans-regular" sx={{ mt: 3 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Expiration Date</MKTypography>
                                            <MKTypography fontSize="16px" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansMedium" }}>on {formatDate(date, 'addons')}</MKTypography>
                                        </MKBox>
                                        <MKBox pr="40px">
                                            <MKTypography fontSize="14px" className="opensans-regular" sx={{ mt: 3 }} style={{ color: "#202223", letterSpacing: "0.14px", textAlign: "left", opacity: 1 }}>Type</MKTypography>
                                            <MKTypography fontSize="16px" style={{ color: "#202223", letterSpacing: "0.16px", textAlign: "left", opacity: 1, fontFamily: "OpenSansMedium" }}>{type}</MKTypography>
                                        </MKBox>
                                    </Grid>
                                </Grid>
                            </MKBox>
                        </Grid>
                    )

                    )
                }
            </>

        )
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [recurrinIndex, setRecurringIndex] = useState(-1);
    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setRecurringIndex(index);
        setAnchorEl(event.currentTarget);
        // cancelAddon(rec_addon_sub_id, name, quantity, type);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [oneTimeaddons, setOneTimeaddons] = useState([]);
    const [recurringAddons, setRecurringAddons] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [cancelmodal, setCancelModal] = useState(false);
    const [spAddonID, setSpAddOnID] = useState('');
    const [planName, setPlanName] = useState('');
    const [finalAddonList, setFinalAddonList] = useState([]);
    const [addonName, setAddonName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [storeData, setStoreData] = useState({})
    const [addonStatusModal, setAddonStatusModal] = useState(false);
    const [userAddonQuantity, setUserAddonQuantity] = useState(1);
    const [cancelQty, setCancelQty] = useState(1);
    useEffect(() => {

        const onetimeAddonArray = [];
        const recurringAddonArray = [];

        const getAdons = async () => {
            const stores = await getAllowedStore();
            const pushKeyStores = await getStoreDetails();
            setIsLoading(true);
            const addonsList = await getCurrentAdons()
            if (addonsList.data.one_time) {
                addonsList.data.one_time.forEach((addon) => {
                    addon['name'] = addon.addonName;
                    addon['date'] = (addon.expiryDate / 1000)
                    addon['type'] = 'One Time'
                    if (addon.growdata) {
                        if (addon.growdata.PUSH_KEY) {
                            const storeDet = pushKeyStores.data.ResponseData.data.find((store) => {
                                return store.pushKey === addon.growdata.PUSH_KEY;
                            })
                            addon['storeName'] = storeDet?.storeName ? storeDet?.storeName : "";
                            addon['storeKey'] = storeDet?.storeKey ? storeDet?.storeKey : "";
                        } else if (addon.growdata.STORE_KEY) {
                            const storedet = stores.data.ResponseData.data.find((store) => {
                                return store.key === addon.growdata.STORE_KEY
                            })

                            addon['storeName'] = storedet?.storeName ? storedet?.storeName:'';
                            addon['storeKey'] = storedet?.key ? storedet?.key: '';

                        }
                    }
                    onetimeAddonArray.push(addon)

                })
            }
            if (addonsList.data.recurring) {
                addonsList.data.recurring.forEach((addon) => {
                    if (addon.quantity !== 0){
                    addon['name'] = addon.planname;
                    addon['date'] = (addon.expirydate)
                    addon['type'] = 'Recurring'
                    if (addon.growdata) {
                        if (addon.growdata.PUSH_KEY) {
                            const storeDet = pushKeyStores.data.ResponseData.data.find((store) => {
                                return store.pushKey === addon.growdata.PUSH_KEY;
                            })
                            addon['storeName'] = storeDet?.storeName ? storeDet?.storeName : '' ;
                            addon['storeKey'] = storeDet?.storeKey ? storeDet?.storeKey : '';
                        } else if (addon.growdata.STORE_KEY) {
                            const storedet = stores.data.ResponseData.data.find((store) => {
                                return store.key === addon.growdata.STORE_KEY
                            })
                            if (!storedet) {
                            } else {
                                addon['storeName'] = storedet?.storeName ? storedet?.storeName : '';
                                addon['storeKey'] = storedet?.key ? storedet?.key : '';
                            }
                        }
                    }
                    recurringAddonArray.push(addon)
                }
                })
            }
            setOneTimeaddons(onetimeAddonArray)
            setRecurringAddons(recurringAddonArray)
            setAddonList(onetimeAddonArray.concat(recurringAddonArray));
            setFinalAddonList(onetimeAddonArray.concat(recurringAddonArray));
            setIsLoading(false);
        }

        getAdons();

    }, [])


    const cancelAddon = async () => {
        let terminalCount = 0;
        const addondata = recurringAddons[recurrinIndex];
        let title = 'A Terminal has been canceled!';
        handleClose();
        setSpAddOnID(addondata.rec_addon_sub_id)
        setUserAddonQuantity(addondata.quantity)
        let plantext = getAddonShortName(addondata.quantity, addondata.name)
        if (plantext === 'Users' || plantext === 'User') {
            title = 'User Add-on cancellation successful!';
        } else if (plantext === 'Locations' || plantext === 'Location') {
            title = 'Location has been canceled!';
            const result = await getTermCount(addondata.storeKey);
            terminalCount = result.data.ResponseData.data.terminalCount
        } else if (plantext === 'Store Website'){
            title = 'The store website add-on has been canceled!';
        }
        if (plantext === 'Users') {
            plantext = 'these Users'
        } else {
            plantext = 'this ' + plantext + ' add-on'
        }
        setPlanName(plantext)
        setCancelModal(true);
        const addOnName = getAddonShortName(1, addondata.name);
        setAddonName(addOnName);
        setStoreName(addondata.storeName);
        setStoreData({
            title: title,
            storeKey: addondata.storeKey,
            storeName: addondata.storeName,
            addOnName: addOnName,
            addOnType: 'Recurring',
            userQty: '',
            terminals: terminalCount
        })

    }



    const getAdonsList = async () => {
        setIsLoading(true);
        setTimeout(async () => {
            const onetimeAddonArray = [];
            const recurringAddonArray = [];
            const stores = await getAllowedStore();
            const pushKeyStores = await getStoreDetails();
            const addonsList = await getCurrentAdons()
            if (addonsList.data.one_time) {
                addonsList.data.one_time.forEach((addon) => {
                    addon['name'] = addon.addonName;
                    addon['date'] = (addon.expiryDate / 1000)
                    addon['type'] = 'One Time';
                   
                    if (addon.growdata) {
                        if (addon.growdata.PUSH_KEY) {
                            const storeDet = pushKeyStores.data.ResponseData.data.find((store) => {
                                return store.pushKey === addon.growdata.PUSH_KEY;
                            })
                            addon['storeName'] = storeDet?.storeName ? storeDet?.storeName : '';
                            addon['storeKey'] = storeDet?.storeKey ? storeDet?.storeKey : '';
                        } else if (addon.growdata.STORE_KEY) {
                            const storedet = stores.data.ResponseData.data.find((store) => {
                                return store.key === addon.growdata.STORE_KEY
                            })

                            addon['storeName'] = storedet?.storeName ? storedet?.storeName : '';
                            addon['storeKey'] = storedet?.key ? storedet?.key : '';

                        }
                    }
                    onetimeAddonArray.push(addon)

                })
            }
            if (addonsList.data.recurring) {
                addonsList.data.recurring.forEach((addon) => {
                    if (addon.quantity !== 0) {
                    addon['name'] = addon.planname;
                    addon['date'] = (addon.expirydate)
                    addon['type'] = 'Recurring'
                    if (addon.growdata) {
                        if (addon.growdata.PUSH_KEY) {
                            const storeDet = pushKeyStores.data.ResponseData.data.find((store) => {
                                return store.pushKey === addon.growdata.PUSH_KEY;
                            })
                            addon['storeName'] = storeDet?.storeName ? storeDet?.storeName : "";
                            addon['storeKey'] = storeDet?.storeKey ? storeDet?.storeKey : "";
                        } else if (addon.growdata.STORE_KEY) {
                            const storedet = stores.data.ResponseData.data.find((store) => {
                                return store.key === addon.growdata.STORE_KEY
                            })
                            if (!storedet) {
                            } else {
                                addon['storeName'] = storedet?.storeName ? storedet?.storeName :"";
                                addon['storeKey'] = storedet?.key ? storedet?.key : "";
                            }
                        }
                    }
                    recurringAddonArray.push(addon)
                }
                })
            }

            setAddonList(onetimeAddonArray.concat(recurringAddonArray));
            setFinalAddonList(onetimeAddonArray.concat(recurringAddonArray));
            setIsLoading(false);
            setOneTimeaddons(onetimeAddonArray)
            setRecurringAddons(recurringAddonArray)


        }, 2000);
    }

    const cancelModalClose = async () => {
        setCancelModal(false);
    }


    const proceedCancelModal = async () => {
        setCancelModal(false);
        setIsLoading(true);
        if (addonName.toLowerCase().includes('user')) {


            if (Number(userAddonQuantity) === Number(cancelQty)){
                const result = await cancelAddons(spAddonID)
                setStoreData({ ...storeData, 'addOnId': result.data.latest_invoice,
                    'userQty': cancelQty })
            }else{
                const result = await handleSubscribeUpdate(spAddonID, userAddonQuantity - cancelQty);
            setStoreData({
                ...storeData, 'addOnId': result.data.latest_invoice,
                'userQty': cancelQty
            })
        }
        } else {
            const result = await cancelAddons(spAddonID)
            setStoreData({ ...storeData, 'addOnId': result.data.latest_invoice })
        }

        await getAdonsList();
        setIsLoading(false);
        toggleAddonStatusModal();
    }

    const getAddonShortName = (quantity, addon) => {
        const name = addon.toLowerCase();
        if (name.includes('location')) {
            if (quantity === 1) {
                return 'Location';
            } else {
                return 'Locations';
            }

        }
        if (name.includes('terminal')) {
            if (quantity === 1) {
                return 'Terminal';
            } else {
                return 'Terminals';
            }

        }
        if (name.includes('user')) {
            if (quantity === 1) {
                return 'User';
            } else {
                return "Users"
            }
        }
        if (name.includes('website')) {
            return 'Store Website';
        }
        else {
            return '';
        }
    }

    const getmiddleText = (quantity, addon, storeName) => {
        const name = addon.toLowerCase();
        if (name.includes('location')) {
            return storeName + ' Store';
        }
        if (name.includes('terminal')) {
            return quantity + " Terminal for " + storeName + " Store"
        }
        if (name.includes('user')) {
            if (quantity === 1) {
                return '1 user';
            } else {
                return quantity + " users"
            }
        }
        else {
            return '';
        }
    }


    const shortNameForRecurring = (name) => {
        let addOnTitle = '';
        if (name.toLowerCase().includes('recurring')) {
            addOnTitle = name.replace("Recurring", "");
        } else {
            addOnTitle = name;
        }
        return addOnTitle;
    }


    const toggleAddonStatusModal = () => {
        setAddonStatusModal(!addonStatusModal)
    }

    if (addonStatusModal) {
        document.body.classList.add('active-addonstatusmodal')
    } else {
        document.body.classList.remove('active-addonstatusmodal')
    }

    return (
        <>
            <Grid id='addons' mt={'60px'}>
                <MKTypography variant="h2" fontSize="28px" className="welcome" style={{ color: "#2E4455", letterSpacing: "0.28px", textAlign: "left", opacity: 1 }}>Add-Ons</MKTypography>
                <MKTypography mt={3} fontSize="18px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "left", opacity: 1, marginBottom: '15px', fontFamily: 'OpenSansRegular' }}>Your Current Add-Ons are</MKTypography>

                {finalAddonList.length === 0 ?
                    <MKTypography mt={4} mb={4} fontSize="16px" style={{ color: "#202223", letterSpacing: "0px", textAlign: "center", opacity: 1, fontFamily: 'OpenSansRegular' }}>You haven’t added any add-on yet. Explore the options below.</MKTypography>
                    : ""}

            </Grid>

            {isLoading ?
                < Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                    <MKBox
                        sx={{
                            mt: '250px',
                            mb: '250px',
                            width: "150px",
                            height: "150px",
                        }}>
                        <Lottie animationData={growLoader} loop={true} />
                    </MKBox>
                </Grid>
                :
                <Grid container item justifyContent="start">

                    {/* <Grid item container>
                    {AddOnBlock(oneTimeaddons)}
                </Grid>
                <Grid item container>
                    {AddOnBlock(recurringAddons)}
                </Grid> */}

                    <Grid item container>
                        {AddOnBlock(oneTimeaddons)}

                        {AddOnBlock(recurringAddons)}
                    </Grid>






                </Grid>
            }

            <Grid>
                {cancelmodal && (
                    < CancelPlan title={"Cancel " + addonName + " Add-On"} merchName={merchName} handleCancel={e => cancelModalClose(e)} handleSubmit={e => proceedCancelModal(e)} planDetail={planName} addonList={[]} storeName={storeName} userAddonQuantity={userAddonQuantity} setCancelQty={setCancelQty} />
                )}
            </Grid>
            <Grid>
                {addonStatusModal && (
                    <AddOnStatusModal type={'Cancel'} statusData={storeData} toggleAddonStatusModal={toggleAddonStatusModal} merchName={merchName} session_id={''} />
                )}
            </Grid>
        </>
    )
}
)

export default CurrentAddons;