const axios = require('axios');

const cloudStartService = async (mailAddress) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const url = apiUrl + "/growapi";

  return await axios.get(url, {
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
}



export default cloudStartService;