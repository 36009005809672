import * as React from 'react';
import { Grid, } from '@mui/material';
import Container from '@mui/material/Container';
import growLoader from "../assets/animation/growLoader.json";
import shelfperks from "../assets/images/logo.svg";
import Lottie from "lottie-react";
import MKBox from "../components/MKBox";
import MKTypography from "../components/MKTypography";

export function Processing() {
    return (
        <MKBox mb='250px'>
            <Container component="main" maxWidth="xs" style={{ overflowX: "hidden", overflow: "hidden" }}>
                <Grid container justifyContent="center" alignItems="center" style={{ overflow: "hidden" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                        <div className='logo'>
                            <img src={shelfperks} alt="LOGO"></img>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                        <MKBox 
                            sx={{
                                mt: {
                                    lg: "135px",
                                    xs: "135px",
                                },
                                width: "150px",
                                height: "150px",
                            }}>
                                <Lottie animationData={growLoader} loop={true} />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" display="flex" >
                        <MKTypography
                            fontSize="24px"
                            sx={{
                                mt: {
                                    xs: "15px"
                                },
                                mb: "16px",
                                fontFamily: "OpenSansSemiBold",
                                textAlign: "center"
                            }}
                        >
                            Hold On Tight!
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={12} justifyContent="center" textAlign="center" display="flex">
                        <MKTypography
                            fontSize="16px"
                            sx={{
                                mt: {
                                    xs: "10px"
                                },
                                width: "312px",
                                height: "fit-content",
                                fontFamily: "OpenSansRegular"
                            }}>
                            We are preparing your merchant portal...
                        </MKTypography>

                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    )
}