import React from 'react';

import Cancelplan from './ChangePlan/cancelplan'

export function CancelLayout({ authUser }) {
    const [loading, setLoad] = React.useState(false);
    React.useEffect(() => {
        if (authUser) {
            setLoad(true)
        }
    }, [authUser]);
    return (
        < >
            {loading ?
                < Cancelplan /> : ""}
        </>
    )
}
