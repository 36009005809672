const axios = require('axios');

const stepValidationService = async (urltoken) => {
  const apiUrl = window.spconfig?.config.apiUrl;
  const data = {
    urltoken: urltoken
  };
  const urlLink = apiUrl + "/growapi/validatesteplink";
  return await axios.post(urlLink, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response;
    },
      (error) => {
        return error;
      })
};
export default stepValidationService;