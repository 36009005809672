import React  from 'react';

import ChangePlanForm from "./ChangePlan"

export function ChangePlan({authUser}) {
    const [loading, setLoad] = React.useState(false);
    React.useEffect(() => {
        if (authUser) {
            setLoad(true)
        }
    }, [authUser]);
    return (
        < >
        {loading  ?
            < ChangePlanForm /> : ""  }                   
        </>
    )
}
