import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton"
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core/styles";
import forgotUsernameService from "../../providers/forgotUsername";
import emailValidation from "../../helpers/emailValidation";
import ReCAPTCHA from "react-google-recaptcha";
import { BsCheck2 } from 'react-icons/bs';
import logo from "../../assets/images/logo.svg";


const ColorButton = styled(MKButton)(() => ({
    backgroundColor: "#C2C2C2",
    '&:hover': {
        backgroundColor: "#3CAC33",
    },
}))

const MyTextField = withStyles(() => ({
    root: {
        "&.MuiInputLabel": {
            fontFamily: "OpenSansRegular"
        },

        "& .MuiInput-root": {
            fontSize: "14px",
            fontFamily: "OpenSansMedium"
        },
        "& .MuiInputBase-input": {
            padding: "16.5px 14px"
        },
        "& .MuiFormHelperText-root":{
            color: "#FF4040",
        },
        "& .MuiTextField-root": {
            marginBottom: "20px"
        },
    }
}))(TextField)

const ForgotUsername = ({ setStepperCount }) => {

    useEffect(() => {
        setStepperCount(-1);
    }, [])

    const [emailId, setEmailId] = useState("");
    const [merchId, setMerchId] = useState("");
    const [captchaCode, setCaptchaCode] = useState("");
    const [formSubmit, setFormSubmit] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [processStatus, setProcessStatus] = useState(false);
    // const [commonError, setCommonError] = useState(false);
    const [error_merchid, setError_merchid] = useState(false);
    const [error_email, setError_email] = useState(false);


    const siteKey = window.spconfig?.config.reCaptKey;

    const onChangeCaptcha = (value) => {
        setCaptchaCode(value)
    }

    const onEmailBlur = (event) => {
        const value = event.target.value
        if (value === ""){
            return;
        }
        const result = emailValidation(value);
        if (!result){
            setEmailError(true);
        }
    }

    const onMerchIdBlur = (event) => {
        const value = event.target.value;
        if (value === ""){
            return;
        } else{
            setMerchId(value);
        }
    }

    const onSubmit = async () => {
        setFormSubmit(true);
        if (merchId === "" || emailId === "" || captchaCode === "" || captchaCode === null || emailError){
            return;
        } else {
            const data = {
                email: emailId,
                merchid: merchId,
                captchaCode: captchaCode
            }
            const result = await forgotUsernameService(data);
            if (result.data.ResponseStatus === 'Success') {
                setProcessStatus(true);
            } else {
                if (result.data.ResponseContent?.data.error_merchid){
                    setError_merchid(true);
                } else if (result.data.ResponseContent?.data.error_email){
                    setError_email(true)
                }else {
                    // setCommonError(true);
                }
            }
            
        }
    }

    return (
        <MKBox mb='250px'>
        
            < Container component="main" maxWidth="xs" justify="center" style={{ overflowX: "hidden" }
            }>
                <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                    <MKBox sx={{ justifyContent: "center", textAlign: "center", align: "center" }}>
                        <img src={logo} alt="LOGO"></img>
                    </MKBox>
                </Grid> 

                {!processStatus ?
                <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                    mt="80px"
                >

                    <Grid container justifyContent="center" maxWidth="xs" style={{ overflowX: "hidden" }}>
                        <Grid item xs={12} lg={12} sx={{ mb: "20px", }}>
                            <MKTypography sx={{
                                fontSize: "22px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                fontWeight: "600",
                                color: "#2E4455",
                                mb: "10px"
                            }}>
                                Forgot Username
                            </MKTypography>
                            <MKTypography sx={{
                                fontSize: "16px",
                                textAlign: "left",
                                fontFamily: "OpenSansRegular",
                                color: "#2E4455",
                                mb: "30px"
                            }}>
                                Help us recover your credentials securely. 
                                Provide your Merchant ID and registered 
                                email address.
                            </MKTypography>
                        </Grid>


                        <Grid xs={12} lg={12} sx={{ mb: "20px" }}>
                            <MyTextField id="outlined-basic"
                                label="Merchant ID"
                                placeholder="Merchant ID issued during registration"
                                fullWidth
                                variant="standard"
                                onBlur={onMerchIdBlur}
                                error={(formSubmit && merchId === "")}
                                    helperText={formSubmit && emailId === "" ? "Merchant ID is required" : error_merchid ?"Sorry! It is not a valid Merchant ID.":""}

                                onChange={(event) => {
                                    setError_merchid(false);
                                    setMerchId(event.target.value);
                                    setFormSubmit(false);
                                }}

                             />

                        </Grid>
                        <Grid xs={12} lg={12} sx={{ mb: "40px" }}>
                            <MyTextField
                                id="outlined-basic"
                                label="Email"
                                placeholder="Email associated with your username"
                                fullWidth
                                variant="standard"
                                error={(emailError) || (formSubmit && emailId === "")}
                                value={emailId}
                                onBlur={onEmailBlur} 
                                    helperText={emailError ? "Invalid Email" : formSubmit && emailId === "" ? "Email is required" : error_email ?"Sorry! It is not a valid Email Address.":""}
                                onChange={(event) => {
                                    setError_email(false)
                                    setFormSubmit(false)
                                    setEmailError(false)
                                    setEmailId(event.target.value)
                                }}
                                name="emailId"
                                />
                        </Grid>
                        <Grid xs={12} lg={12} sx={{ mb: "30px" }}>
                                    <ReCAPTCHA
                                        sitekey={siteKey}
                                        onChange={onChangeCaptcha}
                                    />
                                </Grid>



                        <Grid item xs={12} lg={12} >
                            <ColorButton
                                style={{ color: "#FFFFFF", fontFamily: "OpenSansSemiBold" }}
                                variant="contained"
                                sx={{
                                    width: "100%",
                                    height: "48px",
                                    backgroundColor: "#50B748",
                                    color: "white",
                                    textTransform: "capitalize",
                                    fontFamily: "OpenSansSemiBold",
                                    fontSize: "15px",
                                }}
                                onClick={onSubmit}
                            >
                                Submit
                            </ColorButton>
                        </Grid>
                        <Grid item xs={12} lg={12} mt={2}>
                            <MKTypography sx={{
                                fontSize: "14px",
                                textAlign: "center",
                                fontFamily: "OpenSansRegular",
                                color: "#6D7175",
                            }}>
                                New to ShelfPerks?
                                
                                <a href="/" style={{
                                    color: "#50B748",
                                    fontWeight: "700"
                                }}> Register Now</a>
                            </MKTypography>
                        </Grid>

                    </Grid>
                </MKBox> :
                    <Grid sx={{ minHeight: "60vh" }} >
                        <Grid item xs={12} sm={12} md={12} justifyContent="center" display="flex">
                    <MKBox component="main"
                        sx={{
                            mt: {
                                lg: "180px",
                                xs: "180px",
                            },
                            width: "86px",
                            height: "85px",
                            borderRadius: '43px',
                            backgroundColor: "#50B748"
                        }}>
                        <div className='check-icon' style={{ align: "center" }}>
                            <BsCheck2 />
                        </div>
                    </MKBox>
                </Grid>
                <Grid item xs={12} lg={12} justifyContent="center">
                    <MKTypography
                        fontSize="24px"
                        sx={{
                            mt: "82px",
                            fontFamily: "OpenSansSemiBold",
                            textAlign: "center",
                            color: "#2E4455"
                        }}>
                        Done! We have sent the details to your registered email.
                    </MKTypography>
                </Grid>
            </Grid>
        }
            </Container >
        </MKBox >
    )
}

export default ForgotUsername;